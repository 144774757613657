import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Grid,
  Skeleton,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Add, Download, Email, Info } from "@mui/icons-material";
import CustomTooltip from "./ui/CustomTootltip";
import CustomButton from "./ui/CustomButton";
import NoDataFound from "./NoDataFound";
import PricesPopover from "./ui/PricesPopover";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";

const ReusableTable = ({
  componentName,
  columns,
  data,
  rowsPerPage,
  page,
  onAdd,
  onEdit,
  onDelete,
  loading,
  totalCount,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const getFilteredData = () => {
    let filteredData = data;

    if (searchQuery) {
      switch (componentName.toLowerCase()) {
        case "clients":
          filteredData = data.filter((item) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
          );
          break;
        case "resources":
          filteredData = data.filter(
            (item) =>
              item.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
              item.designation.toLowerCase().includes(searchQuery.toLowerCase())
          );
          break;
        default:
          filteredData = data.filter(
            (item) =>
              item.matter.toLowerCase().includes(searchQuery.toLowerCase()) ||
              item.client.toLowerCase().includes(searchQuery.toLowerCase())
          );
          break;
      }
    }

    return filteredData;
  };

  const filteredData = getFilteredData();

  const [openModal, setOpenModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");

  const handleOpenModal = (address) => {
    setSelectedAddress(address);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedAddress("");
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 500]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <TableContainer sx={{ maxHeight: "75vh", overflowX: "auto" }}>
        <Table
          stickyHeader
          aria-label="reusable table"
          sx={{
            // tableLayout: "fixed",
            width: "100%",
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{ backgroundColor: "white" }}
                colSpan={columns.length}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid
                    item
                    sm={4}
                    md={4}
                    lg={4}
                    display="flex"
                    gap={2}
                    alignItems="center"
                  >
                    <Typography variant="h5" align="center">
                      {componentName}
                    </Typography>

                    <CustomTooltip text="Add">
                      <CustomButton
                        style={{
                          borderRadius: "50%",
                          minWidth: "5px",
                          height: "30px",
                          marginBottom: "7px",
                          transition:
                            "transform 0.3s ease-in-out, background-color 0.3s ease",
                          "&:hover": {
                            backgroundColor: "#886a47",
                            transform: "scale(1.1)",
                          },
                        }}
                        onClick={() => onAdd(null)}
                      >
                        <Add />
                      </CustomButton>
                    </CustomTooltip>
                  </Grid>
                  <Grid item sm={6} md={8} lg={8} alignSelf="flex-end">
                    <TextField
                      fullWidth
                      label={
                        componentName.toLowerCase() === "resources"
                          ? "Search for Resources and Designation"
                          : componentName.toLowerCase() === "matters"
                          ? "Search by Matters and Client Name"
                          : componentName.toLowerCase() === "clients"
                          ? "Search by Client"
                          : "Search"
                      }
                      variant="outlined"
                      size="small"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      InputProps={{
                        style: { borderRadius: 25 },
                      }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align || "left"}
                  sx={{
                    minWidth: column.minWidth,
                    whiteSpace: "wrap", // Ensure no wrapping
                    // overflow: "visible",
                    textOverflow: "ellipsis", // Ellipsis if text overflows
                    fontWeight: "bold",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              // Render skeleton loaders
              Array.from(new Array(5)).map((_, index) => (
                <TableRow key={index}>
                  <TableCell colSpan={columns.length}>
                    <Skeleton
                      variant="rectangular"
                      height={30}
                      animation="wave"
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : filteredData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length + 2}>
                  <NoDataFound />
                </TableCell>
              </TableRow>
            ) : (
              filteredData.map((row, index) => (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={row.key}
                  sx={{
                    backgroundColor: "white", // Remove row background coloring based on status
                    fontFamily: "Nunito Sans, sans-serif !important",
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];

                    return (
                      <TableCell
                        key={column.id}
                        align={column.align || "left"}
                        sx={{
                          width: column.width, // Use the explicit width here
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {column.id === "price" ? (
                          Array.isArray(value) ? (
                            // Show PricesPopover if value is an array
                            <PricesPopover prices={value} />
                          ) : (
                            // Show fixed price directly
                            value
                          )
                        ) : column.id === "matterAddress" ? (
                          value === "" || value === null ? (
                            ""
                          ) : (
                            <CustomTooltip text="View Matter Address">
                              <IconButton
                                onClick={() => handleOpenModal(value)}
                              >
                                <Info />
                              </IconButton>
                            </CustomTooltip>
                          )
                        ) : column.id === "email" ? (
                          value === "" ||
                          value === null ||
                          value === undefined ? (
                            ""
                          ) : (
                            <CustomTooltip text={value}>
                              <IconButton>
                                <Email />
                              </IconButton>
                            </CustomTooltip>
                          )
                        ) : column.id === "status" ? (
                          // Conditionally style the status text
                          <span
                            style={{
                              color:
                                value === "active" || value === "enable"
                                  ? "green"
                                  : value === "inActive" || value === "disable"
                                  ? "red"
                                  : "", // Green for active or enable, red for inactive or disable
                              fontWeight: "bold",
                            }}
                          >
                            {value === "active" || value === "enable"
                              ? "Active"
                              : "Inactive"}
                          </span>
                        ) : column.id === "edit" ? (
                          <IconButton onClick={() => onEdit(row)}>
                            <CiEdit title="Edit" />
                          </IconButton>
                        ) : column.id === "delete" ? (
                          <IconButton onClick={() => onDelete(row.id)}>
                            <RiDeleteBin6Line title="Delete" />
                          </IconButton>
                        ) : column.id === "engagementLetter" ? (
                          value?.length !== 0 && (
                            <a
                              href={value}
                              download
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton>
                                <Download />
                              </IconButton>
                            </a>
                          )
                        ) : column.format && typeof value === "number" ? (
                          column.format(value)
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        {/* Modal to display matter address */}
        <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogTitle>Matter Address</DialogTitle>
          <DialogContent>
            <Typography>{selectedAddress}</Typography>
          </DialogContent>
        </Dialog>
      </TableContainer>
    </Paper>
  );
};

export default ReusableTable;
