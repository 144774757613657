import React, { useEffect, useState } from "react";
import formatDate, {
  ConfirmationAlert,
  errorAlert,
  formatMonthDate,
  successAlert,
  customStyles,
} from "../../utils/services";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import Select from "react-select";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
  TablePagination,
  FormControl,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axiosInstance from "../../helpers/axios";
import CustomTooltip from "../../components/ui/CustomTootltip";
import CustomButton from "../../components/ui/CustomButton";
import NoDataFound from "../../components/NoDataFound";
import TableSkeletonLoader from "../../components/ui/TableSkeletonLoader";
import CustomModal from "../../components/ui/CustomModal";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { fetchEmployees } from "../../store/slices/employeesSlice";

const COLORS = ["#8884d8", "#82ca9d", "#ffc658", "#ff7f50", "#87cefa"];

const Leave = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { allEmployees: employees } = useSelector((state) => state.employee);

  useEffect(() => {
    dispatch(fetchEmployees());
  }, [dispatch]);

  const [leaveTable, setLeaveTable] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [selectedLawyer, setSelectedLawyer] = useState("");

  //modal states
  const [showModal, setShowModal] = useState(false);
  const [editingLeave, setEditingLeave] = useState(null);
  const [modalStartDate, setModalStartDate] = useState(formatDate(new Date()));
  const [modalEndDate, setModalEndDate] = useState(formatDate(new Date()));
  const [modalLeaveDescription, setModalLeaveDescription] = useState("");
  const [userID, setUserID] = useState("");
  const [leaveStatus, setLeaveStatus] = useState("approved");
  const [paidLeave, setPaidLeave] = useState("yes");
  const [leaveType, setLeaveType] = useState("leave");
  const [remarks, setRemarks] = useState("");

  //pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalLeavesCount, setTotalLeavesCount] = useState(0);
  const [loading, setLoading] = useState(false);

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Financial Year Dates
  // Determine the financial year dynamically
  const getFinancialYear = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    return currentMonth < 3 ? currentYear - 1 : currentYear;
  };

  const [selectedYear, setSelectedYear] = useState(getFinancialYear);

  // Function to generate years array (last 4 financial years)
  const generateYearsArray = () => {
    const baseYear = getFinancialYear();
    const years = [];
    for (let i = 0; i < 4; i++) {
      years.push(baseYear - i);
    }
    return years;
  };

  const years = generateYearsArray();

  useEffect(() => {
    fetchAllLeaves();
  }, [selectedYear, page, rowsPerPage, selectedLawyer]);

  // tabs
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // tab Panel
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  //Modal for add and update leave
  const openModal = (leave) => {
    if (leave) {
      setEditingLeave(leave);
      setUserID(leave.user._id);
      setModalStartDate(leave.leaveStartDate);
      setModalEndDate(leave.leaveEndDate);
      setModalLeaveDescription(leave.leaveDescription);
      setLeaveStatus(leave.leaveStatus);
      setPaidLeave(leave.paidLeave);
      setLeaveType(leave.leaveType);
      // console.log("leave data", leave, editingLeave);
    } else {
      setEditingLeave(null);
      setModalStartDate(formatDate(new Date()));
      setModalEndDate(formatDate(new Date()));
      setLeaveStatus("approved");
      setPaidLeave("yes");
      setLeaveType("leave");
      setModalLeaveDescription("");
      setUserID("");
      setRemarks("");
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  //get leaves
  const fetchAllLeaves = async () => {
    try {
      setLoading(true);

      // Derive dates directly from selectedYear
      const newStartDate = formatDate(new Date(selectedYear, 3, 1)); // April 1st
      const newEndDate = formatDate(new Date(selectedYear + 1, 2, 31)); // March 31st of next year

      const response = await axiosInstance.get(`/getPaginatedLeaves`, {
        params: {
          limit: rowsPerPage,
          page,
          adminId: auth.user._id,
          userId: selectedLawyer,
          startSelectedDate: newStartDate,
          endSelectedDate: newEndDate,
        },
      });

      setLeaves(response.data.leaves);
      setTotalLeavesCount(response.data.totalLeavesCount);

      // Similarly update other API calls
      const response2 = await axiosInstance.get(`/leavesByMonth`, {
        params: {
          adminId: auth.user._id,
          userId: selectedLawyer,
          startSelectedDate: newStartDate,
          endSelectedDate: newEndDate,
        },
      });

      const response3 = await axiosInstance.get(`/leaves/summary`, {
        params: {
          adminId: auth.user._id,
          userId: selectedLawyer,
          startSelectedDate: newStartDate,
          endSelectedDate: newEndDate,
        },
      });

      if (response3.data) {
        setLeaveTable(response3?.data?.data);
      }

      const chartData = Object.keys(response2.data.leavesByMonth)
        .map((month) => ({
          month,
          totalDays: response2.data.leavesByMonth[month].totalDays,
        }))
        .filter((entry) => entry.totalDays > 0)
        .reverse();

      setLeaveData(chartData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorAlert(
        error.response.data.message || "Error Fetching Leaves!!, Try again..."
      );
    }
  };

  //modal form validation
  const modalIsValidForm = () => {
    if (modalStartDate === "" || modalEndDate === "" || userID === "") {
      errorAlert("Start Date, End Date and Resource are required!!");
      return false;
    } else if (!(modalStartDate <= modalEndDate)) {
      errorAlert("Select Correct Dates!!");
      return false;
    } else {
      return true;
    }
  };

  //add and update leave
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (modalIsValidForm()) {
      const formData = {
        admin: auth.user._id,
        user: userID,
        leaveStartDate: modalStartDate,
        leaveEndDate: modalEndDate,
        leaveDescription: modalLeaveDescription,
        leaveStatus: leaveStatus,
        paidLeave: paidLeave,
        leaveType: leaveType,
        remarks,
      };

      try {
        if (editingLeave) {
          // Update existing leave
          const response = await axiosInstance.put(
            `/leaves/${editingLeave._id}`,
            formData
          );
          if (response.data) {
            successAlert(response.data.message);
            fetchAllLeaves();
            closeModal();
          }
        } else {
          // Add new leave
          const response = await axiosInstance.post(`/leaves`, formData);
          if (response.data) {
            successAlert(response.data.message);
            fetchAllLeaves();
            closeModal();
          }
        }
      } catch (error) {
        console.error("Error:", error);
        errorAlert(
          error.response.data.message ||
            (editingLeave
              ? "Error updating leave!! Try again..."
              : "Error creating leave!! Try again...")
        );
      }
      closeModal();
    }
  };

  //delete
  const handleDeleteLeave = (id) => {
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onDeleteLeave(id)}
        question={"Are you sure to delete this leave?"}
        answer={"Yes, Delete it"}
        icon={<RiDeleteBin6Line />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onDeleteLeave = async (leaveId) => {
    await axiosInstance
      .delete(`/leaves/${leaveId}`)
      .then((response) => {
        if (response.data) {
          successAlert(response.data.message);
          fetchAllLeaves();
        }
      })
      .catch((error) => {
        errorAlert(
          error.response.data.message || "Error Deleting Leave!!, Try again..."
        );
      });
  };

  useEffect(() => {
    fetchAllLeaves();
  }, []);

  return (
    <div>
      <ToastContainer />

      {/* Search Bar  */}
      <Paper elevation={0} className="rounded-full">
        <Grid container spacing={1} alignItems="center" marginBottom="1rem">
          {/* Main Heading  */}
          <Grid item container spacing={1} xs={12} sm={7} md={3}>
            <Grid item>
              <Typography variant="h5">Leaves</Typography>
            </Grid>
            <Grid item>
              <CustomTooltip text="Add Leave">
                <CustomButton
                  style={{
                    borderRadius: "50%",
                    minWidth: "5px",
                    height: "30px",
                    transition:
                      "transform 0.3s ease-in-out, background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#886a47",
                      transform: "scale(1.1)",
                    },
                  }}
                  onClick={() => openModal(null)}
                >
                  <AddIcon />
                </CustomButton>
              </CustomTooltip>
            </Grid>
          </Grid>

          {/* Resource  */}
          <Grid item xs={12} sm={5} md={2}>
            <Select
              options={employees}
              value={employees.find(
                (resOption) => resOption.value === `${selectedLawyer}`
              )}
              onChange={(e) => {
                setSelectedLawyer(e.value);
              }}
              isSearchable
              placeholder="Select Resource"
              styles={customStyles}
            />
          </Grid>

          {/* Year Dropdown */}
          <Grid item xs={12} sm={6} md={1.5}>
            <FormControl
              variant="outlined"
              size="small"
              style={{ minWidth: 120 }}
            >
              <MuiSelect
                value={selectedYear}
                onChange={(e) => setSelectedYear(parseInt(e.target.value, 10))}
                styles={customStyles}
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}-{year + 1}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      {/* Modal  */}
      {showModal && (
        <CustomModal
          showModal={showModal}
          closeModal={closeModal}
          title={editingLeave ? "Edit Leave" : "Add Leave"}
          onSubmit={handleSubmit}
        >
          <div className="flex gap-4 w-full">
            <div style={{ width: "50%" }}>
              <label>Start Date *</label>
              <input
                type="date"
                value={modalStartDate}
                onChange={(e) => setModalStartDate(e.target.value)}
              />
            </div>
            <div style={{ width: "50%" }}>
              <label>End Date *</label>
              <input
                type="date"
                value={modalEndDate}
                onChange={(e) => setModalEndDate(e.target.value)}
              />
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <div style={{ width: "50%" }}>
              <Select
                options={employees}
                value={employees.find(
                  (resOption) => resOption.label === `${userID}`
                )}
                onChange={(e) => {
                  setUserID(e.value);
                }}
                isSearchable
                placeholder="Select Resource"
                styles={customStyles}
              />
            </div>

            <div style={{ width: "50%" }}>
              <select
                style={{ width: "100%" }}
                value={leaveType}
                name="leaveType"
                onChange={(event) => setLeaveType(event.target.value)}
              >
                <option value="">Select Leave Type</option>
                <option value="quarterDay">Quarter Day</option>
                <option value="halfDay">Half Day</option>
                <option value="leave">Leave</option>
                <option value="workFromHome">Work From Home</option>
              </select>
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <div style={{ width: "50%" }}>
              <label>Select Status</label>
              <select
                value={leaveStatus}
                name="leaveStatus"
                onChange={(e) => setLeaveStatus(e.target.value)}
              >
                <option value="">Select Status</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Reject</option>
              </select>
            </div>

            <div style={{ width: "50%" }}>
              <label>Select Paid Leave</label>
              <select
                value={paidLeave}
                name="paidLeave"
                onChange={(e) => setPaidLeave(e.target.value)}
              >
                <option value="">Select One</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>

          <textarea
            placeholder="Leave description"
            value={modalLeaveDescription}
            onChange={(e) => setModalLeaveDescription(e.target.value)}
            style={{ width: "100%" }}
          />

          <textarea
            placeholder="Any remarks for HR"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            style={{ width: "100%", height: "40px" }}
          />
        </CustomModal>
      )}

      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        style={{ marginBottom: "1rem" }}
      >
        <Tab label="Overview" />
        <Tab label="Leave Entries" />
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        {/* chart */}
        {leaveData.length > 0 && (
          <Paper elevation={3} style={{ marginTop: "1rem", padding: "1rem" }}>
            <Typography variant="h6" gutterBottom>
              Leave Days by Month
            </Typography>

            <ResponsiveContainer width="100%" height={500}>
              <BarChart
                data={leaveData}
                margin={{ top: 20, right: 40, left: 40, bottom: 100 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="month"
                  tick={{
                    // angle: -20,
                    dy: 30,
                    fontSize: 12,
                  }}
                  interval={0}
                />
                <YAxis />
                <RechartsTooltip formatter={(value) => `${value} days`} />
                <Legend verticalAlign="top" align="right" />
                <Bar dataKey="totalDays" fill="#8884d8" name="Total Leave Days">
                  {leaveData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        )}

        {/* Main Table  */}
        {leaveTable && (
          <Paper sx={{ width: "100%", marginTop: "20px" }}>
            <TableContainer>
              <Table aria-label="reusable table">
                <TableHead>
                  {/* columns row  */}
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Resource
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Assigned Leaves
                    </TableCell>
                    <TableCell
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Used Leaves
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Available Leaves
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Assigned WFH
                    </TableCell>
                    <TableCell
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Used WFH
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Available WFH
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <>
                      <TableSkeletonLoader rows={6} colSpan={11} />
                    </>
                  ) : leaveTable.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={11}>
                        <NoDataFound message="Oops! No Leaves Found" />
                      </TableCell>
                    </TableRow>
                  ) : (
                    leaveTable.map((employee, index) => (
                      <TableRow
                        key={employee._id}
                        role="checkbox"
                        tabIndex={-1}
                        sx={{
                          backgroundColor: "white",
                        }}
                      >
                        <TableCell>{employee.resource}</TableCell>
                        <TableCell>{employee.assignLeaves}</TableCell>
                        <TableCell>{employee.usedLeaves}</TableCell>
                        <TableCell>{employee.availableLeaves}</TableCell>
                        <TableCell>{employee.assignedWFH}</TableCell>
                        <TableCell>{employee.usedWFH}</TableCell>
                        <TableCell>{employee.availableWFH}</TableCell>
                      </TableRow>
                    ))
                  )}
                  {/* Total row */}
                  <TableRow>
                    <TableCell colSpan={2} style={{ fontWeight: "bold" }}>
                      Total Leaves Used
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {/* Calculate the total of used leaves and used WFH */}
                      {leaveTable.reduce(
                        (total, employee) =>
                          total + employee.usedLeaves + employee.usedWFH,
                        0
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        {/* Entries Table  */}

        <Paper sx={{ width: "100%" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              style={{
                marginLeft: "10px",
                fontWeight: 500,
                fontStyle: "italic",
              }}
            >
              Recent Leaves
            </Typography>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={totalLeavesCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
          <TableContainer sx={{ maxHeight: "61vh" }}>
            <Table aria-label="reusable table">
              <TableHead>
                {/* columns row  */}
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Start Date - End Date
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Resource</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Days</TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Narration
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Leave Type
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Paid Leave
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Edit
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <>
                    <TableSkeletonLoader rows={6} colSpan={11} />
                  </>
                ) : leaves.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={11}>
                      <NoDataFound message="Oops! No Leaves Found." />
                    </TableCell>
                  </TableRow>
                ) : (
                  leaves.map((leave, index) => (
                    <TableRow
                      key={leave._id}
                      role="checkbox"
                      tabIndex={-1}
                      sx={{
                        backgroundColor: "white",
                        fontFamily: "Nunito Sans, sans-serif !important",
                      }}
                    >
                      <TableCell>
                        {formatMonthDate(leave.leaveStartDate)} -{" "}
                        {formatMonthDate(leave.leaveEndDate)}
                      </TableCell>
                      <TableCell>{leave.user.fullName}</TableCell>
                      <TableCell>{leave.leaveDays}</TableCell>
                      <TableCell className="table-cell">
                        {leave.leaveDescription}
                      </TableCell>
                      <TableCell>
                        {leave.leaveType === "workFromHome"
                          ? "Work From Home"
                          : leave.leaveType === "quarterDay"
                          ? "Quarter Day"
                          : leave.leaveType === "halfDay"
                          ? "Half Day"
                          : "Leave"}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            leave.leaveStatus === "approved"
                              ? "green"
                              : leave.leaveStatus === "pending"
                              ? "orange"
                              : "red",
                          fontWeight: "bold",
                          padding: "10px",
                        }}
                      >
                        {leave.leaveStatus === "pending"
                          ? "Pending"
                          : leave.leaveStatus === "approved"
                          ? "Approved"
                          : leave.leaveStatus === "rejected" && "Rejected"}
                      </TableCell>
                      <TableCell>{leave.paidLeave}</TableCell>
                      <TableCell className="table-cell">
                        <CiEdit
                          style={{ cursor: "pointer" }}
                          onClick={() => openModal(leave)}
                        />
                      </TableCell>
                      <TableCell className="table-cell">
                        <RiDeleteBin6Line
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteLeave(leave._id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
                <TableRow>
                  <TableCell colSpan={2}>Total Leaves</TableCell>
                  <TableCell>
                    {leaves.reduce(
                      (total, curr) => total + parseFloat(curr.leaveDays),
                      0
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </TabPanel>
    </div>
  );
};

export default Leave;
