import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfirmationAlert, formatDatePrevious } from "../../utils/services";
import ReusableTable from "../../components/DataTable";
import { errorAlert, successAlert } from "../../utils/services";
import Modal from "./EditMatter";
import AddMatter from "./AddMatter";
import axiosInstance from "../../helpers/axios";
import { RiDeleteBin6Line } from "react-icons/ri";
import { fetchAllMatters } from "../../store/slices/matterSlice";

const ShowMatter = () => {
  const dispatch = useDispatch();
  const matter = useSelector((state) => state.matter);
  const { matters, totalMatters, loading } = matter;

  //Modal states
  const [modalFormData, setModalFormData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  //pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    dispatch(fetchAllMatters({ rowsPerPage, page }));
  }, [dispatch, page, rowsPerPage]);

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    { id: "client", label: "Client", width: "15%" },
    { id: "matter", label: "Matter", width: "15%" },
    { id: "currency", label: "Currency", width: "5%" },
    { id: "email", label: "Outlook Email", width: "15%" },
    { id: "price", label: "Rates", width: "10%" },
    { id: "matterAddress", label: "Address", width: "10%" },
    { id: "flatFeesAmount", label: `Fixed Fees`, width: "10%" },
    { id: "advanceAmount", label: "Advance", width: "10%" },
    { id: "status", label: "Status", width: "5%" },
    { id: "matterOpenDate", label: "Opened when", width: "5%" },
    { id: "disableDate", label: "Disabled when", width: "5%" },
    { id: "engagementLetter", label: "EL", width: "5%" },
    { id: "edit", label: "Edit", width: "5%" },
    { id: "delete", label: "Delete", width: "5%" },
  ];

  const rows = matters.map((matter) => ({
    id: matter._id,
    client: matter.client.name,
    matter: matter.name,
    currency: matter.currency,
    email: matter.email,
    price: matter.pricePerHour
      ? matter.pricePerHour?.$numberDecimal
      : matter.resourceSpecificPrice,
    matterAddress: matter.matterAddress,
    status: matter.status,
    flatFeesAmount: matter.flatFeesAmount.$numberDecimal,
    advanceAmount: matter.advanceAmount.$numberDecimal,
    matterOpenDate: matter.matterOpenDate
      ? formatDatePrevious(matter.matterOpenDate)
      : "",
    disableDate: matter.disableDate
      ? formatDatePrevious(matter.disableDate)
      : "",
    engagementLetter:
      matter?.engagementLetter !== "" ? matter.engagementLetter : null,
  }));

  const handleOpenAddModal = () => {
    setModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setModalOpen(false);
  };

  const handleEdit = (data) => {
    setModalFormData(data);
    setIsModalOpen(true);
  };

  const handleDeleteMatter = (id) => {
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onDeleteMatter(id)}
        question={"Are you sure to delete this matter?"}
        answer={"Yes, Delete it"}
        icon={<RiDeleteBin6Line />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onDeleteMatter = async (matterId) => {
    await axiosInstance
      .delete(`/matter/delete/${matterId}`)
      .then((response) => {
        if (response.data) {
          successAlert("Matter Deleted Successfully!");
          dispatch(fetchAllMatters({ rowsPerPage, page }));
        }
      })
      .catch((error) => {
        errorAlert("Deletion of matter failed", "Please try again...");
      });
  };

  return (
    <div>
      <ToastContainer />

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            dispatch(fetchAllMatters({ rowsPerPage, page }));
          }}
          entryData={modalFormData}
        />
      )}

      <div style={{ marginBottom: 10 }}>
        {modalOpen && <AddMatter onClose={handleCloseAddModal} />}
      </div>

      <ReusableTable
        componentName={"Matters"}
        columns={columns}
        data={rows}
        onAdd={handleOpenAddModal}
        onEdit={handleEdit}
        onDelete={handleDeleteMatter}
        loading={loading}
        totalCount={totalMatters}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default ShowMatter;
