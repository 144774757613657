import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Grid,
  Typography,
  IconButton,
  TextField,
  Box,
} from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiDuplicate } from "react-icons/bi";
import CustomTooltip from "./CustomTootltip";
import useResponsive from "../../constants/useResponsive";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import formatDate, { errorAlert, formatMonthDate } from "../../utils/services";
import CustomButton from "./CustomButton";
import { useLocation } from "react-router-dom";

const WorkHourCard = ({
  time,
  handleSave,
  handleDelete,
  handleDuplicate,
  showDuplicate = true,
  showDelete = true,
  matters,
  handleEdit,
  isShowingPending,
  handleApprove,
  showApprove = false,
  showLawyerName = false,
}) => {
  const cardRef = useRef(null);
  const { isSm } = useResponsive();
  const [isEditing, setIsEditing] = useState(false);
  const location = useLocation();
  const isExportExcelPage = location.pathname === "/resource/exportexcel";

  // Use react-hook-form for form control
  const { control, handleSubmit, setValue, trigger } = useForm({
    defaultValues: {
      workDate: time.workDate,
      matter: time.matter?._id, // Using the matter's ID
      workDescription: time.workDescription,
      workHour: time.workHour,
    },
  });

  const handleSaveClick = (data) => {
    // Parse workHour as a float to ensure it's stored as a number
    const parsedWorkHour = parseFloat(data.workHour).toFixed(2);
    // console.log("parsedWorkHour", parsedWorkHour);

    // Validate the parsedWorkHour
    if (isNaN(parsedWorkHour) || parsedWorkHour <= 0) {
      // Handle the error accordingly, e.g., show a notification
      errorAlert("Kindly enter correct workhours!!");
      return;
    }

    const updatedTime = {
      ...time,
      ...data,
      workHour: parsedWorkHour, // Store as a number
    };

    handleSave(updatedTime);
    setIsEditing(false);
  };

  // Handle the matter change and trigger the update
  const handleMatterChange = async (option) => {
    try {
      // Update the form values with the selected matter and other details
      setValue("matter", option.value);
      setValue("selectedMatterName", option.label.split("|")[0]);
      setValue("client", option.label.split("|")[1]);
      trigger("matter"); // Trigger validation for the matter field
    } catch (error) {
      console.error("Error fetching matter details:", error);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setIsEditing(false);
      }
    }

    if (isEditing) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing]);

  useEffect(() => {
    if (isEditing) {
      setValue("workDate", formatDate(new Date(time.workDate)));
      setValue("matter", time.matter?._id);
      setValue("workDescription", time.workDescription);
      setValue("comments", time.comments);
      setValue("workHour", time.workHour);
    }
  }, [isEditing, time, setValue]);

  return (
    <CustomTooltip
      text={
        showLawyerName
          ? "Click to duplicate this entry"
          : isShowingPending
          ? "Click to edit and approve this entry"
          : "Click to edit this entry"
      }
      arrow
    >
      <Card
        ref={cardRef}
        elevation={0}
        sx={{
          padding: isSm ? 1 : 2,
          marginBottom: 2,
          display: "flex",
          alignItems: "center",
          border: "1px solid #e0e0e0",
          borderRadius: "10px",
          width: "100%",
          position: "relative",
          overflow: "visible",
          wordBreak: "break-word",
          boxSizing: "border-box",
        }}
        onClick={() => {
          setIsEditing(true);
          if (!showLawyerName) {
            handleEdit(time);
          }
        }}
      >
        <Grid container alignItems="center" spacing={2.5}>
          {isEditing ? (
            <>
              {/* AI Work Description Input */}
              <Grid item xs={isSm ? 12 : 4}>
                <Controller
                  name="workDescription"
                  control={control}
                  marginBottom={1}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      multiline
                      minRows={1}
                      maxRows={10}
                      placeholder="Work Description"
                    />
                  )}
                />
              </Grid>

              {/* Matter Select Dropdown */}
              <Grid item xs={isSm ? 12 : 2.5}>
                <Controller
                  name="matter"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={matters}
                      value={matters.find((m) => m.value === field.value)}
                      onChange={handleMatterChange}
                      placeholder="Select Matter"
                      isSearchable
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {/* Date Picker Input */}
              {/* <Grid item xs={isSm ? 6 : 1.5}>
                <Controller
                  name="workDate"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} type="date" fullWidth />
                  )}
                />
              </Grid> */}

              <Grid item xs={isSm ? 6 : 1.5}>
                <Controller
                  name="workDate"
                  control={control}
                  render={({ field }) => {
                    console.log(time);
                    // Extract dates (already in YYYY-MM-DD format)
                    const lastSentTsDate = time.matter.lastSentTSDate;
                    const matterOpenDate = time.matter.matterOpenDate;

                    const minDate =
                      lastSentTsDate > matterOpenDate
                        ? lastSentTsDate
                        : matterOpenDate;

                    return (
                      <TextField
                        {...field}
                        type="date"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: minDate || time.workDate, // Fallback to no constraint if no dates exist
                        }}
                      />
                    );
                  }}
                />
              </Grid>

              {/* Work Hour Input */}
              <Grid item xs={isSm ? 6 : 1}>
                <Controller
                  name="workHour"
                  control={control}
                  rules={{
                    required: "Work hours are required",
                    min: { value: 0, message: "Work hours cannot be negative" },
                    max: { value: 24, message: "Work hours cannot exceed 24" },
                    pattern: {
                      value: /^\d*\.?\d{0,2}$/,
                      message:
                        "Enter a valid number with up to two decimal places",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="number"
                      fullWidth
                      placeholder="Hours"
                      inputProps={{
                        step: 0.1, // Set the step to 0.1 for correct increment/decrement
                        min: 0, // Optional: set a minimum value
                        max: 24, // Optional: set a maximum value
                      }}
                      onChange={(e) => {
                        let value = e.target.value;

                        // Prepend '0' if the value starts with a dot
                        if (value.startsWith(".")) {
                          value = "0" + value;
                        }

                        // Optionally, limit to two decimal places
                        if (value.includes(".")) {
                          const [integerPart, decimalPart] = value.split(".");
                          value = `${integerPart}.${decimalPart.slice(0, 2)}`;
                        }

                        field.onChange(value);
                      }}
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />

                {/* <Controller
                  name="workHour"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      fullWidth
                      placeholder="Hours"
                    />
                  )}
                /> */}
              </Grid>

              {/* Comments  */}
              <Grid item xs={isSm ? 12 : 2}>
                <Controller
                  name="comments"
                  control={control}
                  marginBottom={1}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      multiline
                      minRows={1}
                      maxRows={10}
                      placeholder="Comments for the reviewer"
                    />
                  )}
                />
              </Grid>

              {/* Action Buttons: Save/Cancel */}
              <Grid item xs={12} md={1} sm={1}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {/* Save Button */}
                  <Grid item xs={5}>
                    <CustomTooltip
                      text={
                        showLawyerName
                          ? "Duplicate"
                          : isShowingPending
                          ? "Approve"
                          : "Save"
                      }
                    >
                      {window.innerWidth <= 480 ? (
                        <CustomButton
                          onClick={handleSubmit(handleSaveClick)}
                          style={{
                            marginTop: "6px",
                            fontSize: "16px",
                            width: "100%", // Ensures the button takes the full width
                          }}
                        >
                          Save
                        </CustomButton>
                      ) : (
                        <IconButton
                          style={{ color: "green", width: "100%" }}
                          onClick={handleSubmit(handleSaveClick)}
                        >
                          <Check style={{ fontSize: "20px" }} />
                        </IconButton>
                      )}
                    </CustomTooltip>
                  </Grid>

                  {/* Cancel Button */}
                  <Grid item xs={5}>
                    <CustomTooltip text="Cancel">
                      {window.innerWidth <= 480 ? (
                        <CustomButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsEditing(false);
                          }}
                          style={{
                            marginTop: "6px",
                            fontSize: "16px",
                            width: "100%", // Ensures the button takes the full width
                          }}
                        >
                          Cancel
                        </CustomButton>
                      ) : (
                        <IconButton
                          style={{ color: "red", width: "100%" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsEditing(false);
                          }}
                        >
                          <Close style={{ fontSize: "20px" }} />
                        </IconButton>
                      )}
                    </CustomTooltip>
                  </Grid>
                </Box>
              </Grid>
            </>
          ) : (
            <>
              {/* Display Mode */}

              {showDuplicate && isExportExcelPage ? (
                <>
                  {/* workDescription */}
                  <Grid item xs={isSm ? 12 : 6}>
                    <Typography>{time.workDescription}</Typography>
                  </Grid>
                  {/* Matter name */}
                  <Grid item xs={isSm ? 4 : 2.5}>
                    {/* Fix: Render matter's name, not the object */}
                    <Typography>
                      {time.matter?.name || "Unknown Matter"}
                    </Typography>
                  </Grid>
                  {/* Work Date */}
                  <Grid item xs={isSm ? 4 : 1}>
                    <Typography>{formatMonthDate(time.workDate)}</Typography>
                  </Grid>
                  {/* Lawyer Name */}
                  {showLawyerName && time.user?.fullName && (
                    <Grid item xs={isSm ? 4 : 1.5}>
                      <Typography
                        variant="body2"
                        style={{ fontSize: isSm ? "16px" : "14px" }}
                      >
                        {time.user?.fullName}
                      </Typography>
                    </Grid>
                  )}

                  {/* Work Hour */}
                  <Grid item xs={isSm ? 6 : 0.9} textAlign="center">
                    <Typography>{time.workHour} h</Typography>
                  </Grid>

                  <Grid
                    item
                    xs={isSm ? 6 : "auto"}
                    sx={{
                      display: {
                        xs: "block",
                        sm: "none",
                        md: "none",
                        lg: "none",
                      },
                      textAlign: "center",
                    }}
                  >
                    <CustomButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDuplicate(time);
                      }}
                      style={{
                        marginTop: "6px",
                        fontSize: "12px",
                        width: "60%",
                      }}
                    >
                      Duplicate
                    </CustomButton>
                  </Grid>
                </>
              ) : (
                <>
                  {/* workDescription */}
                  <Grid item xs={isSm ? 7 : 6}>
                    <Typography>{time.workDescription}</Typography>
                  </Grid>
                  {/* Matter name */}
                  <Grid item xs={isSm ? 5 : 2.5}>
                    {/* Fix: Render matter's name, not the object */}
                    <Typography>
                      {time.matter?.name || "Unknown Matter"}
                    </Typography>
                  </Grid>
                  {/* Work Date */}
                  <Grid item xs={isSm ? 7 : 1}>
                    <Typography>{formatMonthDate(time.workDate)}</Typography>
                  </Grid>
                  {/* Lawyer Name */}
                  {showLawyerName && time.user?.fullName && (
                    <Grid item xs={isSm ? 5 : 1.5}>
                      <Typography
                        variant="body2"
                        style={{ fontSize: isSm ? "16px" : "14px" }}
                      >
                        {time.user?.fullName}
                      </Typography>
                    </Grid>
                  )}

                  {/* Work Hour */}
                  <Grid item xs={isSm ? 5 : 0.9}>
                    <Typography>{time.workHour} h</Typography>
                  </Grid>
                </>
              )}

              {/* Work Hour */}
              {/* <Grid item xs={isSm ? 5 : 0.9}>
                <Typography>{time.workHour} h</Typography>
              </Grid> */}

              {/* Approve Button */}
              {showApprove && isShowingPending && (
                <Grid item xs={isSm ? 6 : 0.6}>
                  <CustomTooltip text="Approve">
                    {window.innerWidth <= 480 ? (
                      <CustomButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleApprove(time._id);
                        }}
                        style={{
                          marginTop: "6px",
                          fontSize: "16px",
                        }}
                      >
                        Approve
                      </CustomButton>
                    ) : (
                      <IconButton
                        style={{ color: "green" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleApprove(time._id);
                        }}
                      >
                        <Check style={{ fontSize: "20px" }} />
                      </IconButton>
                    )}
                  </CustomTooltip>
                </Grid>
              )}

              {/* Add Time Duplicate Action */}
              {showDuplicate && !isShowingPending && !showLawyerName && (
                <Grid item xs={isSm ? 6 : 0.6} style={{ textAlign: "right" }}>
                  <CustomTooltip text="Duplicate">
                    {window.innerWidth <= 480 ? (
                      <CustomButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDuplicate(time);
                        }}
                        style={{
                          // color: "green",
                          // border: "none",
                          // background: "transparent",
                          marginTop: "6px",
                          // marginLeft: "20px",

                          fontSize: "12px",
                        }}
                      >
                        {" "}
                        Duplicate
                      </CustomButton>
                    ) : (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDuplicate(time);
                        }}
                      >
                        <BiDuplicate size={15} />
                      </IconButton>
                    )}
                  </CustomTooltip>
                </Grid>
              )}

              {/* Delete Button */}
              {showDelete && (
                <Grid item xs={isSm ? 6 : 0.6}>
                  <CustomTooltip text="Delete">
                    {window.innerWidth <= 480 ? (
                      <CustomButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(time);
                        }}
                        variant="outlined"
                        style={{
                          // color: "green",
                          // border: "none",
                          // background: "transparent",
                          marginTop: "6px",
                          // marginLeft: "20px",

                          fontSize: "12px",
                        }}
                      >
                        {" "}
                        Delete
                      </CustomButton>
                    ) : (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(time);
                        }}
                      >
                        <RiDeleteBin6Line size={16} />
                      </IconButton>
                    )}
                  </CustomTooltip>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Card>
    </CustomTooltip>
  );
};

export default WorkHourCard;
