import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PopoverGroup } from "@headlessui/react";
import { Avatar, Typography } from "@mui/material";
import { Logout, Add } from "@mui/icons-material";
import {
  FaHome,
  FaReceipt,
  FaCalendarAlt,
  FaSignOutAlt,
  FaBusinessTime,
} from "react-icons/fa";
import SickIcon from "@mui/icons-material/Sick";
import { useDispatch, useSelector } from "react-redux";
import { authConstants } from "../../actions/constants";
import { COLORS } from "../../constants/theme";
import CustomTooltip from "./CustomTootltip";
import { fetchDesignations } from "../../store/slices/globalSlice";

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth < 1024); // Assuming 1024px as the breakpoint for laptop view
    };

    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //fetch holidays and designations
  useEffect(() => {
    dispatch(fetchDesignations());
  }, [dispatch]);

  const { billedDesignations } = useSelector((state) => state.global);

  const location = useLocation();
  const auth = useSelector((state) => state.auth);

  const onLogOut = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    localStorage.removeItem("selectedComponent");
    dispatch({
      type: authConstants.LOGOUT_SUCCESS,
    });
    navigate("/");
  };

  const isActive = (path) => location.pathname === path;

  // Get initials from the user's name
  const getUserInitials = (name) => {
    const nameParts = name?.split(" ") || [];
    const firstNameInitial = nameParts[0]?.charAt(0).toUpperCase() || "";
    const lastNameInitial = nameParts[1]?.charAt(0).toUpperCase() || "";
    return `${firstNameInitial}${lastNameInitial}`;
  };

  if (!mobileView) {
    return (
      <header className="bg-white border-b border-slate-500">
        <nav
          aria-label="Global"
          className="flex w-full items-center justify-between p-6 lg:px-14"
        >
          <div className="flex lg:flex-1">
            <a href="#/resourceDashboard" className="">
              <img
                src={`https://comput-images.blr1.cdn.digitaloceanspaces.com/Comput_labs_black.png`}
                alt="logo"
                className="h-8 w-auto"
              />
            </a>
          </div>

          <PopoverGroup className="hidden lg:flex lg:gap-x-12">
            <Typography
              component="a"
              href="#/resourceDashboard"
              variant={isActive("/resourceDashboard") ? "body2" : "body1"}
            >
              Dashboard
            </Typography>

            <Typography
              component="a"
              href="#/resource/addtime"
              variant={isActive("/resource/addtime") ? "body2" : "body1"}
            >
              Time
            </Typography>
            <Typography
              component="a"
              href="#/resource/ope"
              variant={isActive("/resource/ope") ? "body2" : "body1"}
            >
              Expense
            </Typography>
            <Typography
              component="a"
              href="#/resource/calendar"
              variant={isActive("/resource/calendar") ? "body2" : "body1"}
            >
              Calendar
            </Typography>
            <Typography
              component="a"
              href="#/resource/leaves"
              variant={isActive("/resource/leaves") ? "body2" : "body1"}
            >
              Leave
            </Typography>
            {billedDesignations.includes(auth?.user?.designation || "") && (
              <Typography
                component="a"
                href="#/resource/exportexcel"
                variant={isActive("/resource/exportexcel") ? "body2" : "body1"}
              >
                TimeImport
              </Typography>
            )}
            {/* <Typography
              component="a"
              href="#/resource/timetracker"
              variant={isActive("/resource/timetracker") ? "body2" : "body1"}
            >
              Time Tracker
            </Typography> */}
          </PopoverGroup>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
            <CustomTooltip text={auth.user.fullName}>
              <Avatar
                sx={{
                  bgcolor: "lightgrey",
                  marginRight: 2,
                  color: COLORS.primary,
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                {getUserInitials(auth?.user?.fullName)}
              </Avatar>
            </CustomTooltip>
            <button
              onClick={onLogOut}
              className="font-semibold text-white bg-black px-4 py-2 rounded-lg flex align-bottom"
            >
              <Logout className="mx-1" /> Logout
            </button>
          </div>
        </nav>
      </header>
    );
  }

  return (
    <>
      {/* Top Header for Mobile */}
      <header className="fixed top-0 left-0 right-0 bg-white border-b border-slate-200 z-50">
        <div className="flex items-center justify-between px-4 h-16">
          <a href="#/resourceDashboard" style={{ textDecoration: 'none' }}>
            <CustomTooltip text={auth.user.fullName}>
              <Avatar
                sx={{
                  bgcolor: "#f8f8f8",
                  marginRight: 2,
                  color: COLORS.primary,
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                {getUserInitials(auth?.user?.fullName)}
              </Avatar>
            </CustomTooltip>
          </a>
          <a
            href="#/resourceDashboard"
            className="absolute left-1/2 -translate-x-1/2"
          >
            <img
              src="https://comput-images.blr1.cdn.digitaloceanspaces.com/Comput_labs_black.png"
              alt="logo"
              className="h-8 w-auto"
            />
          </a>

          <button onClick={onLogOut} className="p-2">
            <FaSignOutAlt className="h-5 w-5" />
          </button>
        </div>
      </header>

      {/* Main Content Area */}
      <main className="pt-5 pb-3">{/* Your page content here */}</main>

      {/* Bottom Navigation for Mobile */}
      <nav className="fixed bottom-0 left-0 right-0 bg-white border-t border-slate-200 z-50">
        <div className="relative flex justify-around items-center h-20">
          {billedDesignations.includes(auth?.user?.designation || "") ? (
            ""
          ) : (
            <a
              href="#/resourceDashboard"
              className="flex flex-col items-center p-2 text-xs"
            >
              <FaHome className="h-5 w-5" />
              <span>Home</span>
            </a>
          )}

          {billedDesignations.includes(auth?.user?.designation || "") && (
            <a
              href="#/resource/ope"
              className={`flex flex-col items-center p-2 text-xs ${isActive("/resource/ope") ? "text-blue-500" : "text-black"
                }`}
            >
              <FaReceipt className="h-5 w-5" />
              <span>Expense</span>
            </a>
          )}

          <a
            href="#/resource/calendar"
            className={`flex flex-col items-center p-2 text-xs ${isActive("/resource/calendar") ? "text-blue-500" : "text-black"
              }`}
            style={{ marginRight: "60px" }}
          >
            <FaCalendarAlt className="h-5 w-5" />
            <span>Calendar</span>
          </a>

          {billedDesignations.includes(auth?.user?.designation || "") ? (
            ""
          ) : (
            <a
              href="#/resource/ope"
              className={`flex flex-col items-center p-2 text-xs ${isActive("/resource/ope") ? "text-blue-500" : "text-black"
                }`}
            >
              <FaReceipt className="h-5 w-5" />
              <span>Expense</span>
            </a>
          )}

          <div
            className={`absolute -top-6 flex justify-center items-center w-16 h-16 rounded-full shadow-lg border ${isActive("/resource/addtime")
                ? "bg-white text-blue-500"
                : "bg-white text-black border-slate-200"
              }`}
          >
            <a href="#/resource/addtime" className="text-center text-2xl">
              <Add style={{ fontSize: "36px" }} />
            </a>
          </div>

          {billedDesignations.includes(auth?.user?.designation || "") && (
            <a
              href="#/resource/exportexcel"
              className={`flex flex-col items-center p-2 text-xs ${isActive("/resource/exportexcel")
                  ? "text-blue-500"
                  : "text-black"
                }`}
            >
              <FaBusinessTime className="h-5 w-5" />
              <span>TimeImport</span>
            </a>
          )}

          <a
            href="#/resource/leaves"
            className={`flex flex-col items-center p-2 text-xs ${isActive("/resource/leaves") ? "text-blue-500" : "text-black"
              }`}
          >
            <SickIcon style={{ fontSize: "20px" }} />
            <span>Leave</span>
          </a>
        </div>
      </nav>
    </>
  );
}
