import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  TablePagination,
  Typography,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getPendingEntriesCount, getWorkHourByUserId } from "../../actions";
import CustomButton from "../../components/ui/CustomButton";
import formatDate, {
  addOneDay,
  customStyles,
  errorAlert,
  formatMonthDate,
  successAlert,
} from "../../utils/services";
import { Add, Remove } from "@mui/icons-material"; // Added Add and Close icons
import axiosInstance from "../../helpers/axios";
// import SkeletonRows from "../../components/ui/CardSkeletonLoader.js";
import NoDataFound from "../../components/NoDataFound";
import { HiSparkles } from "react-icons/hi2";
import Header from "../../components/ui/Header";
import CustomTooltip from "../../components/ui/CustomTootltip";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";
import { COLORS } from "../../constants/theme";
import WorkHourCard from "../../components/ui/WorkHourCard";
import useResponsive from "../../constants/useResponsive.js";
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TfiReload } from "react-icons/tfi";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
// import { fetchAllMatters } from "../../store/slices/matterSlice.js";
// import MatterDropdown from "../../components/resource/MatterDropdown.js";
import { fetchAssignedMatters } from "../../store/slices/assignedMatterSlice.js";

// Validation Schema
const validationSchema = Yup.object().shape({
  selectedDate: Yup.string().required("Date is required"),
  matter: Yup.string().required("Matter is required"),
  workDescription: Yup.string().required("Work Description is required"),
  workHour: Yup.string().required("Work Hour is required"),
  workMin: Yup.string().required("Work Minute is required"),
});

const AddTime = () => {
  const dispatch = useDispatch();
  const { isSm } = useResponsive();
  // const { filteredMatters } = useSelector((state) => state.matter);
  const { filteredMatters } = useSelector((state) => state.assignedMatters);

  // console.log(filteredMatters, assignedMatters);

  // Create a Set of assigned matter values for quick lookup
  // const assignedValues = new Set(assignedMatters.map((matter) => matter.value));

  // // Filter out matters that are not assigned
  // const unassignedMatters = filteredMatters.filter(
  //   (matter) => matter.value && !assignedValues.has(matter.value)
  // );

  // console.log("Unassigned Matters:", unassignedMatters);

  useEffect(() => {
    // dispatch(fetchAllMatters());
    dispatch(fetchAssignedMatters({ rowsPerPage: 0, page: 0, search: "" }));
  }, [dispatch]);

  const auth = useSelector((state) => state.auth);
  const time = useSelector((state) => state.time);

  const pendingEntriesCount = useSelector(
    (state) => state.time.pendingEntriesCount
  );

  const { userWorkHour, totalCount } = time;

  const [editingEntry, setEditingEntry] = useState(null);
  const [duplicatingEntry, setDuplicatingEntry] = useState(null);
  const [isTableFullWidth, setIsTableFullWidth] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 7));
    return formatDate(pastDate);
  });
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );
  const [isShowingPending, setIsShowingPending] = useState(false); // false for Fulfilled, true for Pending
  const [lastSentTSDate, setLastSentTSDate] = useState(null);
  const [matterOpenDate, setMatterOpenDate] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(true);

  // Pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // AI suggestion state
  const [aiSuggestion, setAiSuggestion] = useState("");
  const [filterOption, setFilterOption] = useState("All"); // New state variable for filter

  // Pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(
      getWorkHourByUserId(
        isShowingPending ? "" : startSelectedDate,
        isShowingPending ? "" : endSelectedDate,
        searchQuery,
        page,
        rowsPerPage,
        isShowingPending ? false : true
      )
    );
  }, [
    dispatch,
    auth.user._id,
    auth.user.company,
    page,
    rowsPerPage,
    startSelectedDate,
    endSelectedDate,
    searchQuery,
    isShowingPending,
  ]);

  useEffect(() => {
    if (auth.user && auth.user._id) {
      dispatch(getPendingEntriesCount(auth.user._id));
    }
  }, [dispatch, auth.user]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      selectedDate: formatDate(new Date()),
      // matter: "",
      // client: "",
      // selectedMatterName: "",
      workDescription: "",
      comments: "",
      workHour: "",
      workMin: "",
    },
  });

  useEffect(() => {
    const entry = editingEntry || duplicatingEntry;
    if (entry) {
      setValue("selectedDate", formatDate(new Date(entry.workDate)));
      setValue("matter", entry.matter._id);
      setValue("selectedMatterName", entry.matter.name);
      setValue("workDescription", entry.workDescription);
      setValue("comments", entry.comments);

      // Split workHour into hour and minute
      let [hour, min] = entry.workHour.split(".");

      // Ensure hour is set
      setValue("workHour", hour);

      // If min is undefined, set it to "0"; otherwise, check its length
      if (!min) {
        min = "00"; // Default to "00" if no minutes are specified
      } else if (min.length === 1) {
        // If the minute has only one digit, append "0"
        min = min + "0";
      }

      // Convert the corrected min to the nearest 6-minute increment
      const minuteValue = Math.floor((parseFloat(min) * 6) / 10);

      // Set the minute value in the form
      setValue("workMin", minuteValue);
    }
  }, [editingEntry, duplicatingEntry, setValue]);

  // Function to fetch AI suggestion
  const getAiSuggestion = async (workDescription, isDescriptive = false) => {
    try {
      const response = await axiosInstance.post(`/ai/suggest`, {
        workDescription,
        isDescriptive, // Pass the descriptive flag to the backend
      });
      return response.data.aiSuggestedDescription;
    } catch (error) {
      console.error("Error fetching AI suggestion:", error);
      errorAlert("Failed to get AI suggestion. Please try again.");
      return "";
    }
  };

  // Normal submit handler
  const onSubmit = async (data) => {
    if (
      data.selectedDate &&
      data.matter &&
      data.workDescription &&
      data.workHour !== "00" &&
      data.workMin !== "00"
    ) {
      const timeData = {
        admin: auth?.user?.company,
        user: auth?.user?._id,
        workDate: data.selectedDate,
        matter: data.matter,
        workDescription: data.workDescription,
        comments: data.comments,
        workHour: `${data.workHour}.${data.workMin * (10 / 6)}`,
        reviewed: true,
      };

      if (
        data.selectedDate <= lastSentTSDate ||
        data.selectedDate < matterOpenDate
      ) {
        errorAlert("Time entry is blocked for this date.");
        return;
      }

      if (editingEntry) {
        // Update the entry
        try {
          const response = await axiosInstance.put(
            `/workHour/update/${editingEntry._id}`,
            timeData
          );

          if (response.data) {
            successAlert("Time Updated Successfully!");
            fetchWorkHours();
            resetForm();
          }
        } catch (error) {
          console.error("Error updating time:", error);
          errorAlert("Error updating time");
        }
      } else {
        // Add or duplicate an entry
        try {
          const response = await axiosInstance.post(
            `/workHour/createNew`,
            timeData
          );

          if (response.data) {
            successAlert("Great !! Time Added Successfully!");
            fetchWorkHours();
            resetForm();
          }
        } catch (error) {
          console.error("Error adding time:", error);
          errorAlert("Error adding time");
        }
      }
    } else {
      errorAlert("All Fields are Required!");
    }
  };

  // Submit with AI suggestion
  const onSubmitWithAiSuggestion = async (data) => {
    if (
      data.selectedDate &&
      data.matter &&
      aiSuggestion &&
      data.workHour !== "00" &&
      data.workMin !== "00"
    ) {
      const timeData = {
        admin: auth?.user?.company,
        user: auth?.user?._id,
        workDate: data.selectedDate,
        matter: data.matter,
        workDescription: aiSuggestion, // Use the AI suggestion
        comments: data.comments,
        workHour: `${data.workHour}.${data.workMin * (10 / 6)}`,
        reviewed: true,
      };

      if (
        data.selectedDate <= lastSentTSDate ||
        data.selectedDate < matterOpenDate
      ) {
        errorAlert("Time entry is blocked for this date.");
        return;
      }

      try {
        if (editingEntry) {
          // Update the existing entry with AI suggestion
          const response = await axiosInstance.put(
            `/workHour/update/${editingEntry._id}`,
            timeData
          );

          if (response.data) {
            successAlert("Time Updated Successfully with AI Suggestion!");
            fetchWorkHours();
            resetForm();
          }
        } else {
          // Create a new entry with AI suggestion
          const response = await axiosInstance.post(
            `/workHour/createNew`,
            timeData
          );

          if (response.data) {
            successAlert("Time Added Successfully with AI Suggestion!");
            fetchWorkHours();
            resetForm();
          }
        }
      } catch (error) {
        console.error(
          "Error adding or updating time with AI suggestion:",
          error
        );
        errorAlert("Error adding or updating time with AI suggestion");
      }
    } else {
      errorAlert("All Fields are Required!");
    }
  };

  // Reset form
  const resetForm = () => {
    reset({
      // selectedDate: formatDate(new Date()), // Reset selectedDate to current date
      // matter: "",
      // client: "",
      // selectedMatterName: "",
      workDescription: "",
      comments: "",
      workHour: "",
      workMin: "",
    });
    setEditingEntry(null);
    setDuplicatingEntry(null);
    setAiSuggestion("");
  };

  const handleEdit = (time) => {
    // console.log("time data in edit", time);

    const matterId = { value: time.matter._id };
    // setEditingEntry(entry);
    handleMatterChange(matterId);
    setDuplicatingEntry(null);
    setAiSuggestion("");
    setIsFormVisible(false);
  };

  const toggleFormVisibility = () => {
    setIsFormVisible((prev) => !prev);
    // if (isFormVisible) resetForm(); // Reset form when hiding
  };

  // Remove filtering by 'reviewed' status, as it's handled server-side
  const filteredWorkHours = userWorkHour.filter((entry) => {
    // Apply additional filter when 'Maybe' is selected and 'filterOption' is not 'All'
    if (isShowingPending && filterOption !== "All") {
      if (filterOption === "Meeting") {
        return !!entry.meetingId;
      } else if (filterOption === "Email") {
        return !!entry.emailId;
      }
    }
    return true;
  });

  const handleMatterChange = async (option) => {
    // console.log("option data", option);
    try {
      const response = await axiosInstance.get(
        `/matter/getMatterByMatterId/${option.value}`
      );

      const matterDetails = response.data?.matterDetails;

      // Ensure the dates are valid
      const matterOpenDate = matterDetails?.matterOpenDate
        ? new Date(matterDetails.matterOpenDate)
        : null;
      const lastSentTSDate = matterDetails?.lastSentTSDate
        ? new Date(matterDetails.lastSentTSDate)
        : null;

      // Get today's date without the time part
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      let minDate;

      // Determine the appropriate minimum date
      if (matterOpenDate && lastSentTSDate) {
        minDate =
          matterOpenDate < today && lastSentTSDate < today
            ? today
            : matterOpenDate > lastSentTSDate
            ? matterOpenDate
            : lastSentTSDate;
      } else if (matterOpenDate) {
        minDate = matterOpenDate < today ? today : matterOpenDate;
      } else if (lastSentTSDate) {
        minDate = lastSentTSDate < today ? today : lastSentTSDate;
      } else {
        minDate = today;
      }

      // Format the minimum date to "yyyy-MM-dd"
      const formattedMinDate = formatDate(minDate);

      console.log(
        formattedMinDate,
        formatDate(matterOpenDate),
        formatDate(lastSentTSDate || null)
      );

      // Update the state
      setMatterOpenDate(addOneDay(matterDetails?.matterOpenDate) || null);
      setLastSentTSDate(addOneDay(matterDetails?.lastSentTSDate) || null);

      if (option.label) {
        // Update form values dynamically
        setValue("matter", option.value);
        setValue("selectedMatterName", option.label.split("|")[0]);
        setValue("client", option.label.split("|")[1]);
        setValue("selectedDate", formattedMinDate); // Set the minimum date as the default selected date

        trigger("matter"); // Trigger validation for the 'matter' field
      }
    } catch (error) {
      console.error("Error fetching matter details:", error);
    }
  };

  //duplicate entries
  const handleDuplicate = (entry) => {
    if (isTableFullWidth) {
      setIsTableFullWidth((prev) => !prev);
    }
    setDuplicatingEntry(entry);
    setEditingEntry(null);
    setIsFormVisible(true);
  };

  // Approve handler function
  const handleApprove = async (entryId) => {
    try {
      const response = await axiosInstance.put(`/workHour/update/${entryId}`, {
        reviewed: true,
      });

      if (response.data) {
        successAlert("Time Approved Successfully!");
        fetchWorkHours(); // Refresh the work hours after approval
      }
    } catch (error) {
      console.error("Error approving time:", error);
      errorAlert("Error approving time");
    }
  };

  // Fetch work hours after any action
  const fetchWorkHours = () => {
    dispatch(
      getWorkHourByUserId(
        isShowingPending ? "" : startSelectedDate,
        isShowingPending ? "" : endSelectedDate,
        searchQuery,
        page,
        rowsPerPage,
        isShowingPending ? false : true
      )
    );
    dispatch(getPendingEntriesCount(auth.user._id));
  };

  // states and functions for date range picker
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  // Function to receive selected dates from DateRangePickerTst
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setStartSelectedDate(startDate);
    setEndSelectedDate(endDate);
    console.log(startDate, endDate);
  };

  // State to keep track of the deleted item
  const [deletedItem, setDeletedItem] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deletedEntries, setDeletedEntries] = useState([]);

  // Delete handler
  const handleSoftDelete = async (time) => {
    const matterId = { value: time.matter._id };
    await handleMatterChange(matterId);

    console.log("delete time data", time, lastSentTSDate, matterOpenDate);

    if (time.workDate <= lastSentTSDate || time.workDate < matterOpenDate) {
      errorAlert("Time entry is blocked for this date.");
      return;
    }

    try {
      const response = await axiosInstance.delete(
        `/workhour/softdelete/${time._id}`
      );

      if (response.data) {
        setDeletedItem({
          entryId: response.data.deletedWorkhour._id,
          ...response.data,
        });
        console.log("deletedItem", deletedItem);

        setSnackbarOpen(true);
        fetchWorkHours();
      } else {
        errorAlert("Failed to delete workhour.");
      }
    } catch (error) {
      errorAlert("An error occurred while deleting workhour.");
    }
  };

  // Undo handler
  const handleUndo = async () => {
    try {
      if (deletedItem) {
        const response = await axiosInstance.post(
          `/workhour/restore/${deletedItem.entryId}`
        );

        if (response.data) {
          // Remove the item from temporary state
          setDeletedItem(null);
          successAlert("Workhour restored successfully.");
          fetchWorkHours();
        } else {
          errorAlert("Failed to restore workhour.");
        }
      }
    } catch (error) {
      errorAlert("An error occurred while restoring workhour.");
    }
  };

  const [isRetryingAi, setIsRetryingAi] = useState(false); // New state to track retries

  // AI submit handler
  const onSubmitWithAi = async (data) => {
    if (
      data.selectedDate &&
      data.matter &&
      data.workDescription &&
      data.workHour !== "00" &&
      data.workMin !== "00"
    ) {
      const suggestion = await getAiSuggestion(data.workDescription, false);
      setAiSuggestion(suggestion);
      setIsRetryingAi(false); // Reset retry state
    } else {
      errorAlert("All Fields are Required!");
    }
  };

  // Function to retry AI suggestion
  const retryAiSuggestion = async () => {
    setIsRetryingAi(true); // Set retry state to true
    const currentWorkDescription = watch("workDescription"); // Get the current work description from the form
    const newSuggestion = await getAiSuggestion(currentWorkDescription, false); // Fetch a new suggestion
    setAiSuggestion(newSuggestion); // Update the AI suggestion
    setIsRetryingAi(false); // Reset retry state after fetching
  };

  // Handle saving of edited work hour entry
  const handleSave = async (updatedEntry) => {
    // console.log("data coming for save", updatedEntry);

    if (
      updatedEntry.workDate < lastSentTSDate ||
      updatedEntry.workDate < matterOpenDate
    ) {
      errorAlert("Time entry is blocked for this date.");
      return;
    }

    try {
      const response = await axiosInstance.put(
        `/workHour/update/${updatedEntry._id}`,
        { ...updatedEntry, reviewed: true }
      );

      if (response.data) {
        successAlert("Time Updated Successfully!");
        fetchWorkHours(); // Refresh the data after saving
      }
    } catch (error) {
      console.error("Error updating work hour:", error);
      errorAlert("Error updating work hour.");
    }
  };

  const [selectedEntries, setSelectedEntries] = useState([]);
  const [multipleDeleteSnackbarOpen, setMultipleDeleteSnackbarOpen] =
    useState(false);

  // Add this function to handle checkbox changes
  const handleCheckboxChange = (entryId) => {
    setSelectedEntries((prevSelected) => {
      if (prevSelected.includes(entryId)) {
        // Remove entry if it's already selected
        return prevSelected.filter((id) => id !== entryId);
      } else {
        // Add entry if it's not selected
        return [...prevSelected, entryId];
      }
    });
  };

  const handleDeleteSelected = async () => {
    try {
      for (const entryId of selectedEntries) {
        await axiosInstance.delete(`/workhour/softdelete/${entryId}`);
      }

      // Clear the selection
      setDeletedEntries(selectedEntries);
      setSelectedEntries([]);
      setMultipleDeleteSnackbarOpen(true);

      // Refresh work hours
      fetchWorkHours();

      successAlert("Selected entries deleted successfully.");
    } catch (error) {
      errorAlert("An error occurred while deleting entries.");
    }
  };

  const handleUndoMultiple = async () => {
    try {
      const restorePromises = deletedEntries.map((entryId) => {
        return axiosInstance.post(`/workhour/restore/${entryId}`);
      });

      await Promise.all(restorePromises);

      successAlert("Selected entries restored successfully");

      // Restore entries in the UI
      setDeletedEntries([]);
      setMultipleDeleteSnackbarOpen(false);

      fetchWorkHours();
    } catch (error) {
      console.error("Error restoring selected entries:", error);
      errorAlert("Failed to restore selected entries.");
    }
  };

  const [newMatter, setNewMatter] = useState(""); //matter id
  const [newClient, setNewClient] = useState("");
  const [newSelectedMatterName, setNewSelectedMatterName] = useState("");

  const handleMatterSelected = async () => {
    try {
      // Step 1: Validate that there are selected entries
      if (selectedEntries.length === 0) {
        errorAlert("No entries selected for update.");
        return;
      }

      // Step 2: Validate that 'newMatter' is not empty
      if (!newMatter || newMatter.trim() === "") {
        errorAlert("Please provide a new matter code.");
        return;
      }

      // Step 3: Prepare the payload with 'newMatter' and 'reviewed: true'
      const payload = {
        entries: selectedEntries,
        newMatter,
        reviewed: true, // Mark entries as reviewed/approved
      };

      // Step 4: Send the update request to the API
      const response = await axiosInstance.post(
        `/updateMatterCodeEntries`,
        payload
      );

      // Step 5: Handle the API response
      if (response.status === 200) {
        // Clear the selected entries as they've been successfully updated
        setSelectedEntries([]);
        setNewMatter("");
        setNewSelectedMatterName("");
        setNewClient("");
        // Refresh the work hours data to reflect the updates
        fetchWorkHours();

        // Notify the user of the successful update
        successAlert(
          "Selected entries successfully updated with new matter and approved."
        );

        // Optional: Implement undo functionality here
        // For example, store previous state before updating and show a snackbar with undo option
      } else {
        // If the response status isn't 200, throw an error to be caught in the catch block
        throw new Error(`Update failed with status code ${response.status}`);
      }
    } catch (error) {
      // Log the error for debugging purposes
      console.error("Error updating entries:", error);

      // Notify the user of the error
      errorAlert("An error occurred while updating entries.");
    }
  };

  return (
    <>
      <ToastContainer />
      <Header />
      {/* Multiple Workhour  */}
      <Snackbar
        open={multipleDeleteSnackbarOpen}
        autoHideDuration={10000} // Hide after 10 seconds
        onClose={() => setMultipleDeleteSnackbarOpen(false)}
        message="Multiple entries deleted"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Change the position here
        action={
          <Button color="secondary" size="small" onClick={handleUndoMultiple}>
            UNDO
          </Button>
        }
      />
      {/* Snackbar for Undo Action */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={10000} // Hide after 10 seconds if no action is taken
        onClose={() => setSnackbarOpen(false)}
        message="Workhour deleted"
        action={
          <Button color="secondary" size="small" onClick={handleUndo}>
            UNDO
          </Button>
        }
      />

      <Grid
        container
        display="flex"
        justifyContent="center"
        paddingX={isSm ? 2 : 5}
        paddingY={2}
        md={12}
      >
        {/* Header with Heading, Add Button, and Middle Section Buttons */}
        <Grid
          container
          alignItems="center"
          justifyContent={isSm ? "center" : "space-between"}
          marginBottom={3}
          md={12}
          flexDirection="row"
          gap={2}
        >
          {/* Left Section: Heading and Add Button */}
          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            display="flex"
            justifyContent={isSm ? "flex-start" : "flex-start"}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Typography variant="h5">Time</Typography>
              <CustomTooltip text={isFormVisible ? "Close Form" : "Add Time"}>
                <CustomButton
                  style={{
                    borderRadius: "50%",
                    minWidth: "30px", // Minimum width
                    width: "40px", // Fixed width
                    height: "35px", // Fixed height
                    flexShrink: 0, // Prevents the button from stretching
                    transition:
                      "transform 0.3s ease-in-out, background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: COLORS.primary,
                      transform: "scale(1.1)",
                    },
                  }}
                  onClick={toggleFormVisibility}
                >
                  {isFormVisible ? <Remove /> : <Add />}
                </CustomButton>
              </CustomTooltip>
            </Box>
          </Grid>

          {/* Middle Section: Search Bar and Date Range Picker */}
          {(window.innerWidth > 480 || !isFormVisible) && (
            <Grid
              item
              xs={12}
              sm={7}
              md={5}
              display="flex"
              justifyContent={isSm ? "center" : "flex-start"}
            >
              <Grid container alignItems="center" flexDirection="row">
                <Grid item xs={8} sm={8} md={8}>
                  <Select
                    options={filteredMatters}
                    value={filteredMatters.find(
                      (option) => option.value === searchQuery
                    )}
                    onChange={(option) => setSearchQuery(option.value)}
                    isSearchable
                    placeholder="Select Matter"
                    styles={customStyles}
                  />

                  {/* <MatterDropdown
                  // selectedMatter={selectedMatter}
                  // setSelectedMatter={setSelectedMatter}
                  /> */}
                </Grid>

                {/* Date Range Picker */}
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  display="flex"
                  alignItems="center"
                  justifyContent={isSm ? "center" : "flex-end"}
                >
                  {!isShowingPending && (
                    <>
                      <CustomTooltip text="Select Dates">
                        <CustomDateRangePicker
                          startDate={selectedDateRange.start}
                          endDate={selectedDateRange.end}
                          onDateRangeChange={handleDateRangeChange}
                          iconSize={30}
                          iconColor={COLORS.primary}
                        />
                      </CustomTooltip>

                      <Typography>
                        {formatMonthDate(startSelectedDate)} -{" "}
                        {formatMonthDate(endSelectedDate)}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* Right Section: Buttons for Pending and Final Entries */}
          {(window.innerWidth > 480 || !isFormVisible) && (
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              display="flex"
              justifyContent={isSm ? "flex-start" : "flex-end"}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <CustomTooltip text="Entered Entries">
                  <CustomButton
                    variant={isShowingPending ? "outlined" : "contained"}
                    onClick={() => setIsShowingPending(false)}
                    style={{
                      minWidth: isSm ? "90px" : "120px",
                      padding: isSm ? "20px 12px" : "10px 20px",
                      fontSize: isSm ? "16px" : "14px",
                    }}
                  >
                    Entered
                  </CustomButton>
                </CustomTooltip>

                <CustomTooltip text="New AI entries are added daily by 10 PM">
                  <CustomButton
                    variant={!isShowingPending ? "outlined" : "contained"}
                    onClick={() => setIsShowingPending(true)}
                    style={{
                      minWidth: isSm ? "90px" : "130px",
                      padding: isSm ? "20px 12px" : "10px 20px",
                      fontSize: isSm ? "16px" : "14px",
                    }}
                  >
                    Maybe?{" "}
                    {pendingEntriesCount > 0 && (
                      <span
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "28px", // Fixed width
                          height: "28px", // Fixed height
                          borderRadius: "50%", // Maintain circle shape
                          backgroundColor: isShowingPending
                            ? "white"
                            : COLORS.primary,
                          color: isShowingPending ? COLORS.primary : "white",
                          fontWeight: "bold",
                          fontSize: isSm ? "12px" : "14px",
                          marginLeft: "10px",
                          animation: "bounce 2s infinite",
                        }}
                      >
                        {pendingEntriesCount}
                      </span>
                    )}
                  </CustomButton>
                </CustomTooltip>
              </Box>
            </Grid>
          )}
        </Grid>

        {/* Form Section */}
        {isFormVisible && (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            marginBottom={2}
            paddingRight={isSm ? 0 : 2}
          >
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              {/* Matter and Date  */}
              <Grid container spacing={2} marginBottom={1}>
                <Grid item xs={6} sm={6} md={6}>
                  <Controller
                    name="matter"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={filteredMatters}
                        value={filteredMatters.find(
                          (option) => option.value === field.value
                        )}
                        onChange={handleMatterChange}
                        isSearchable
                        placeholder="Select Matter"
                        className={` ${errors.matter ? "formInputError" : ""}`}
                        styles={{
                          ...customStyles, // Spread the global styles
                          control: (base, state) => ({
                            ...customStyles.control(base, state), // Apply global control styles
                            borderColor: errors.matter ? "red" : "#aaa", // Conditionally add error style
                            "&:hover": {
                              borderColor: errors.matter ? "red" : "#aaa", // Change hover color based on error
                            },
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: "black", // Force placeholder text color to black
                          }),
                        }}
                      />
                    )}
                  />
                  {errors.matter && (
                    <p className="errorMessage">{errors.matter.message}</p>
                  )}
                </Grid>

                <Grid item xs={6} sm={6} md={6}>
                  <input
                    type="date"
                    style={{
                      borderColor: "#aaa",
                      backgroundColor: "#fff",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                    className={`formInput ${
                      errors.selectedDate ? `formInputError` : ""
                    }`}
                    {...register("selectedDate", { required: true })}
                    min={
                      (matterOpenDate < lastSentTSDate
                        ? lastSentTSDate
                        : matterOpenDate) || ""
                    }
                  />
                  {errors.selectedDate && (
                    <p className="errorMessage">
                      {errors.selectedDate.message}
                    </p>
                  )}
                </Grid>
              </Grid>

              {/* Work Dropdowns  */}
              <Grid container columnSpacing={2} marginBottom={2}>
                {/* Work Hours DropDown */}
                <Grid item xs={6} sm={6} md={6}>
                  <select
                    style={{
                      fontSize: "12px",
                      borderColor: "#aaa",
                      backgroundColor: "#fff",
                    }}
                    className={`formInput ${
                      errors.workHour ? "formInputError" : ""
                    }`}
                    {...register("workHour", {
                      required: "Work Hour is required",
                    })}
                  >
                    <option value="">Select Work Hour *</option>
                    {/* Work Hour Options */}
                    {Array.from({ length: 19 }, (_, i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                  {errors.workHour && (
                    <p className="errorMessage">{errors.workHour.message}</p>
                  )}
                </Grid>

                {/* Work Minute Dropdown */}
                <Grid item xs={6} sm={6} md={6}>
                  <select
                    style={{
                      fontSize: "12px",
                      borderColor: "#aaa",
                      backgroundColor: "#fff",
                    }}
                    className="formInput"
                    {...register("workMin", {
                      required: "Work Minute is required",
                    })}
                  >
                    <option value="">Select Work Minutes *</option>
                    {/* Work Minute Options */}
                    {Array.from({ length: 10 }, (_, i) => (
                      <option key={i * 6} value={i * 6}>
                        {i * 6}
                      </option>
                    ))}
                  </select>
                  {errors.workMin && (
                    <p className="errorMessage">{errors.workMin.message}</p>
                  )}
                </Grid>
              </Grid>

              {/* Comments  */}
              <Grid container marginBottom={1} flexDirection="column">
                <textarea
                  placeholder="Comments for the reviewer (optional)"
                  style={{
                    fontSize: "12px",
                    height: "50px",
                    fontStyle: "italic",
                    borderColor: "#aaa",
                  }}
                  {...register("comments")}
                />
              </Grid>

              {/* Work description  */}
              <Grid container flexDirection="column">
                <textarea
                  className={`formInput ${errors.workDescription ? "formInputError" : ""} 
                  `}
                  style={{
                    height: "120px",
                    borderColor: "#aaa",
                  }}
                  {...register("workDescription")}
                  value={watch("workDescription")}
                  onChange={(e) => {
                    setValue("workDescription", e.target.value);
                    // setIsAiSuggested(false);
                  }}
                  placeholder="Work Description *"
                />
                {errors.workDescription && (
                  <p className="errorMessage">
                    {errors.workDescription.message}
                  </p>
                )}
              </Grid>

              {/* 4 buttons and AI description  */}
              {aiSuggestion && (
                <>
                  <Grid
                    container
                    flexDirection="row"
                    justifyContent="sm:space-between md:space-between"
                    alignItems="center"
                    marginBottom={1}
                  >
                    <Grid item xs={3} sm={3}>
                      <CustomTooltip
                        text={
                          editingEntry ? "Update Original" : "Submit Original"
                        }
                      >
                        {window.innerWidth <= 768 ? (
                          <CustomButton
                            onClick={handleSubmit(onSubmit)}
                            style={{
                              // color: "green",
                              // border: "none",
                              // background: "transparent",
                              marginTop: "6px",

                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Submit
                          </CustomButton>
                        ) : (
                          <IconButton
                            onClick={handleSubmit(onSubmit)}
                            style={{ color: "green" }}
                          >
                            <IoCheckmarkCircle style={{ fontSize: "25px" }} />
                          </IconButton>
                        )}
                      </CustomTooltip>
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      sm={3}
                      marginLeft={2}
                      display="sm:flex"
                      justifyContent="sm:flex-end"
                    >
                      <CustomTooltip text="Delete">
                        {window.innerWidth <= 768 ? (
                          <CustomButton
                            onClick={resetForm}
                            style={{
                              // color: "green",
                              // border: "none",
                              // background: "transparent",
                              marginTop: "6px",

                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Clear
                          </CustomButton>
                        ) : (
                          <IconButton onClick={resetForm}>
                            <RiDeleteBin6Line
                              style={{ fontSize: "20px", cursor: "pointer" }}
                            />
                          </IconButton>
                        )}
                      </CustomTooltip>
                    </Grid>
                  </Grid>

                  {/* AI suggested description  */}
                  <Grid>
                    <Typography variant="body2" style={{ fontSize: "16px" }}>
                      AI Suggested
                    </Typography>
                    <textarea
                      onLoadStart={isRetryingAi}
                      className="formInput"
                      style={{ height: "120px", backgroundColor: "#f0f0f0" }}
                      value={isRetryingAi ? "Revising again..." : aiSuggestion}
                      onChange={(e) => setAiSuggestion(e.target.value)}
                      disabled={isRetryingAi}
                    />
                  </Grid>

                  <Grid
                    container
                    flexDirection="row"
                    // justifyContent="center"
                    alignItems="center"
                    marginBottom={1}
                  >
                    <Grid item xs={6} sm={5}>
                      <CustomTooltip
                        text={
                          editingEntry ? "Update Revised" : "Submit Revised"
                        }
                      >
                        {window.innerWidth <= 768 ? (
                          <CustomButton
                            onClick={handleSubmit(onSubmitWithAiSuggestion)}
                            style={{
                              // color: "green",
                              // border: "none",
                              // background: "transparent",
                              marginTop: "6px",

                              fontSize: "16px",
                            }}
                          >
                            Submit Revised
                          </CustomButton>
                        ) : (
                          <IconButton
                            style={{
                              color: "green",
                              fontSize: "20px",
                              fontWeight: "bolder",
                            }}
                            onClick={handleSubmit(onSubmitWithAiSuggestion)}
                          >
                            <IoCheckmarkDoneSharp
                              style={{ fontSize: "22px", marginRight: "0px" }}
                            />
                            <HiSparkles size={20} color="black" />
                          </IconButton>
                        )}
                      </CustomTooltip>
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      <CustomTooltip text="Try again">
                        {window.innerWidth <= 768 ? (
                          <CustomButton
                            onClick={retryAiSuggestion}
                            style={{
                              // color: "green",
                              // border: "none",
                              // background: "transparent",
                              marginTop: "6px",
                              marginLeft: "20px",
                              fontSize: "16px",
                            }}
                          >
                            Redraft <HiSparkles size={"16px"} />
                          </CustomButton>
                        ) : (
                          <IconButton onClick={retryAiSuggestion}>
                            <TfiReload
                              style={{ fontSize: "18px", cursor: "pointer" }}
                            />
                          </IconButton>
                        )}
                      </CustomTooltip>
                    </Grid>
                  </Grid>
                </>
              )}

              {/* 3 final buttons  */}
              {!aiSuggestion && (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="sm:space-between md:space-between"
                >
                  <Grid item xs={4} sm={2}>
                    <CustomTooltip
                      text={
                        editingEntry
                          ? "Update"
                          : duplicatingEntry
                          ? "Duplicate"
                          : "Submit"
                      }
                    >
                      {window.innerWidth <= 768 ? ( // Conditional rendering based on screen size
                        <CustomButton
                          type="submit"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {editingEntry
                            ? "Update"
                            : duplicatingEntry
                            ? "Duplicate"
                            : "Submit"}
                        </CustomButton>
                      ) : (
                        <IconButton type="submit" style={{ color: "green" }}>
                          <IoCheckmarkCircle style={{ fontSize: "30px" }} />
                        </IconButton>
                      )}
                    </CustomTooltip>
                  </Grid>

                  <Grid item xs={4} sm={2}>
                    <CustomTooltip text="Redraft with AI">
                      {window.innerWidth <= 768 ? (
                        <CustomButton
                          onClick={handleSubmit(onSubmitWithAi)}
                          style={{
                            marginTop: "6px",
                            fontSize: "12px",
                          }}
                        >
                          {" "}
                          Redraft{" "}
                          <HiSparkles
                            style={{ marginLeft: "5px" }}
                            size={"16px"}
                          />
                        </CustomButton>
                      ) : (
                        <IconButton
                          onClick={handleSubmit(onSubmitWithAi)}
                          style={{ color: "green" }}
                        >
                          {/* <IoCheckmarkDoneSharp
                          style={{ fontSize: "25px", marginRight: "0px" }}
                        /> */}
                          <HiSparkles size={22} color="black" />
                        </IconButton>
                      )}
                    </CustomTooltip>
                  </Grid>

                  {window.innerWidth <= 768 ? (
                    ""
                  ) : (
                    <Grid
                      item
                      xs={3}
                      sm={8}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <CustomTooltip text="Reset">
                        <IconButton
                          onClick={handleSubmit(onSubmitWithAi)}
                          style={{ color: "black" }}
                        >
                          <RiDeleteBin6Line
                            style={{ fontSize: "22px", cursor: "pointer" }}
                            onClick={resetForm}
                          />
                        </IconButton>
                      </CustomTooltip>
                    </Grid>
                  )}
                </Grid>
              )}
            </Box>
          </Grid>
        )}

        {/* WorkHour Cards Section */}
        <Grid
          item
          xs={12}
          sm={12}
          md={isFormVisible ? 8 : 12}
          gap={1}
          sx={{
            maxHeight: "70vh",
            overflow: "hidden",
          }}
          marginTop={isSm ? 4 : 0}
          paddingBottom={isShowingPending ? 5 : 0}
        >
          {/* Pagination */}
          <Box>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={isShowingPending ? pendingEntriesCount : totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              //               sx={{ padding: isSm ? "0 8px" : "0 16px" }}
              sx={{
                padding: isSm ? "0 5px" : "0 16px",
                marginRight: isSm ? "0px" : "-10px",
                "& .MuiTablePagination-toolbar": {
                  fontSize: "16px", // Text size for the toolbar
                },
                "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows":
                  {
                    fontSize: isSm ? "12px" : "", // Text size for labels and displayed rows
                  },
                "& .MuiTablePagination-select": {
                  fontSize: isSm ? "12px" : "", // Text size for the dropdown (e.g., "25")
                  color: "black", // Match the text color of the rest of the style
                  fontWeight: "bold", // Optional: Bold text for emphasis
                  alignContent: "baseline",
                },
                "& .MuiTablePagination-select:hover": {
                  backgroundColor: "transparent", // Optional: Ensure no hover effect for dropdown
                },
                "& .MuiTablePagination-select:focus": {
                  outline: "none", // Remove focus outline if needed
                },
                "& .MuiTablePagination-actions button": {
                  fontSize: "16px", // Icon button size
                },
              }}
            />
          </Box>
          {/* Add the filter dropdown here */}
          {isShowingPending && (
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              marginBottom={1}
            >
              {/* "Select All" Checkbox */}
              <Grid item xs={12} sm={4} md={3}>
                <Grid container alignItems="center">
                  <Checkbox
                    checked={
                      filteredWorkHours.length > 0 &&
                      selectedEntries.length === filteredWorkHours.length
                    }
                    indeterminate={
                      selectedEntries.length > 0 &&
                      selectedEntries.length < filteredWorkHours.length
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        // Select all
                        setSelectedEntries(
                          filteredWorkHours.map((entry) => entry._id)
                        );
                      } else {
                        // Deselect all
                        setSelectedEntries([]);
                      }
                    }}
                    inputProps={{ "aria-label": "Select all entries" }}
                  />
                  <Typography variant="body2">Select All</Typography>
                </Grid>
              </Grid>

              {/* Delete Selected Button */}
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                container
                justifyContent="center"
              >
                {selectedEntries.length > 1 && (
                  <Grid container spacing={1} alignItems="baseline">
                    <Grid item xs={8} sm={8} md={8}>
                      <Select
                        options={filteredMatters}
                        value={filteredMatters.find(
                          (option) =>
                            option.label ===
                            `${newSelectedMatterName} | ${newClient}`
                        )}
                        onChange={(e) => {
                          setNewMatter(e.value);
                          setNewSelectedMatterName(e.label.split("|")[0]);
                          setNewClient(e.label.split("|")[1]);
                        }}
                        isSearchable
                        placeholder="Select New Matter"
                        styles={customStyles}
                      />
                    </Grid>

                    <Grid item xs={2} sm={2} md={2}>
                      <CustomTooltip text={`Move to ${newSelectedMatterName}`}>
                        <IconButton onClick={() => handleMatterSelected()}>
                          <DriveFileMoveOutlinedIcon
                            style={{ fontSize: "25px" }}
                          />
                        </IconButton>
                      </CustomTooltip>
                    </Grid>

                    <Grid item xs={2} sm={2} md={2}>
                      <CustomTooltip text="Delete all">
                        <IconButton onClick={() => handleDeleteSelected()}>
                          <RiDeleteBin6Line style={{ fontSize: "25px" }} />
                        </IconButton>
                      </CustomTooltip>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {/* Filter Dropdown */}
              <Grid item xs={12} sm={4} md={3}>
                <Select
                  options={[
                    { value: "All", label: "All Entries" },
                    { value: "Meeting", label: "Meeting Entries" },
                    { value: "Email", label: "Email Entries" },
                  ]}
                  value={{
                    value: filterOption,
                    label: `${filterOption} Entries`,
                  }}
                  onChange={(option) => setFilterOption(option.value)}
                  placeholder="Filter Entries"
                  styles={customStyles}
                />
              </Grid>
            </Grid>
          )}

          <Paper
            elevation={0}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: "90%",
            }}
          >
            {/* Scrollable Content */}
            <Box
              sx={{
                flex: 1,
                overflowY: "auto",
              }}
            >
              {filteredWorkHours.length === 0 ? (
                <NoDataFound message="Oops! No Workhours Found" />
              ) : (
                filteredWorkHours.map((time) => (
                  <Grid
                    container
                    alignItems="center"
                    key={time._id}
                    wrap="nowrap"
                  >
                    {/* Checkbox outside the card */}
                    {isShowingPending && (
                      <Grid item>
                        <Checkbox
                          checked={selectedEntries.includes(time._id)}
                          onChange={() => handleCheckboxChange(time._id)}
                        />
                      </Grid>
                    )}
                    {/* WorkHourCard */}
                    <Grid item xs>
                      <WorkHourCard
                        key={time._id}
                        time={time}
                        handleSave={handleSave}
                        isFormVisible={isFormVisible}
                        isShowingPending={isShowingPending}
                        handleApprove={handleApprove}
                        handleDuplicate={handleDuplicate}
                        handleEdit={handleEdit}
                        handleDelete={handleSoftDelete}
                        showApprove={true}
                        showDuplicate={true}
                        showEdit={true}
                        showDelete={true}
                        showLawyerName={false}
                        matters={filteredMatters}
                        isSelected={selectedEntries.includes(time._id)}
                        onCheckboxChange={handleCheckboxChange}
                      />
                    </Grid>
                  </Grid>
                ))
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {window.innerWidth <= 768 ? (
        <Grid item xs={12}>
          <Box sx={{ height: "70px" }}></Box> {/* Add 50px of space */}
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default AddTime;
