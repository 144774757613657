import React from "react";
import { Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "./CustomButton"; // Import your CustomButton component

const CustomModal = ({
  showModal,
  closeModal,
  title,
  children,
  onSubmit,
  submitting,
}) => {
  if (!showModal) return null;

  return (
    <div className="modal" style={styles.modal}>
      <div
        className="modal-content"
        style={styles.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div style={styles.modalHeader}>
          <Typography variant="h5" style={styles.modalTitle}>
            {title}
          </Typography>
          <IconButton onClick={closeModal} style={styles.closeButton}>
            <CloseIcon style={{ fontSize: "20px" }} />
          </IconButton>
        </div>
        <form className="flex flex-col gap-3" onSubmit={onSubmit}>
          {children}
          <div className="flex justify-center items-center align-middle gap-3">
            <CustomButton
              onClick={onSubmit}
              disabled={submitting}
              type="submit"
            >
              Submit
            </CustomButton>
            <CustomButton
              variant="outlined"
              style={{ width: "30%" }}
              onClick={closeModal}
            >
              Cancel
            </CustomButton>
          </div>
        </form>
      </div>
    </div>
  );
};

const styles = {
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
  },
  modalContent: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "20px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    width: "50%",
    maxWidth: "600px",
    maxHeight: "80vh", // Adjust to fit the screen
    overflowY: "auto", // Handle overflow content
    marginTop: "5vh",
    marginLeft: "auto",
    marginRight: "auto",
    gap: "20px",
    position: "relative", // For close button positioning
    boxSizing: "border-box", // Ensure padding doesn't affect size
  },
  modalHeader: {
    display: "flex",
    justifyContent: "center", // Center the title
    alignItems: "center",
    marginBottom: "15px",
    position: "relative", // Allows positioning child elements absolutely
  },
  modalTitle: {
    flexGrow: 1, // Allows the title to take all available space, centering it
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    top: "50%",
    right: "-10px", // Adjust this value as necessary to fine-tune the position
    transform: "translateY(-50%)", // Center the button vertically
  },
  "@media (max-width: 600px)": {
    modalContent: {
      width: "fit-content",
      maxWidth: "100%",
      margin: "5%",
    },
  },
};

export default CustomModal;
