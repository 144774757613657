import React, { useCallback, useEffect, useState } from "react";
import formatDate, {
  errorAlert,
  formatMonthDate,
  successAlert,
} from "../../utils/services";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Box,
  Typography,
  Card,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axiosInstance from "../../helpers/axios";
import Header from "../../components/ui/Header";
import { COLORS } from "../../constants/theme";
// import SkeletonRows from "../../components/ui/CardSkeletonLoader";
import CustomTooltip from "../../components/ui/CustomTootltip";
import CustomButton from "../../components/ui/CustomButton";
import NoDataFound from "../../components/NoDataFound";
import useResponsive from "../../constants/useResponsive";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";
import LeaveCard from "../../components/resource/LeaveCard";

const ResourceLeave = () => {
  const auth = useSelector((state) => state.auth);
  const { isSm } = useResponsive();

  //breakpoints
  const { isMd } = useResponsive();

  const [leaves, setLeaves] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [totalLeavesCount, setTotalLeavesCount] = useState(0);
  const [totalLeavesTaken, setTotalLeavesTaken] = useState(0);
  const [totalWorkFromHomeTaken, setTotalWorkFromHomeTaken] = useState(0);

  //modal states
  const [modalStartDate, setModalStartDate] = useState(formatDate(new Date()));
  const [modalEndDate, setModalEndDate] = useState(formatDate(new Date()));
  const [modalLeaveDescription, setModalLeaveDescription] = useState("");
  const [editingLeave, setEditingLeave] = useState(null);
  const [leaveType, setLeaveType] = useState("");

  //pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [loading, setLoading] = useState(false);

  //get leaves
  const fetchAllLeaves = useCallback(async () => {
    try {
      // setLoading(true);
      const response = await axiosInstance.get(`/leaves/my-leaves`, {
        params: {
          limit: rowsPerPage,
          page,
          needTotals: "true",
        },
      });
      setLeaves(response.data.leavesWithDays);
      setTotalLeavesCount(response.data.totalLeavesCount);
      setTotalLeavesTaken(response.data.totalLeavesTaken);
      setTotalWorkFromHomeTaken(response.data.totalWorkFromHomeTaken);

      // setLoading(false);
      // console.log("Resource Leaves data------", response.data.leavesWithDays);
    } catch (error) {
      // setLoading(false);

      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      // console.error("Error fetching leaves:", errorMessage);
      errorAlert(errorMessage);
      setLeaves([]);
    }
  }, [rowsPerPage, page]);

  useEffect(() => {
    fetchAllLeaves();
  }, [fetchAllLeaves, page, rowsPerPage]);

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Modal functions
  const openModal = (leave) => {
    if (leave) {
      setEditingLeave(leave);
      setModalStartDate(leave.leaveStartDate);
      setModalEndDate(leave.leaveEndDate);
      setModalLeaveDescription(leave.leaveDescription);
      //  console.log("leave data", leave, editingLeave)
    } else {
      setEditingLeave(null);
      setModalStartDate(formatDate(new Date()));
      setModalEndDate(formatDate(new Date()));
      setModalLeaveDescription("");
    }
    setShowModal(true);
  };

  //modal form validation
  const modalIsValidForm = () => {
    if (modalStartDate === "" || modalEndDate === "") {
      errorAlert("Start Date and End Date are required!!");
      return false;
    } else if (!(modalStartDate <= modalEndDate)) {
      errorAlert("Select Correct Dates!!");
      return false;
    } else {
      return true;
    }
  };

  // console.log(auth.user.fullName);

  //add and update leave function
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (modalIsValidForm()) {
      const formData = {
        admin: auth.user.company,
        user: auth.user._id,
        fullName: auth.user.fullName,
        leaveStartDate: modalStartDate,
        leaveEndDate: modalEndDate,
        leaveDescription: modalLeaveDescription,
        leaveType: leaveType,
        leaveStatus: "pending",
        paidLeave: "yes",
      };

      //  console.log(formData);

      try {
        if (editingLeave) {
          // Update existing leave
          const response = await axiosInstance.put(
            `/leaves/${editingLeave._id}`,
            formData
          );
          if (response.data) {
            successAlert(response.data.message);
            setShowModal(false);
            fetchAllLeaves();
          }
        } else {
          // Add new leave
          const response = await axiosInstance.post(`/leaves`, formData);
          if (response.data) {
            successAlert(response.data.message);
            setShowModal(false);
            fetchAllLeaves();
          }
        }
      } catch (error) {
        errorAlert(
          error.response.data.message ||
            (editingLeave
              ? "Error updating leave!! Try again..."
              : "Error creating leave!! Try again...")
        );
      }
    }
  };

  const userJson = localStorage.getItem("user");
  const user = JSON.parse(userJson);
  // console.log(user.totalLeaves);

  // states and functions for date range picker
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  // Function to receive selected dates from DateRangePickerTst
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setModalStartDate(startDate);
    setModalEndDate(endDate);
    // console.log(startDate, endDate);
  };

  return (
    <>
      <Header />
      <ToastContainer />
      {/* Main Heading  */}
      <Grid
        item
        sm={6}
        md={6}
        lg={6}
        gap={1}
        display="flex"
        alignItems="center"
        paddingX={5}
        marginTop={2}
      >
        <Typography variant="h5">Leaves</Typography>
        <CustomTooltip text={"Add Leave"}>
          <CustomButton
            style={{
              borderRadius: "50%",
              minWidth: "30px", // Minimum width
              width: "40px", // Fixed width
              height: "35px", // Fixed height
              flexShrink: 0, // Prevents the button from stretching
              transition:
                "transform 0.3s ease-in-out, background-color 0.3s ease",
              "&:hover": {
                backgroundColor: COLORS.primary,
                transform: "scale(1.1)",
              },
            }}
            onClick={() => openModal(null)}
          >
            <AddIcon />
          </CustomButton>
        </CustomTooltip>
      </Grid>

      {/* Add / Edit Modal  */}
      {showModal && (
        <div className="modal">
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <Typography variant="h5" align="center">
              {editingLeave ? "Edit Leave" : "Add Leave"}
            </Typography>

            <form onSubmit={handleSubmit} className="flex flex-col gap-3">
              <Grid
                container
                spacing={2}
                className="flex justify-center align-middle"
              >
                <Grid item sm={12} md={8} xs={12}>
                  <select
                    style={{
                      width: "100%",
                      backgroundColor: "white", // Setting background color to white
                    }}
                    value={leaveType}
                    name="leaveType"
                    onChange={(event) => setLeaveType(event.target.value)}
                  >
                    <option value="">Select Leave Type</option>
                    <option value="leave">Leave</option>
                    <option value="workFromHome">Work From Home</option>
                  </select>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  <CustomTooltip text="Select Dates">
                    <CustomDateRangePicker
                      startDate={selectedDateRange.start}
                      endDate={selectedDateRange.end}
                      onDateRangeChange={handleDateRangeChange}
                      iconSize={30}
                      iconColor={COLORS.primary}
                    />
                  </CustomTooltip>
                  <Typography>
                    {formatMonthDate(modalStartDate)} -{" "}
                    {formatMonthDate(modalEndDate)}
                  </Typography>
                </Grid>
              </Grid>

              <textarea
                style={{ width: "100%", padding: "5px" }}
                type="text"
                placeholder="Leave description"
                value={modalLeaveDescription}
                onChange={(e) => setModalLeaveDescription(e.target.value)}
              />

              <Box
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <CustomButton type="submit">Submit</CustomButton>
                <CustomButton
                  variant="outlined"
                  style={{ width: "20%" }}
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </CustomButton>
              </Box>
            </form>
          </div>
        </div>
      )}

      {/* Total Table  */}
      <Grid
        container
        spacing={2}
        justifyContent="center"
        padding={isMd ? 2 : 5}
      >
        <Grid item xs={12} md={6}>
          <LeaveCard
            title="Leaves"
            assigned={user.totalLeaves}
            used={totalLeavesTaken}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LeaveCard
            title="Work From Home"
            assigned={user.totalWorkFromHome}
            used={totalWorkFromHomeTaken}
          />
        </Grid>
      </Grid>

      {/* Leave Data Table  */}
      <Grid container paddingX={isMd ? 2 : 5} paddingY={2}>
        <Paper
          elevation={0}
          sx={{
            width: "100%",
            overflowX: "auto",
            borderRadius: 2,
            border: "1px solid #e0e0e0",
          }}
        >
          <Table>
            {/* Table Head */}
            <TableHead sx={{ backgroundColor: "#f9f9f9" }}>
              <TableRow>
                <TableCell>
                  <strong>Date</strong>
                </TableCell>
                <TableCell>
                  <strong>Leave Type</strong>
                </TableCell>
                {!isSm && (
                  <TableCell>
                    <strong>Days</strong>
                  </TableCell>
                )}
                <TableCell>
                  <strong>Status</strong>
                </TableCell>
                {!isSm && (
                  <TableCell>
                    <strong>Description</strong>
                  </TableCell>
                )}
                {!isSm && (
                  <TableCell>
                    <strong>Paid Leave</strong>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {leaves.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={isSm ? 3 : 6} align="center">
                    <Typography variant="body2" color="text.secondary">
                      Oops! No Leaves Found.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                leaves.map((leave) => (
                  <TableRow key={leave._id} hover>
                    <TableCell>
                      {leave.leaveStartDate === leave.leaveEndDate
                        ? formatMonthDate(leave.leaveStartDate)
                        : `${formatMonthDate(
                            leave.leaveStartDate
                          )} - ${formatMonthDate(leave.leaveEndDate)}`}
                    </TableCell>

                    <TableCell>
                      {leave.leaveType === "workFromHome"
                        ? "Work From Home"
                        : leave.leaveType === "quarterDay"
                        ? "Quarter Day"
                        : leave.leaveType === "halfDay"
                        ? "Half Day"
                        : "Leave"}
                    </TableCell>

                    {!isSm && <TableCell>{leave.leaveDays}</TableCell>}

                    <TableCell
                      sx={{
                        color:
                          leave.leaveStatus === "approved"
                            ? "green"
                            : leave.leaveStatus === "pending"
                            ? "orange"
                            : "red",
                        fontWeight: 500,
                      }}
                    >
                      {leave.leaveStatus.charAt(0).toUpperCase() +
                        leave.leaveStatus.slice(1)}
                    </TableCell>

                    {!isSm && (
                      <TableCell>{leave.leaveDescription || "N/A"}</TableCell>
                    )}

                    {!isSm && (
                      <TableCell>
                        {leave.paidLeave === "yes" ? "Paid" : "Not Paid"}
                      </TableCell>
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>

          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalLeavesCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-toolbar": {
                fontSize: "10px",
              },
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-select":
                {
                  fontSize: "10px",
                },
            }}
          />
        </Paper>
      </Grid>

      {window.innerWidth <= 768 ? (
        <Grid item xs={12}>
          <Box sx={{ height: "100px" }}></Box>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default ResourceLeave;
