import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import theme from "./constants/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import NoInternetConnection from "./components/NoInternetConnection";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <NoInternetConnection>
      <App />
    </NoInternetConnection>
  </ThemeProvider>
  // </React.StrictMode>
);
