import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import formatDate, {
  customStyles,
  errorAlert,
  formatDatePrevious,
  formatDateToYYYYMMDD,
  getNumericAmount,
  successAlert,
} from "../../utils/services";
import Select from "react-select";
import "./EditMatter.css";
import CustomButton from "../../components/ui/CustomButton";
import { Download, Close, Add } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import { COLORS } from "../../constants/theme";
import { Typography } from "@mui/material";
import CustomModal from "../../components/ui/CustomModal";
import { fetchCurrencySettings } from "../../actions/currencyActions";
import { fetchDesignations } from "../../store/slices/globalSlice";
import { fetchEmployees } from "../../store/slices/employeesSlice";
import { getAllClients } from "../../actions";

const Modal = ({ isOpen, onClose, entryData }) => {
  const dispatch = useDispatch();

  // Global states
  const auth = useSelector((state) => state.auth);
  const adminClient = useSelector((state) => state.adminClient);
  const allCurrencyData = useSelector((state) => state.currency);
  const { billedDesignations } = useSelector((state) => state.global);
  const { employees, filteredEmployees } = useSelector(
    (state) => state.employee
  );
  const { clientList } = adminClient;

  console.log("entryData", entryData);

  // Fetch global data
  useEffect(() => {
    dispatch(fetchDesignations());
    dispatch(fetchEmployees());
    dispatch(getAllClients());
  }, [dispatch]);

  // Local currency state
  const [currencyData, setCurrencyData] = useState({
    nativeCurrencyName: "",
    nativeCurrencyCode: "",
    conversionRates: [],
  });

  // If currency settings are not loaded, fetch them
  useEffect(() => {
    if (!allCurrencyData.nativeCurrencyName) {
      dispatch(fetchCurrencySettings());
    }
  }, [dispatch, allCurrencyData.nativeCurrencyName]);

  // Keep local currency in sync
  useEffect(() => {
    setCurrencyData(allCurrencyData);
  }, [allCurrencyData]);

  // Client dropdown options
  const clientNames = clientList.map((client) => ({
    value: client._id,
    label: client.name,
  }));

  // Pricing for designations
  const [designationPrices, setDesignationPrices] = useState({});

  // New resource input states
  const [newResource, setNewResource] = useState("");
  const [newResourcePrice, setNewResourcePrice] = useState("");

  // For toggling pop-up to shift between pricePerHour <-> resourceSpecific
  const [showPriceInput, setShowPriceInput] = useState(false);
  const [shiftMode, setShiftMode] = useState("");
  const [customPrice, setCustomPrice] = useState("");

  // ---------------------------------------
  // MULTIPLE ADVANCES
  // ---------------------------------------
  const [advanceInvoices, setAdvanceInvoices] = useState([]);
  const [newAdvanceAmount, setNewAdvanceAmount] = useState("");
  const [newAdvanceNote, setNewAdvanceNote] = useState("");
  // Default date to today's date. Convert it to the same format as stored in DB.
  const [newAdvanceDate, setNewAdvanceDate] = useState(formatDate(new Date()));

  console.log("advanceInvoices", advanceInvoices);

  // Engagement Letter
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);

  // Main form data (excluding arrays like advanceInvoices, resourceSpecificPrice)
  const [formData, setFormData] = useState({
    admin: auth?.user?._id,
    entryId: "",
    clientName: "",
    matterName: "",
    currency: "",
    email: "",
    resourceSpecificPrice: [],
    pricePerHour: "",
    status: "",
    disableDate: "",
    matterOpenDate: "",
    matterAddress: "",
    engagementLetter: [],
    flatFeesAmount: "",
  });

  // Setup initial form state from entryData
  useEffect(() => {
    // Replace single advanceAmount usage if your server sends an array for adv. invoices
    // If entryData has an `advanceInvoices` array, load that:
    setAdvanceInvoices(entryData.advanceInvoices || []);

    setFormData({
      admin: auth?.user?._id,
      entryId: entryData.id || "",
      clientName: entryData.client || "",
      matterName: entryData.matter || "",
      currency: entryData.currency || "",
      email: entryData.email || "",
      pricePerHour: typeof entryData.price === "string" ? entryData.price : "",
      resourceSpecificPrice:
        Array.isArray(entryData.price) && entryData.price.length > 0
          ? entryData.price
          : [],
      matterAddress: entryData.matterAddress || "",
      status: entryData.status || "",
      disableDate: formatDateToYYYYMMDD(entryData.disableDate) || "",
      matterOpenDate: formatDateToYYYYMMDD(entryData.matterOpenDate) || "",
      engagementLetter: entryData.engagementLetter || [],
      flatFeesAmount: entryData.flatFeesAmount || 0,
      // If we still want to store a single "advanceAmount" field for backward-compat:
      // advanceAmount: entryData.advanceAmount || 0,
    });

    if (entryData.engagementLetter?.length > 0) {
      setSelectedFile(entryData.engagementLetter);
    }

    dispatch(fetchEmployees());
  }, [entryData, dispatch, auth?.user?._id]);

  // Build list of currencies
  const currencies = [
    currencyData.nativeCurrencyCode,
    ...(currencyData.conversionRates || []).map((c) => c.currencyCode),
  ];

  // Handle text changes (for main form fields)
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If user sets disableDate => status is 'disable'
    // If user toggles status => might clear disableDate
    let updatedFormData = { ...formData, [name]: value };

    if (name === "disableDate") {
      updatedFormData.status = value ? "disable" : "enable";
    }
    if (name === "status" && value === "enable") {
      updatedFormData.disableDate = "";
    }

    setFormData(updatedFormData);
  };

  // Resource-specific price update
  const handleEmployeePriceChange = (e, index) => {
    let { value } = e.target;
    value = value.trim(); // remove leading/trailing spaces

    if (!isNaN(value)) {
      const updatedArr = [...formData.resourceSpecificPrice];
      const [employeeName] = updatedArr[index].split(":");
      updatedArr[index] = `${employeeName.trim()}: ${value}`;
      setFormData({ ...formData, resourceSpecificPrice: updatedArr });
    } else {
      errorAlert("Please enter a valid number for the price.");
    }
  };

  // Add new resource to resourceSpecificPrice
  const handleAddNewResource = () => {
    if (newResource && newResourcePrice) {
      const newEntry = `${newResource.trim()}: ${newResourcePrice}`;
      setFormData((prev) => ({
        ...prev,
        resourceSpecificPrice: [...prev.resourceSpecificPrice, newEntry],
      }));
      setNewResource("");
      setNewResourcePrice("");
    } else {
      errorAlert("Please enter both resource name and price.");
    }
  };

  // Remove resource from resourceSpecificPrice
  const handleRemoveResource = (index) => {
    const updated = formData.resourceSpecificPrice.filter(
      (_, i) => i !== index
    );
    setFormData({ ...formData, resourceSpecificPrice: updated });
  };

  // SHIFT from price/hour -> resourceSpecific
  const handleShiftToResourceSpecific = () => {
    if (formData.pricePerHour) {
      setShiftMode("hourToResource");
      setShowPriceInput(true);
    } else {
      errorAlert("No price per hour to shift.");
    }
  };

  // SHIFT from resourceSpecific -> price/hour
  const handleShiftToPricePerHour = () => {
    if (formData.resourceSpecificPrice.length > 0) {
      setShiftMode("resourceToHour");
      setShowPriceInput(true);
    } else {
      errorAlert("No resource-specific prices to shift.");
    }
  };

  // DESIGNATION approach
  const handleDesignationPriceChange = (designation, price) => {
    setDesignationPrices((prev) => ({ ...prev, [designation]: price }));
  };

  // Apply the designation-based pricing
  const applyDesignationPrices = () => {
    const newResourcePrices = employees
      .filter(
        (emp) =>
          billedDesignations.includes(emp.designation) &&
          emp.status === "active"
      )
      .map((emp) => {
        const price = designationPrices[emp.designation] || 0;
        return `${emp.fullName}: ${price}`;
      });

    setFormData((prev) => ({
      ...prev,
      resourceSpecificPrice: newResourcePrices,
      pricePerHour: "", // Clear single price
    }));

    setShowPriceInput(false);
    successAlert("Price applied based on designations.");
  };

  // SHIFT from resource-specific -> single price/hour
  const applyCustomPriceToPricePerHour = () => {
    if (isNaN(customPrice) || customPrice <= 0) {
      errorAlert("Please enter a valid price.");
      return;
    }

    setFormData((prev) => ({
      ...prev,
      pricePerHour: customPrice,
      resourceSpecificPrice: [],
    }));

    setCustomPrice("");
    setShowPriceInput(false);
    successAlert("Price successfully shifted to price per hour.");
  };

  // ---------------------------------------
  // MULTIPLE ADVANCES: Handlers
  // ---------------------------------------
  const handleAddAdvance = () => {
    if (!newAdvanceAmount) {
      errorAlert("Advance amount is required.");
      return;
    }
    const newItem = {
      amount: +newAdvanceAmount,
      note: newAdvanceNote,
      date: newAdvanceDate,
    };

    console.log("newItem", newItem);
    setAdvanceInvoices((prev) => [...prev, newItem]);

    // Clear the inputs
    setNewAdvanceAmount("");
    setNewAdvanceNote("");
    setNewAdvanceDate(formatDate(new Date()));
  };

  const removeAdvanceItem = (idx) => {
    setAdvanceInvoices((prev) => prev.filter((_, i) => i !== idx));
  };

  // ---------------------------------------
  // File Upload
  // ---------------------------------------
  const handleFileUpload = async (e) => {
    if (e.target.files.length > 0) {
      const files = e.target.files;
      const uploadPromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("adminId", auth.user._id);

        uploadPromises.push(axiosInstance.post(`/uploadMatterEL`, formData));
      }

      try {
        setUploading(true);
        const uploadResponses = await Promise.all(uploadPromises);
        const uploadData = await Promise.all(
          uploadResponses.map((res) => res.data)
        );

        const uploadedFileUrls = uploadData.map((data) => data.url);
        setSelectedFile((prev) => [...prev, ...uploadedFileUrls]);

        e.target.value = "";
      } catch (error) {
        console.error(error);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleRemoveFile = async (index) => {
    const fileToRemove = selectedFile[index];
    try {
      // Example: call a delete endpoint if needed
      // await axiosInstance.delete("/deleteEngagementLetter", { filePath: fileToRemove });
      const newSelectedFile = selectedFile.filter((_, i) => i !== index);
      setSelectedFile(newSelectedFile);
    } catch (error) {
      console.error("Error deleting file:", error);
      alert("Failed to delete file. Please try again.");
    }
  };

  // ---------------------------------------
  // Submit
  // ---------------------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();

    // If matter is disabled, ensure disableDate is set
    if (formData.status === "disable" && !formData.disableDate) {
      errorAlert("Please enter a disable date before disabling the matter.");
      return;
    }

    // Filter resourceSpecificPrice for empties
    const filteredRSP = formData.resourceSpecificPrice.filter((price) => {
      const [, val] = price.split(":");
      return val.trim() !== "";
    });

    // Build final form
    const payload = {
      admin: auth?.user?._id,
      entryId: formData.entryId,
      clientName: formData.clientName,
      matterName: formData.matterName,
      currency: formData.currency,
      email: formData.email,
      resourceSpecificPrice: filteredRSP,
      pricePerHour: formData.pricePerHour,
      status: formData.status,
      disableDate: formData.disableDate,
      matterOpenDate: formData.matterOpenDate,
      engagementLetter: selectedFile,
      matterAddress: formData.matterAddress,
      flatFeesAmount: formData.flatFeesAmount || 0,

      // MULTIPLE ADVANCES
      advanceInvoices: advanceInvoices, // Our new array

      // If still needed, keep single 'advanceAmount' for backward-compat:
      // advanceAmount: formData.advanceAmount,
    };

    try {
      const response = await axiosInstance.put(
        `/matter/updateMatter/${formData.entryId}`,
        payload
      );
      if (response.data) {
        successAlert("Matter updated successfully");
      }
      onClose();
    } catch (error) {
      console.error("Error updating matter details:", error);
      errorAlert("Error updating matter details, try again...");
    }
  };

  // ---------------------------------------
  // RENDER
  // ---------------------------------------
  return (
    <CustomModal
      showModal={isOpen}
      closeModal={onClose}
      title="Edit Matter"
      onSubmit={handleSubmit}
    >
      {/* CLIENT, MATTER, CURRENCY */}
      <div
        style={{ display: "flex", gap: "1rem", width: "100%", marginTop: 15 }}
      >
        <div style={{ width: "60%" }}>
          <label>
            <p>
              <b>Client</b>
            </p>
          </label>
          <Select
            name="clientName"
            options={clientNames}
            value={clientNames.find((opt) => opt.label === formData.clientName)}
            onChange={(selectedOption) =>
              setFormData({ ...formData, clientName: selectedOption.label })
            }
            isSearchable
            placeholder="Search Client"
            styles={customStyles}
          />
        </div>
        <div style={{ width: "60%" }}>
          <label>
            <p>
              <b>Matter</b>
            </p>
          </label>
          <input
            type="text"
            name="matterName"
            value={formData.matterName}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ width: "40%" }}>
          <label>
            <b>Currency</b>
          </label>
          <select
            name="currency"
            value={formData.currency}
            onChange={handleInputChange}
            style={{ width: "100%" }}
          >
            {currencies.map((curr) => (
              <option key={curr} value={curr}>
                {curr}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* PRICING */}
      <div
        style={{ marginTop: "1rem", border: "1px solid #ccc", padding: "10px" }}
      >
        <label style={{ fontSize: "14px", marginBottom: "10px" }}>
          Pricing
        </label>
        {/* If no resource-specific prices, show price/hour + shift button */}

        {!formData.resourceSpecificPrice.length > 0 && (
          <div>
            <label>
              <b>Price (Blended Rate/Hour)</b>
            </label>
            <input
              type="text"
              name="pricePerHour"
              value={formData.pricePerHour}
              onChange={handleInputChange}
            />
            <CustomButton
              type="button"
              onClick={handleShiftToResourceSpecific}
              style={{ margin: "10px" }}
            >
              Shift to Resource Specific
            </CustomButton>
          </div>
        )}

        {/* If resource-specific is present, show the resource list + shift back to single rate */}
        {formData.resourceSpecificPrice.length > 0 && (
          <>
            {/* Add new resource UI */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                width: "100%",
                gap: "20px",
              }}
            >
              <div style={{ width: "40%" }}>
                <Select
                  options={filteredEmployees.map((emp) => ({
                    value: emp.label,
                    label: emp.label,
                  }))}
                  value={
                    newResource
                      ? { label: newResource, value: newResource }
                      : null
                  }
                  onChange={(sel) => setNewResource(sel.value)}
                  placeholder="Select resource"
                  isSearchable
                  styles={customStyles}
                />
              </div>
              <div style={{ width: "40%" }}>
                <input
                  type="number"
                  value={newResourcePrice}
                  onChange={(e) => setNewResourcePrice(e.target.value)}
                  placeholder="Enter resource price"
                  onWheel={(e) => e.target.blur()}
                />
              </div>
              <div style={{ width: "20%" }}>
                <Add
                  style={{ fontSize: "20px", color: "green" }}
                  type="button"
                  onClick={handleAddNewResource}
                />
              </div>
            </div>

            {/* Show existing resource-specific items */}
            {formData.resourceSpecificPrice.map((item, idx) => {
              const [empName, empRate] = item.split(":");
              return (
                <div
                  key={idx}
                  style={{
                    marginTop: "10px",
                    gap: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "40%" }}>
                    <Typography variant="body2">{empName.trim()}</Typography>
                  </div>
                  <div style={{ width: "40%" }}>
                    <input
                      type="text"
                      value={empRate.trim()}
                      onChange={(e) => handleEmployeePriceChange(e, idx)}
                      style={{ textAlign: "end" }}
                    />
                  </div>
                  <div style={{ width: "20%" }}>
                    <Close
                      style={{
                        cursor: "pointer",
                        color: "red",
                        fontSize: "20px",
                      }}
                      onClick={() => handleRemoveResource(idx)}
                    />
                  </div>
                </div>
              );
            })}

            <CustomButton
              type="button"
              variant="outlined"
              onClick={handleShiftToPricePerHour}
              style={{
                marginTop: "25px",
                width: "fit-content",
              }}
            >
              Shift to Blended Rate / Hour
            </CustomButton>
          </>
        )}
      </div>

      {/* SHIFTING POP-UP (Blended -> Resource or Resource -> Blended) */}
      {showPriceInput && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            zIndex: 1000,
            minWidth: "300px",
          }}
        >
          {shiftMode === "hourToResource" ? (
            <>
              <h3>Enter Prices for Designations</h3>
              {billedDesignations.map((designation) => (
                <div key={designation} style={{ marginBottom: "10px" }}>
                  <label>{designation}:</label>
                  <input
                    type="number"
                    value={designationPrices[designation] || ""}
                    onChange={(e) =>
                      handleDesignationPriceChange(designation, e.target.value)
                    }
                    placeholder="Enter price"
                    style={{ width: "100%" }}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              ))}
              <CustomButton
                type="button"
                onClick={applyDesignationPrices}
                style={{ marginRight: "10px" }}
              >
                Apply to Resource Specific
              </CustomButton>
            </>
          ) : (
            <>
              <h3>Enter a Single Blended Rate</h3>
              <input
                type="number"
                value={customPrice}
                onChange={(e) => setCustomPrice(e.target.value)}
                placeholder="Blended Rate/Hour"
                style={{ width: "100%", marginBottom: "10px" }}
                onWheel={(e) => e.target.blur()}
              />
              <CustomButton
                type="button"
                onClick={applyCustomPriceToPricePerHour}
                style={{ marginRight: "10px" }}
              >
                Apply Blended Rate
              </CustomButton>
            </>
          )}
          <CustomButton
            type="button"
            variant="outlined"
            onClick={() => setShowPriceInput(false)}
            style={{ marginTop: "10px" }}
          >
            Cancel
          </CustomButton>
        </div>
      )}

      {/* MATTER EMAIL, FLAT FEES, ADVANCES */}
      <div
        style={{ display: "flex", gap: "20px", width: "100%", marginTop: 15 }}
      >
        <div style={{ width: "40%" }}>
          <label>
            <p>
              <b>Matter Email</b>
            </p>
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ width: "30%" }}>
          <label>
            <b>Fixed Fees ({formData.currency})</b>
          </label>
          <input
            type="number"
            name="flatFeesAmount"
            value={formData.flatFeesAmount || 0}
            onChange={handleInputChange}
            onWheel={(e) => e.target.blur()}
          />
        </div>
        {/* If you decide to remove single 'advanceAmount', remove this block */}
        {/* <div style={{ width: "30%" }}>
          <label><b>Advance ({formData.currency})</b></label>
          <input
            type="number"
            name="advanceAmount"
            value={formData.advanceAmount || 0}
            onChange={handleInputChange}
            onWheel={(e) => e.target.blur()}
          />
        </div> */}
      </div>

      {/* MULTIPLE ADVANCES UI */}
      <div
        style={{ marginTop: "1rem", border: "1px solid #ccc", padding: "10px" }}
      >
        <label style={{ fontSize: "14px", marginBottom: "10px" }}>
          Advance(s)
        </label>
        <div style={{ display: "flex", gap: "10px" }}>
          <input
            type="number"
            placeholder={`Amount (${formData.currency})`}
            value={newAdvanceAmount}
            onChange={(e) => setNewAdvanceAmount(e.target.value)}
            onWheel={(e) => e.target.blur()}
          />
          <input
            type="date"
            value={newAdvanceDate}
            onChange={(e) => setNewAdvanceDate(e.target.value)}
          />
          <input
            type="text"
            placeholder="Note (optional)"
            value={newAdvanceNote}
            onChange={(e) => setNewAdvanceNote(e.target.value)}
          />
          {/* <CustomButton type="button" onClick={handleAddAdvance}>
            Add
          </CustomButton> */}

          {/* <div style={{ width: "33%" }}> */}
          <Add
            style={{ fontSize: "20px", color: "green" }}
            type="button"
            onClick={handleAddAdvance}
          />
          {/* </div> */}
        </div>

        {/* Show the current list of advances */}
        {advanceInvoices.map((adv, idx) => {
          const amountValue = getNumericAmount(adv);

          // const amountValue = parseFloat(adv.amount.$numberDecimal);
          console.log("amountValue", amountValue);
          return (
            <div
              key={idx}
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // backgroundColor: "blue",
              }}
            >
              <Typography variant="body2" key={idx}>
                {amountValue} ({formData.currency})
              </Typography>
              <Typography variant="body2" key={idx}>
                {formatDatePrevious(adv.date)}
              </Typography>
              <Typography variant="body2" key={idx}>
                {adv.note}
              </Typography>
              <Close
                style={{ cursor: "pointer", color: "red" }}
                onClick={() => removeAdvanceItem(idx)}
              />
            </div>
          );
        })}
      </div>

      {/* MATTER OPEN, DISABLED, STATUS */}
      <div
        style={{
          width: "100%",
          display: "flex",
          gap: "20px",
          marginTop: "1rem",
        }}
      >
        <div style={{ width: "40%" }}>
          <label>
            <b>Matter Opened on</b>
          </label>
          <input
            type="date"
            name="matterOpenDate"
            value={formData.matterOpenDate}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ width: "30%" }}>
          <label>
            <b>Disabled on</b>
          </label>
          <input
            type="date"
            name="disableDate"
            value={formData.disableDate}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ width: "30%" }}>
          <label>
            <b>Status</b>
          </label>
          <select
            name="status"
            value={formData.status}
            onChange={handleInputChange}
            style={{ width: "100%" }}
          >
            <option value="enable">Enable</option>
            <option value="disable">Disable</option>
          </select>
        </div>
      </div>

      {/* MATTER ADDRESS */}
      <div style={{ marginTop: "1rem" }}>
        <label>
          <b>Matter Address</b>
        </label>
        <textarea
          name="matterAddress"
          value={formData.matterAddress}
          onChange={handleInputChange}
          style={{ width: "100%", height: "60px" }}
        />
      </div>

      {/* ENGAGEMENT LETTER */}
      <div style={{ marginTop: "1rem" }}>
        <label>
          <b>Engagement Letter</b>
        </label>
        <input
          type="file"
          accept=".pdf,.doc,.docx"
          onChange={handleFileUpload}
          disabled={uploading}
        />
        {selectedFile.map((file, index) => (
          <div
            key={index}
            style={{ marginTop: "10px", display: "flex", gap: "10px" }}
          >
            <CustomButton variant="outlined">
              <Download style={{ marginRight: "8px" }} />
              <a
                href={file}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: COLORS.primary }}
              >
                {`Engagement Letter #${index + 1}`}
              </a>
            </CustomButton>
            <CustomButton type="button" onClick={() => handleRemoveFile(index)}>
              Remove
            </CustomButton>
          </div>
        ))}
      </div>
    </CustomModal>
  );
};

export default Modal;
