import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
  Typography, 
  Grid, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TextField,
  TableRow,
  TablePagination,
  Card,
  CardContent,
  Box,
  Container, 
  Stack,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  LinearProgress,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { 
  AccessTime as AccessTimeIcon, 
  Timeline as TimelineIcon,
  BarChart as BarChartIcon,
  Add as AddIcon,
  Edit as EditIcon, 
  Bolt as BoltIcon
} from '@mui/icons-material';
import axiosInstance from "../../helpers/axios.js";
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  Cell,
  PieChart, 
  Pie 
} from 'recharts';
import { 
  format, 
  parseISO, 
  subWeeks, 
  subMonths, 
  startOfDay, 
  endOfDay 
} from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import useResponsive from '../../constants/useResponsive';
import Header from '../../components/ui/Header';
import { RxDotFilled } from 'react-icons/rx';

const COLORS = [
  '#3366CC', // Deep Blue
  '#DC3912', // Bright Red
  '#FF9900', // Vibrant Orange
  '#109618', // Green
  '#990099', // Purple
  '#0099C6', // Cyan
  '#DD4477', // Pink
  '#66AA00'  // Lime Green
];

const DEFAULT_APPS = [
  'VS Code', 
  'Chrome', 
  'Slack', 
  'Zoom', 
  'Microsoft Teams', 
  'Figma', 
  'Other'
];

const TimeTrackerComponent = () => {
  const [trackingData, setTrackingData] = useState(null);
  
  // Separate states for different views
  const [hourlyPeriod, setHourlyPeriod] = useState('today');
  const [selectedTableDate, setSelectedTableDate] = useState(startOfDay(new Date()));
  
  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Modal state
  const [isAdvancedFilterModalOpen, setIsAdvancedFilterModalOpen] = useState(false);
  const [isAddEntryModalOpen, setIsAddEntryModalOpen] = useState(false);
  const [newEntry, setNewEntry] = useState({
    app: '',
    title: '',
    startTime: new Date(),
    duration: 0
  });

  const timer = useRef(null);
  const { isMd } = useResponsive();

  // Centralized error handling for API calls
  const handleApiError = (error, defaultMessage = 'An error occurred') => {
    console.error(error);
    toast.error(
      error.response?.data?.message || 
      error.message || 
      defaultMessage
    );
  };

  const getUserId = () => {
    const userString = localStorage.getItem('user');
    try {
      const userObject = userString ? JSON.parse(userString) : null;
      return userObject?._id || '';
    } catch (error) {
      console.error('Error parsing user from localStorage:', error);
      return '';
    }
  };

  const userId = getUserId();

  const fetchTrackingData = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/tracking-data/user/${userId}`);
      
      if (response.data) {
        setTrackingData(response.data);
      }
    } catch (error) {
      handleApiError(error, 'Failed to fetch tracking data');
    }
  }, [userId]);

  useEffect(() => {
    timer.current = setInterval(() => {}, 1000);
    return () => clearInterval(timer.current);
  }, []);

  useEffect(() => {
    if (userId) {
      fetchTrackingData();
    }
  }, [userId, fetchTrackingData]);

  const parseDuration = (durationStr) => {
    if (!durationStr) return 0;
    
    // Match minutes and seconds pattern
    const minutesMatch = durationStr.match(/(\d+)m\s*(\d+)s/);
    if (minutesMatch) {
      const minutes = parseInt(minutesMatch[1], 10);
      const seconds = parseInt(minutesMatch[2], 10);
      return minutes + (seconds / 60);
    }
    
    // Match just seconds pattern
    const secondsMatch = durationStr.match(/(\d+)s/);
    if (secondsMatch) {
      const seconds = parseInt(secondsMatch[1], 10);
      return seconds / 60;
    }
    
    // Fallback to parsing as float
    const numericValue = parseFloat(durationStr);
    return isNaN(numericValue) ? 0 : numericValue;
  };

  const processChartData = (allRecords, period, selectedDate) => {
    if (!allRecords || !Array.isArray(allRecords)) return [];

    // Flatten records from all days
    const records = allRecords.flatMap(dayData => dayData.records || []);

    switch (period) {
      case 'today': {
        // Group by hour for today's data
        const hourlyData = {};
        records.forEach(record => {
          const recordTime = parseISO(record.startTime);
          const hour = recordTime.getHours();
          const intervalStart = `${hour.toString().padStart(2, '0')}:00`;
          const intervalEnd = `${(hour + 1).toString().padStart(2, '0')}:00`;
          const intervalKey = `${intervalStart}-${intervalEnd}`;
          
          const duration = parseDuration(record.duration);
          
          hourlyData[intervalKey] = (hourlyData[intervalKey] || 0) + duration;
        });

        return Object.entries(hourlyData)
          .map(([interval, duration]) => ({ interval, duration }))
          .sort((a, b) => a.interval.localeCompare(b.interval));
      }
      case 'week': {
        // Group by day of the week
        const daysOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dailyData = {};
        
        records.forEach(record => {
          const recordDate = parseISO(record.startTime);
          const day = daysOrder[recordDate.getDay()];
          const duration = parseDuration(record.duration);
          
          dailyData[day] = (dailyData[day] || 0) + duration;
        });

        return daysOrder
          .filter(day => dailyData[day])
          .map(day => ({ day, duration: dailyData[day] }));
      }
      case 'month': {
        // Group by week of the month
        const weeklyData = {};
        
        records.forEach(record => {
          const recordDate = parseISO(record.startTime);
          const weekNumber = Math.ceil((recordDate.getDate() + (new Date(recordDate.getFullYear(), recordDate.getMonth(), 1).getDay())) / 7);
          const weekLabel = `Week ${weekNumber}`;
          const duration = parseDuration(record.duration);
          
          weeklyData[weekLabel] = (weeklyData[weekLabel] || 0) + duration;
        });

        return Object.entries(weeklyData)
          .map(([week, duration]) => ({ week, duration }))
          .sort((a, b) => {
            const weekNumA = parseInt(a.week.split(' ')[1]);
            const weekNumB = parseInt(b.week.split(' ')[1]);
            return weekNumA - weekNumB;
          });
      }
      case 'year': {
        // Group by month for the current year
        const currentYear = new Date().getFullYear();
        const monthsOrder = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        const monthlyData = {};
        
        records.forEach(record => {
          const recordDate = parseISO(record.startTime);
          
          // Only include records from the current year
          if (recordDate.getFullYear() === currentYear) {
            const month = monthsOrder[recordDate.getMonth()];
            const monthKey = `${month} ${currentYear}`;
            const duration = parseDuration(record.duration);
            
            monthlyData[monthKey] = (monthlyData[monthKey] || 0) + duration;
          }
        });

        return Object.entries(monthlyData)
          .map(([month, duration]) => ({ month, duration }))
          .filter(entry => entry.duration > 0)
          .sort((a, b) => {
            const [monthA] = a.month.split(' ');
            const [monthB] = b.month.split(' ');
            const monthOrderA = monthsOrder.indexOf(monthA);
            const monthOrderB = monthsOrder.indexOf(monthB);
            return monthOrderA - monthOrderB;
          });
      }
      case 'all': {
        // Group by month across all available data
        const monthsOrder = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        const monthlyData = {};
        
        records.forEach(record => {
          const recordDate = parseISO(record.startTime);
          const month = monthsOrder[recordDate.getMonth()];
          const year = recordDate.getFullYear();
          const monthKey = `${month} ${year}`;
          const duration = parseDuration(record.duration);
          
          monthlyData[monthKey] = (monthlyData[monthKey] || 0) + duration;
        });

        return Object.entries(monthlyData)
          .map(([month, duration]) => ({ month, duration }))
          .filter(entry => entry.duration > 0)
          .sort((a, b) => {
            const [monthA, yearA] = a.month.split(' ');
            const [monthB, yearB] = b.month.split(' ');
            const monthOrderA = monthsOrder.indexOf(monthA);
            const monthOrderB = monthsOrder.indexOf(monthB);
            return parseInt(yearA) - parseInt(yearB) || monthOrderA - monthOrderB;
          });
      }
      default:
        return [];
    }
  };

  const processAppUsageData = (allRecords) => {
    if (!allRecords || !Array.isArray(allRecords)) return [];
    
    // Flatten records from all days
    const records = allRecords.flatMap(dayData => dayData.records || []);
    
    const appData = {};
    
    records.forEach(record => {
      const appName = record.app || 'Unknown App';
      const duration = parseDuration(record.duration);
      
      appData[appName] = (appData[appName] || 0) + duration;
    });

    return Object.entries(appData)
      .map(([app, duration]) => ({ app, duration }))
      .filter(item => item.duration > 0)
      .sort((a, b) => b.duration - a.duration);
  };

  const calculateTotalTrackingTime = (allRecords) => {
    if (!allRecords || !Array.isArray(allRecords)) return 0;
    
    // Flatten records from all days
    const records = allRecords.flatMap(dayData => dayData.records || []);
    
    return records.reduce((total, record) => {
      const duration = parseDuration(record.duration);
      return total + duration;
    }, 0);
  };

  const renderChartContent = (chartData, period) => {
    switch (period) {
      case 'today':
        return (
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="interval" 
              label={{ value: 'Hour Intervals', position: 'insideBottom', offset: -5 }} 
            />
            <YAxis label={{ value: 'Minutes', angle: -90, position: 'insideLeft' }} />
            <Tooltip formatter={(value) => [`${value.toFixed(2)} mins`, 'Duration']} />
            <Bar dataKey="duration" fill="#8884d8" barSize={30} />
          </BarChart>
        );
      case 'week':
        return (
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="day" 
              label={{ value: 'Days of Week', position: 'insideBottom', offset: -5 }} 
            />
            <YAxis label={{ value: 'Minutes', angle: -90, position: 'insideLeft' }} />
            <Tooltip formatter={(value) => [`${value.toFixed(2)} mins`, 'Duration']} />
            <Bar dataKey="duration" fill="#82ca9d" barSize={30} />
          </BarChart>
        );
      case 'month':
        return (
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="week" 
              label={{ value: 'Weeks of Month', position: 'insideBottom', offset: -5 }} 
            />
            <YAxis label={{ value: 'Minutes', angle: -90, position: 'insideLeft' }} />
            <Tooltip formatter={(value) => [`${value.toFixed(2)} mins`, 'Duration']} />
            <Bar dataKey="duration" fill="#ffc658" barSize={30} />
          </BarChart>
        );
        case 'year':
          return (
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="month" 
                label={{ value: 'Months (Current Year)', position: 'insideBottom', offset: -5 }} 
              />
              <YAxis label={{ value: 'Minutes', angle: -90, position: 'insideLeft' }} />
              <Tooltip formatter={(value) => [`${value.toFixed(2)} mins`, 'Duration']} />
              <Bar dataKey="duration" fill="#8884d8" barSize={30} />
            </BarChart>
          );
      case 'all':
        return (
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="month" 
              label={{ value: 'Months', position: 'insideBottom', offset: -5 }} 
            />
            <YAxis label={{ value: 'Minutes', angle: -90, position: 'insideLeft' }} />
            <Tooltip formatter={(value) => [`${value.toFixed(2)} mins`, 'Duration']} />
            <Bar dataKey="duration" fill="#ff7300" barSize={30} />
          </BarChart>
        );
      default:
        return null;
    }
  };

  const handleEditRecord = (record) => {
    // Open edit modal or navigate to edit page
    console.log('Editing record:', record);
    toast.info('Edit functionality coming soon!');
  };

  const handleDeleteRecord = (recordId) => {
    try {
      // Simulated record deletion
      const updatedData = trackingData.data.filter(record => record._id !== recordId);
      setTrackingData(prev => ({
        ...prev,
        data: updatedData,
        metadata: {
          ...prev.metadata,
          totalEntries: updatedData.length
        }
      }));
      toast.success('Record deleted successfully');
    } catch (error) {
      toast.error('Failed to delete record');
    }
  };

  const handleAddNewEntry = () => {
    setIsAddEntryModalOpen(true);
  };

  const handleSaveNewEntry = async () => {
    try {
      // Simulated API call to save new entry
      await axiosInstance.post('/tracking/entries', newEntry);
      
      // Update local tracking data
      setTrackingData(prev => ({
        ...prev,
        data: [...(prev.data || []), { records: [newEntry] }],
        metadata: {
          ...prev.metadata,
          totalEntries: (prev.metadata?.totalEntries || 0) + 1
        }
      }));

      // Close modal and reset form
      setIsAddEntryModalOpen(false);
      setNewEntry({
        app: '',
        title: '',
        startTime: new Date(),
        duration: 0
      });

      toast.success('New tracking entry added successfully');
    } catch (error) {
      console.error('Failed to add new entry:', error);
      toast.error('Could not add new tracking entry');
    }
  };

  // StatusDot component for status indicators
  const StatusDot = ({ color = 'primary', size = 'small' }) => (
    <RxDotFilled 
      color={color} 
      style={{ 
        marginRight: 8, 
        fontSize: size === 'small' ? '0.75rem' : '1rem' 
      }} 
    />
  );

  // Modal for adding new tracking entry
  const renderAddEntryModal = () => (
    <Dialog 
      open={isAddEntryModalOpen} 
      onClose={() => setIsAddEntryModalOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Add New Tracking Entry</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Application</InputLabel>
              <Select
                value={newEntry.app}
                label="Application"
                onChange={(e) => setNewEntry(prev => ({
                  ...prev, 
                  app: e.target.value
                }))}
              >
                {DEFAULT_APPS.map((app) => (
                  <MenuItem key={app} value={app}>
                    <StatusDot />
                    {app}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Entry Title"
              value={newEntry.title}
              onChange={(e) => setNewEntry(prev => ({
                ...prev, 
                title: e.target.value
              }))}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="datetime-local"
              label="Start Time"
              value={format(newEntry.startTime, "yyyy-MM-dd'T'HH:mm")}
              onChange={(e) => setNewEntry(prev => ({
                ...prev, 
                startTime: new Date(e.target.value)
              }))}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              type="number"
              label="Duration (minutes)"
              value={newEntry.duration}
              onChange={(e) => setNewEntry(prev => ({
                ...prev, 
                duration: Number(e.target.value)
              }))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsAddEntryModalOpen(false)}>
          Cancel
        </Button>
        <Button 
          color="primary" 
          variant="contained"
          onClick={handleSaveNewEntry}
          disabled={!newEntry.app || !newEntry.title}
        >
          Save Entry
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Advanced filter modal using Dialog components
  const renderAdvancedFilterModal = () => (
    <Dialog 
      open={isAdvancedFilterModalOpen} 
      onClose={() => setIsAdvancedFilterModalOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Advanced Tracking Filters</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Filter by Application</Typography>
            <Select
              multiple
              fullWidth
              value={[]}
              renderValue={(selected) => selected.join(', ')}
            >
              {DEFAULT_APPS.map((app) => (
                <MenuItem key={app} value={app}>
                  <Checkbox checked={false} />
                  <ListItemText primary={app} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Date Range</Typography>
            <TextField
              type="date"
              fullWidth
              label="Start Date"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              type="date"
              fullWidth
              label="End Date"
              InputLabelProps={{ shrink: true }}
              sx={{ mt: 2 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsAdvancedFilterModalOpen(false)}>
          Cancel
        </Button>
        <Button 
          color="primary" 
          variant="contained"
          onClick={() => {
            // Apply filters logic
            setIsAdvancedFilterModalOpen(false);
          }}
        >
          Apply Filters
        </Button>
      </DialogActions>
    </Dialog>
  );

  
const renderProgressTracking = () => {
  const dailyGoal = 4 * 60; // 4 hours in minutes
  const today = new Date().toDateString();
  let totalTrackedTime = 0;

  // Calculate tracked time for today
  (trackingData?.data || []).forEach(dayData => {
    const day = new Date(dayData.date).toDateString();
    if (day === today) {
      totalTrackedTime = dayData.records.reduce((total, record) => {
        return total + parseDuration(record.duration);
      }, 0);
    }
  });

  const progressPercentage = Math.min((totalTrackedTime / dailyGoal) * 100, 100);

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Today's Productivity Progress
        </Typography>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Today
          </Typography>
          <LinearProgress 
            variant="determinate" 
            value={progressPercentage} 
            color={progressPercentage > 100 ? 'error' : 'primary'}
          />
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {totalTrackedTime.toFixed(2)} / {dailyGoal} mins
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
  

  const renderTrackingTable = () => {
    // Flatten records from all days
    const allRecords = trackingData?.data?.flatMap(dayData => dayData.records || []) || [];
    
    // Filter records based on period and selected date
    const filteredRecords = allRecords.filter(record => {
      const recordDate = parseISO(record.startTime);
      
      switch (hourlyPeriod) {
        case 'today':
          return (
            recordDate >= startOfDay(selectedTableDate) && 
            recordDate <= endOfDay(selectedTableDate)
          );
        case 'week':
          return (
            recordDate >= startOfDay(subWeeks(selectedTableDate, 1)) && 
            recordDate <= endOfDay(selectedTableDate)
          );
        case 'month':
          return (
            recordDate >= startOfDay(subMonths(selectedTableDate, 1)) && 
            recordDate <= endOfDay(selectedTableDate)
          );
        case 'all':
          return true;
        default:
          return false;
      }
    });
    
    return (
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Box display="flex" alignItems="center" mb={2}>
            <BarChartIcon sx={{ mr: 2, color: 'primary.main'}} />
            <Typography variant="h5" color="primary">
              Tracking Details
            </Typography>
            
            <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
              <FormControl variant="outlined" size="small" sx={{ minWidth: 120, mr: 2 }}>
                <InputLabel>Period</InputLabel>
                <Select
                  value={hourlyPeriod}
                  onChange={(e) => setHourlyPeriod(e.target.value)}
                  label="Period"
                >
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="week">Last Week</MenuItem>
                  <MenuItem value="month">Last Month</MenuItem>
                  <MenuItem value="all">All Time</MenuItem>
                </Select>
              </FormControl>

              {hourlyPeriod !== 'all' && (
                <TextField
                  label="Select Date"
                  type="date"
                  value={format(selectedTableDate, 'yyyy-MM-dd')}
                  onChange={(e) => {
                    try {
                      setSelectedTableDate(startOfDay(new Date(e.target.value)));
                    } catch (error) {
                      console.error('Invalid date selection', error);
                      toast.error('Invalid date selected');
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  sx={{ minWidth: 150 }}
                />
              )}
            </Box>
          </Box>
          
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>App</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Duration</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRecords
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((record, index) => (
                    <TableRow key={index}>
                      <TableCell>{record.app}</TableCell>
                      <TableCell>{record.title}</TableCell>
                      <TableCell>
                        {format(parseISO(record.startTime), 'dd MMM yyyy')}
                      </TableCell>
                      <TableCell>{record.duration}</TableCell>
                      <TableCell align="left" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton
                          color="primary"
                          onClick={() => handleEditRecord(record)}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteRecord(record._id)}
                        >
                            <AddIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRecords.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(parseInt(e.target.value, 10));
              setPage(0);
            }}
          />
        </CardContent>
      </Card>
    );
  };

  const renderTrackingSummary = () => {
    const metadata = trackingData?.metadata || {};
    const totalTrackingTime = calculateTotalTrackingTime(trackingData?.data || []);

    return (
      <Card sx={{ mb: 3, boxShadow: 3 }}>
        <CardContent>
          <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <TimelineIcon sx={{ mr: 2, color: 'primary.main' }} />
              <Typography variant="h5" color="primary">
                Tracking Summary
              </Typography>
            </Box>
          </Stack>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1" color="text.secondary">
                Total Entries
              </Typography>
              <Typography variant="h6">
                {metadata.totalEntries || 0}
              </Typography>
            </Grid>
            
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1" color="text.secondary">
                Total Tracking Time
              </Typography>
              <Typography variant="h6">
                {totalTrackingTime.toFixed(2)} minutes
              </Typography>
            </Grid>
            
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1" color="text.secondary">
                Average Hourly Usage
              </Typography>
              <Typography variant="h6">
                {(totalTrackingTime / 24).toFixed(2)} minutes
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const renderHourlyUsageChart = () => {
    // Use the entire tracking data for processing
    const chartData = processChartData(
      trackingData?.data || [], 
      hourlyPeriod, 
      selectedTableDate
    );

    return (
      <Card sx={{ height: 400 }}>
        <CardContent>
          <Box display="flex" alignItems="center" mb={2} sx={{ gap: 2 }}>
            <BarChartIcon sx={{ mr: 2, color: 'primary.main' }} />
            <Typography variant="h5" color="primary">
              Usage Chart
            </Typography>
            <FormControl sx={{ width: '25ch' }}>
              <InputLabel id="select-period-label">Period</InputLabel>
              <Select
                labelId="select-period-label"
                id="select-period"
                value={hourlyPeriod}
                label="Period"
                onChange={(e) => setHourlyPeriod(e.target.value)}
              >
                <MenuItem value="all">Monthly (All Time)</MenuItem>
                <MenuItem value="year">Yearly (Current Year)</MenuItem>
                <MenuItem value="month">Weekly (Last Month)</MenuItem>
                <MenuItem value="week">Daily (Last Week)</MenuItem>
                <MenuItem value="today">Hourly (Today)</MenuItem>
              </Select>
            </FormControl>
          </Box>
          
          <ResponsiveContainer width="100%" height={300}>
            {chartData.length > 0 
              ? renderChartContent(chartData, hourlyPeriod)
              : (
                <Box 
                  display="flex" 
                  justifyContent="center" 
                  alignItems="center" 
                  height="100%"
                >
                  <Typography variant="body2" color="text.secondary">
                    No data available for the selected period
                  </Typography>
                </Box>
              )
            }
          </ResponsiveContainer>
        </CardContent>
      </Card>
    );
  };

  const renderAppUsageChart = () => {
    // Use the entire tracking data for processing
    const chartData = processAppUsageData(trackingData?.data || []);

    return (
      <Card sx={{ height: 450, boxShadow: 3 }}>
        <CardContent>
          <Box display="flex" alignItems="center" mb={2} sx={{ gap: 2 }}>
            <AccessTimeIcon sx={{ mr: 2, color: 'primary.main' }} />
            <Typography variant="h5" color="primary" sx={{ flexGrow: 1 }}>
              App Usage Distribution
            </Typography>
          </Box>
          
          <ResponsiveContainer width="100%" height={350}>
            {chartData.length > 0 ? (
              <PieChart>
                <Pie 
                  data={chartData} 
                  dataKey="duration" 
                  nameKey="app" 
                  innerRadius="40%" 
                  outerRadius="70%" 
                  paddingAngle={3}
                  cornerRadius={10}
                  startAngle={90}
                  endAngle={-270}
                  label={({ name, percent }) => {
                    const percentValue = (percent * 100).toFixed(0);
                    return `${percentValue}%`;
                  }}
                  labelStyle={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    fill: 'white',
                    textShadow: '1px 1px 2px rgba(0,0,0,0.5)'
                  }}
                  labelLine={false}
                >
                  {chartData.map((entry, index) => (
                    <Cell 
                      key={`cell-${index}`} 
                      fill={COLORS[index % COLORS.length]} 
                      stroke="#fff" 
                      strokeWidth={2}
                      style={{
                        filter: 'drop-shadow(0 4px 6px rgba(0,0,0,0.2))',
                        transition: 'all 0.3s ease'
                      }}
                    />
                  ))}
                </Pie>
                <Tooltip 
                  formatter={(value, name) => [`${value.toFixed(2)} mins`, name]}
                  contentStyle={{ 
                    backgroundColor: '#f5f5f5', 
                    borderRadius: '10px',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                    border: 'none'
                  }}
                  itemStyle={{
                    padding: '5px 10px',
                    textTransform: 'capitalize'
                  }}
                />
                <Legend 
                  layout="vertical" 
                  verticalAlign="middle" 
                  align="right"
                  iconType="circle"
                  iconSize={10}
                  formatter={(value) => value.charAt(0).toUpperCase() + value.slice(1)}
                />
              </PieChart>
            ) : (
              <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                height="100%"
              >
                <Typography variant="body2" color="text.secondary">
                  No app usage data available
                </Typography>
              </Box>
            )}
          </ResponsiveContainer>
        </CardContent>
      </Card>
    );
  };

  const AIProductivityInsights = () => {
    const [aiInsights, setAIInsights] = useState(null);

    useEffect(() => {
      const fetchAIInsights = async () => {
        try {
          const insights = await generateAIInsights();
          setAIInsights(insights);
        } catch (error) {
          console.error('Failed to fetch AI insights:', error);
          toast.error('Could not load AI insights');
        }
      };
      fetchAIInsights();
    }, []); 

    if (!aiInsights) {
      return null;
    }

    return (
      <Card>
        <CardContent>
          <Box display="flex" alignItems="center" mb={2}>
            <BoltIcon sx={{ mr: 2, color: 'primary.main' }} />
            <Typography variant="h6" color="primary">
              AI Productivity Insights
            </Typography>
          </Box>
          <List>
            {aiInsights.recommendations.map((rec, index) => (
              <ListItem key={index} disableGutters>
                <ListItemIcon>
                  <BoltIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText 
                  primary={rec} 
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    );
  };

  const renderAIProductivityInsights = () => {
    return <AIProductivityInsights />;
  };

  const generateAIInsights = async () => {
    try {
      const response = await axiosInstance.post('/ai/productivity-insights', {
        userId,
        trackingData,
        goals: {
          dailyGoal: 4, 
          weeklyGoal: 20, 
          appTimeLimit: {
            'VS Code': 3, 
            'Chrome': 2 
          }
        }
      });

      return {
        recommendations: response.data.recommendations,
        potentialImprovements: response.data.potentialImprovements,
        burnoutRisk: response.data.burnoutRisk
      };
    } catch (error) {
      console.error('Failed to generate AI insights', error);
      return null;
    }
  };

  // Render method with Container and additional components
  return (
    <Container maxWidth="lg">
      <Header />
      <ToastContainer />
      
      {/* Responsive layout using isMd */}
      <Box sx={{ 
        display: isMd ? 'flex' : 'block',
        flexDirection: isMd ? 'row' : 'column',
        gap: 2
      }}>
        {renderProgressTracking()}
      </Box>

      {/* Existing tracking components */}
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          {renderTrackingSummary()}
        </Grid>
        
        <Grid item xs={12} md={8}>
          {renderHourlyUsageChart()}
        </Grid>
        
        <Grid item xs={12} md={4}>
          {renderAppUsageChart()}
        </Grid>
        
        <Grid item xs={12}>
          {renderTrackingTable()}
        </Grid>
      </Grid>
    </Container>
  );
};

export default TimeTrackerComponent;
