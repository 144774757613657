import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RxDotFilled } from "react-icons/rx";
import formatDate, {
  errorAlert,
  successAlert,
  customStyles,
  addOneDay,
} from "../../utils/services.js";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  TableFooter,
  TablePagination,
} from "@mui/material";
import Select from "react-select";
import "./Calendar.css";
import axiosInstance from "../../helpers/axios.js";
import CustomButton from "../../components/ui/CustomButton.js";
import NoDataFound from "../../components/NoDataFound.js";
import Header from "../../components/ui/Header.js";
import { CiEdit } from "react-icons/ci";
import { Add } from "@mui/icons-material";
import { RiDeleteBin6Line } from "react-icons/ri";
import { COLORS } from "../../constants/theme.js";
import useResponsive from "../../constants/useResponsive.js";
import InfoPopup from "../../components/ui/CalendarInfoPopup.js";
import CustomTooltip from "../../components/ui/CustomTootltip.js";
import InfoIcon from "@mui/icons-material/Info";
import { PlusCircleFilled } from "@ant-design/icons";
import { Check } from "@mui/icons-material";
import { HiSparkles } from "react-icons/hi2";
import { TfiReload } from "react-icons/tfi";
import {
  fetchDesignations,
  fetchHolidays,
} from "../../store/slices/globalSlice.js";
import { fetchAssignedMatters } from "../../store/slices/assignedMatterSlice.js";

const getMonthStartEndDates = (date) => {
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return { startDate, endDate };
};

const getWeekendDates = (startDate, endDate) => {
  const weekendDates = [];
  const currentDate = new Date(startDate);
  const formatDateInReverse = (date) => {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  while (currentDate <= endDate) {
    const day = currentDate.getDay();
    if (day === 0 || day === 6) {
      weekendDates.push(formatDateInReverse(currentDate));
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return weekendDates;
};

const calculateTotals = (searchResult) => {
  let total = 0;
  let totalBillable = 0;

  const matterGroups = {};

  searchResult.forEach((item) => {
    const workHour = parseFloat(item.workHour) || 0;
    total += workHour;

    if (item.billable === true) {
      totalBillable += workHour;
    }

    const matterName = item.matter?.name || "Uncategorized";
    if (!matterGroups[matterName]) {
      matterGroups[matterName] = {
        entries: [],
        total: 0,
      };
    }
    matterGroups[matterName].entries.push(item);
    matterGroups[matterName].total += workHour;
  });

  return {
    total: parseFloat(total).toFixed(2),
    totalBillable: parseFloat(totalBillable).toFixed(2),
    matterGroups,
  };
};

const MyCalendar = () => {
  const dispatch = useDispatch();
  const { isSm, isXs } = useResponsive();
  const auth = useSelector((state) => state.auth);
  const { holidays } = useSelector((state) => state.global);
  // const { filteredMatters } = useSelector((state) => state.matter);
  const { filteredMatters } = useSelector((state) => state.assignedMatters);

  useEffect(() => {
    // dispatch(fetchAllMatters());
    dispatch(fetchAssignedMatters());
  }, [dispatch]);

  //fetch holidays and designations
  useEffect(() => {
    dispatch(fetchHolidays());
    dispatch(fetchDesignations());
  }, [dispatch]);

  // states
  const [searchResult, setSearchResult] = useState([]);
  const isxl = useMediaQuery("(min-width:1024px)");
  const [selectedDate, setSelectedDate] = useState("");
  const [leaves, setLeaves] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userWorkHour, setUserWorkHour] = useState([]);
  const [editingTime, setEditingTime] = useState(null);
  const [date, setDate] = useState(new Date());
  const [modalMatter, setModalMatter] = useState("");
  const [modalClient, setModalClient] = useState("");
  const [modalSelectedMatterName, setModalSelectedMatterName] = useState("");
  const [workDescription, setWorkDescription] = useState("");
  const [comments, setComments] = useState("");
  const [workHour, setWorkHour] = useState("0");
  const [workMin, setWorkMin] = useState("30");
  const [entryId, setEntryId] = useState("");
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [lastSentTSDate, setLastSentTSDate] = useState(null);
  const [matterOpenDate, setMatterOpenDate] = useState(null);
  const [isShowingPending, setIsShowingPending] = useState(false);
  const [timeTrackingData, setTimeTrackingData] = useState([]);
  const [isLoadingTimeTracking, setIsLoadingTimeTracking] = useState(false);
  const [isShowingTimeTracking, setIsShowingTimeTracking] = useState(false);

  const totalWorkHour = useMemo(
    () => userWorkHour.filter((entry) => entry.reviewed === true),
    [userWorkHour]
  );

  const { startDate, endDate } = useMemo(
    () => getMonthStartEndDates(activeStartDate),
    [activeStartDate]
  );

  const formattedStartDate = useMemo(() => formatDate(startDate), [startDate]);
  const formattedEndDate = useMemo(() => formatDate(endDate), [endDate]);

  const weekendDates = useMemo(
    () => getWeekendDates(startDate, endDate),
    [startDate, endDate]
  );

  const fetchData = useCallback(async () => {
    try {
      const workHoursResponse = await axiosInstance.get(
        `/workHour/getWorkHourByUserId`,
        {
          params: {
            fromDate: formattedStartDate,
            toDate: formattedEndDate,
            limit: 0,
            page: 0,
          },
        }
      );

      const leavesResponse = await axiosInstance.get(`/leaves/my-leaves`, {
        params: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          limit: 0,
          page: 0,
          leaveType: "leave",
          needTotals: "false",
        },
      });

      // console.log("leavesResponse", leavesResponse);

      // No need to filter here since we're fetching based on 'reviewed' status
      setUserWorkHour(workHoursResponse.data.workHours);
      setLeaves(leavesResponse.data.leavesWithDays);
    } catch (error) {
      errorAlert(error.response.data.error);
    }
  }, [formattedStartDate, formattedEndDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!selectedDate) {
      setSearchResult([]);
      return;
    }

    const filteredEntries = userWorkHour.filter(
      (entry) => entry.workDate === selectedDate
    );

    const searchedData = filteredEntries.filter((entry) => {
      if (isShowingPending) {
        return entry.reviewed === false;
      } else {
        return entry.reviewed === true || entry.reviewed === undefined;
      }
    });

    setSearchResult(searchedData);
  }, [userWorkHour, selectedDate, isShowingPending]);

  useEffect(() => {
    const today = formatDate(new Date());
    setSelectedDate(today);
  }, []);

  const { total, totalBillable, matterGroups } = useMemo(() => {
    return calculateTotals(searchResult, selectedDate);
  }, [searchResult, selectedDate]);

  const todayLeaves = leaves.filter(
    (leave) =>
      leave.leaveStartDate <= selectedDate && selectedDate <= leave.leaveEndDate
  );

  const handleDateClick = (date) => {
    const formattedDate = formatDate(date);
    setSelectedDate(formattedDate);
  };

  const getMarkedDates = (appointments, leaves, holidays, weekendDates) => {
    const markedDates = {};
    const totalWorkHoursPerDate = appointments.reduce((result, entry) => {
      const { workDate, workHour } = entry;
      const parsedWorkHour = parseFloat(workHour);
      if (parsedWorkHour >= 0) {
        if (result[workDate]) {
          result[workDate] += parsedWorkHour;
        } else {
          result[workDate] = parsedWorkHour;
        }
      }
      return result;
    }, {});

    Object.keys(totalWorkHoursPerDate).forEach((date) => {
      let formattedDate;
      try {
        formattedDate = formatDate(date);
      } catch (error) {
        console.error("date error", error);
        return;
      }

      const workHours = totalWorkHoursPerDate[date].toFixed(2);
      let dotColor;
      if (workHours >= 9) {
        dotColor = "green";
      } else if (workHours >= 3.5) {
        dotColor = "orange";
      } else if (workHours > 0) {
        dotColor = "red";
      }

      markedDates[formattedDate] = { marked: true, dotColor };
    });

    weekendDates.forEach((weekendDate) => {
      const formattedWeekendDate = format(new Date(weekendDate), "yyyy-MM-dd");
      if (totalWorkHoursPerDate[formattedWeekendDate]) {
        markedDates[formattedWeekendDate] = {
          marked: true,
          dotColor: "green",
        };
      } else {
        markedDates[formattedWeekendDate] = {
          marked: true,
          dotColor: "white",
        };
      }
    });

    leaves.forEach((leave) => {
      const startDate = new Date(leave.leaveStartDate);
      const endDate = new Date(leave.leaveEndDate);
      while (startDate <= endDate) {
        const formattedDate = format(startDate, "yyyy-MM-dd");
        markedDates[formattedDate] = { marked: true, dotColor: "green" };
        startDate.setDate(startDate.getDate() + 1);
      }
    });

    holidays.forEach((holiday) => {
      markedDates[holiday] = { marked: true, dotColor: "skyblue" };
    });

    return markedDates;
  };

  const markedDates = useMemo(
    () => getMarkedDates(totalWorkHour, leaves, holidays, weekendDates),
    [totalWorkHour, leaves, weekendDates]
  );

  const renderDot = useCallback(
    ({ date, view }) => {
      if (view === "month") {
        const utcDate = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        );
        const dateString = utcDate.toISOString().split("T")[0];
        const isFutureDate = date > new Date();
        const isLeaveApplied = leaves.some(
          (leave) =>
            leave.leaveStartDate <= dateString &&
            dateString <= leave.leaveEndDate
        );
        const isFutureHoliday =
          holidays.some((holiday) => new Date(holiday) > new Date()) &&
          holidays.includes(dateString);

        let dotColor;

        if (isLeaveApplied) {
          dotColor = "green";
        } else if (isFutureDate && !isFutureHoliday) {
          dotColor = "white";
        } else {
          const dotInfo = markedDates[dateString];
          dotColor = dotInfo?.dotColor || "red";
        }

        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {<RxDotFilled size={25} style={{ color: dotColor }} />}
          </div>
        );
      }

      return null;
    },
    [leaves, markedDates]
  );

  const convertDurationToHours = (duration, fallbackTitle = "") => {
    // Extract title from description if possible
    const titleExtractRegex = /^(.*?)\s*\((\d+)m\)$/;
    const titleMatch = fallbackTitle.match(titleExtractRegex);

    let extractedTitle = fallbackTitle;
    let explicitDuration = duration;

    if (titleMatch) {
      extractedTitle = titleMatch[1].trim();
      explicitDuration = `${titleMatch[2]}m`;
    }

    // Handle decimal hour format
    if (/^\d+\.\d+$/.test(explicitDuration)) {
      const decimalHours = parseFloat(explicitDuration);
      const totalSeconds = Math.round(decimalHours * 3600); // Convert to total seconds

      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;

      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;

      return {
        hours: hours,
        minutes: remainingMinutes,
        display:
          hours > 0
            ? `${hours}h:${remainingMinutes} min`
            : `${remainingMinutes} min`, // Updated display format
        totalMinutes: totalSeconds / 60,
        title: extractedTitle,
        formatted: `${String(hours).padStart(2, "0")}:${String(
          remainingMinutes
        ).padStart(2, "0")}`, // hh:mm format
      };
    }

    // Regular expressions to match hours, minutes, and seconds
    const hourMatch = explicitDuration.match(/(\d+)h/);
    const minuteMatch = explicitDuration.match(/(\d+)m/);
    const secondMatch = explicitDuration.match(/(\d+)s/);

    let totalHours = 0;
    let totalMinutes = 0;
    let totalSeconds = 0;

    // Parse hours
    if (hourMatch) {
      totalHours += parseInt(hourMatch[1], 10);
    }

    // Parse minutes
    if (minuteMatch) {
      totalMinutes += parseInt(minuteMatch[1], 10);
    }

    // Parse seconds
    if (secondMatch) {
      totalSeconds += parseInt(secondMatch[1], 10);
    }

    // Convert all to seconds
    totalSeconds += totalMinutes * 60 + totalHours * 3600;

    // Determine display format based on duration
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (totalSeconds === 0) {
      return {
        hours: 0,
        minutes: 0,
        display: "0 min", // Default message
        totalMinutes: 0,
        title: extractedTitle,
        formatted: "00:00", // hh:mm format
      };
    } else {
      return {
        hours: hours,
        minutes: remainingMinutes,
        display:
          hours > 0
            ? `${hours}h:${remainingMinutes} min`
            : `${remainingMinutes} min`, // Updated display format
        totalMinutes: totalSeconds / 60,
        title: extractedTitle,
        formatted: `${String(hours).padStart(2, "0")}:${String(
          remainingMinutes
        ).padStart(2, "0")}`, // hh:mm format
      };
    }
  };

  const fetchTimeTrackingData = useCallback(async () => {
    if (!isShowingTimeTracking) return;

    setIsLoadingTimeTracking(true);
    try {
      const userId = auth.user._id;
      const response = await axiosInstance.get(
        `/tracking-data/user/${userId}?date=${selectedDate}`
      );

      // console.log("🔍 API Response Data:", response.data.data[0]?.records);

      // Transform tracking data into time tracking entries
      const transformedData = response.data.data
        .flatMap((entry) =>
          entry.records.map((record, index) => {
            // Enhanced duration parsing
            const duration = convertDurationToHours(
              record.duration,
              record.title
            );

            return {
              _id: `${entry._id}-${index}`,
              matter: {
                name: record.app || "Uncategorized",
                _id: record.appId || "unknown",
              },
              workDescription: duration.title || record.title || record.app,
              workHour: duration.hours,
              workMin: duration.minutes,
              workDuration: duration.display,
              totalMinutes: duration.totalMinutes,
              reviewed: true,
              date: formatDate(entry.date, "yyyy-MM-dd"),
              isAdded: record.isAdded ?? false,
              originalRecord: record,
            };
          })
        )
        // Filter to include only entries with 1 minute or more duration
        .filter((entry) => entry.totalMinutes >= 1);

      // Debugging: Log all records
      transformedData.forEach((entry) => {
        // console.log("🛠️ Processed Entry:", entry);
        // console.log("📌 Title:", entry.workDescription);
        // console.log("⏱️ Duration:", `${entry.workHour}h ${entry.workMin}m`);
      });

      // Sort by duration
      transformedData.sort((a, b) => b.totalMinutes - a.totalMinutes);

      // Filter to selected date or today
      const today = formatDate(new Date());
      // Filter to include only entries with a duration of 1 minute or more
      const filteredData = selectedDate
        ? transformedData.filter(
            (entry) => entry.totalMinutes >= 1 && entry.date === selectedDate
          )
        : transformedData.filter(
            (entry) => entry.totalMinutes >= 1 && entry.date === today
          );

      setTimeTrackingData(filteredData);
    } catch (error) {
      console.error("🚨 Error fetching time tracking data:", error);
      errorAlert("Failed to fetch time tracking data");
      setTimeTrackingData([]);
    } finally {
      setIsLoadingTimeTracking(false);
    }
  }, [isShowingTimeTracking, selectedDate, auth.user._id]);

  const openEditModal = (time, type) => {
    if (type === "add") {
      setShowEditModal(true);
      setEditingTime(null);
      setDate(selectedDate);
      setWorkDescription("");
      setModalMatter("");
      setModalSelectedMatterName("");
      setComments("");
      setWorkHour(0);
      setWorkMin(30);
    } else if (type === "add_time") {
      setShowEditModal(true);
      setEditingTime(null);
      setDate(time.date);
      // setWorkDescription(time.workDescription);
      setModalMatter("");
      setModalSelectedMatterName("");
      setComments("");
      setWorkHour(time.workHour);

      const roundedMinutes = Math.ceil(time.workMin / 6) * 6; // Round up to nearest 6 minutes
      setWorkMin(roundedMinutes); // Update state with rounded minutes
      let description = time.workDescription; // Start with the existing description
      if (workHour > 0) {
        description += ` ${workHour} hr`; // Add hours if greater than 0
      }
      if (roundedMinutes > 0) {
        description += ` ${roundedMinutes} min`; // Add minutes if greater than 0
      }
      setWorkDescription(description);
    } else {
      if (time.workDate <= time.matter.lastSentTSDate) {
        errorAlert("Time entry is blocked for this date.");
        return;
      }
      handleMatterChange(time.matter._id);
      setShowEditModal(true);
      setEditingTime(time);
      setDate(time.workDate);
      setEntryId(time._id);
      setWorkDescription(time.workDescription);
      setModalMatter(time.matter);
      setModalSelectedMatterName(time.matter.name);
      setComments(time.comments);
      const hour = time.workHour.split(".");
      setWorkHour(hour[0]);
      setWorkMin(Math.floor((hour[1] * 6) / 10));
    }
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setDate(new Date());
    setWorkDescription("");
    setModalMatter("");
    setModalSelectedMatterName("");
    setModalClient("");
    setComments("");
    setWorkHour(0);
    setWorkMin(30);
    setEntryId("");
    setAiSuggestion("");
    setIsRetryingAi(false);
  };

  const isValidObjField = () => {
    return !(
      date === "" ||
      modalMatter === "" ||
      workDescription === "" ||
      workHour === "00" ||
      workMin === "00"
    );
  };

  const isValidForm = () => {
    if (!isValidObjField()) return errorAlert("All Fields are Required!");
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const time = {
      admin: auth.user.company,
      user: auth.user._id,
      workDate: date,
      matter: modalMatter,
      workDescription,
      comments,
      workHour: `${workHour}.${workMin * (10 / 6)}`,
      reviewed: true,
      isAdded: true, // Explicitly set isAdded flag
    };

    if (isValidForm()) {
      // console.log("date", date, "lastSentTSDate", lastSentTSDate);
      if (date < lastSentTSDate || date < matterOpenDate) {
        errorAlert("Time entry is blocked for this date.");
        return;
      }
      if (editingTime) {
        try {
          const response = await axiosInstance.put(
            `/workHour/update/${entryId}`,
            { ...time, isAdded: true } // Ensure isAdded is set for updates
          );
          if (response.data) {
            successAlert("Time Updated Successfully!");
            setUserWorkHour((prevUserWorkHour) => {
              const updatedWorkHour = prevUserWorkHour.map((entry) =>
                entry._id === entryId
                  ? { ...response.data, isAdded: true } // Explicitly set isAdded
                  : entry
              );
              return updatedWorkHour;
            });
            closeEditModal();
            fetchData();
            fetchTimeTrackingData();
          }
        } catch (error) {
          console.error("Error updating time:", error);
          errorAlert("Error updating time");
        }
      } else {
        try {
          const response = await axiosInstance.post(
            `/workHour/createNew`,
            time
          );
          if (response.data) {
            successAlert("Great !! Time Added Successfully!");
            closeEditModal();
            fetchData();
            fetchTimeTrackingData();
          }
        } catch (error) {
          console.error("Error adding time:", error);
          errorAlert("Error adding time");
        }
      }
    }
  };

  const tileClassName = ({ date }) => {
    if (date.getDay() === 0 || date.getDay() === 6) {
      return "react-calendar__tile--weekend";
    }
    return "";
  };

  const handleMatterChange = async (option) => {
    try {
      // console.log("Matter id", option);
      const response = await axiosInstance.get(
        `/matter/getMatterByMatterId/${option}`
      );
      const matterDetails = response.data.matterDetails;
      // console.log("Matter details", matterDetails);

      setLastSentTSDate(addOneDay(matterDetails?.lastSentTSDate)); //block the dates from next date
      setMatterOpenDate(matterDetails?.matterOpenDate);
      // setDate(selectedDate < lastSentTSDate ? lastSentTSDate : selectedDate);
      // setSelectedDate(
      //   selectedDate < lastSentTSDate ? lastSentTSDate : selectedDate
      // );
      // console.log(
      //   "name",
      //   matterDetails.name,
      //   "selectedDate",
      //   selectedDate,
      //   "date",
      //   date,
      //   "lastSentTSDate",
      //   lastSentTSDate,
      //   "matterOpenDate",
      //   matterOpenDate
      // );

      // console.log("lastSentTSDate", response.data.matterDetails.lastSentTSDate);
    } catch (error) {
      console.error("Error fetching matter details:", error);
    }
  };

  // Function to handle the info icon click and open the popup
  const [openInfo, setOpenInfo] = React.useState(false);

  const handleInfoClick = () => {
    setOpenInfo(true);
  };

  const handleInfoClose = () => {
    setOpenInfo(false);
  };

  // State to keep track of the deleted item
  const [deletedItem, setDeletedItem] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Delete handler
  const handleSoftDelete = async (entry) => {
    try {
      if (
        entry.workDate <= entry.matter.lastSentTSDate ||
        entry.workDate < entry.matter.matterOpenDate
      ) {
        errorAlert("Time entry is blocked for this date.");
        return;
      }

      // console.log("entry data",entry)

      const response = await axiosInstance.delete(
        `/workhour/softdelete/${entry._id}`
      );

      const data = response.data.deletedWorkhour;

      // console.log("Delete response:", data);

      if (response.data) {
        setDeletedItem({ entryId: data._id, ...data });
        // Show the undo alert
        // undoAlert("Workhour deleted.", handleUndo);
        setSnackbarOpen(true);
        // console.log("Deleted Item State:", { entryId: data._id, ...data }); // Debug the deleted item state
        fetchData();
      } else {
        errorAlert("Failed to delete workhour.");
      }
    } catch (error) {
      errorAlert("An error occurred while deleting workhour.");
    }
  };

  // Undo handler
  const handleUndo = async () => {
    try {
      if (deletedItem) {
        const response = await axiosInstance.post(
          `/workhour/restore/${deletedItem.entryId}`
        );
        await response.data;

        if (response.data) {
          // Remove the item from temporary state
          setDeletedItem(null);
          successAlert("Workhour restored successfully.");
          fetchData();
        } else {
          errorAlert("Failed to restore workhour.");
        }
      }
    } catch (error) {
      errorAlert("An error occurred while restoring workhour.");
    }
  };

  // Approve handler function
  const handleApprove = async (entryId) => {
    try {
      const response = await axiosInstance.put(`/workHour/update/${entryId}`, {
        reviewed: true,
      });

      if (response.data) {
        successAlert("Time Approved Successfully!");
        fetchData(); // Refresh the data to show updated entries
      }
    } catch (error) {
      console.error("Error approving time:", error);
      errorAlert("Error approving time");
    }
  };

  const [aiSuggestion, setAiSuggestion] = useState("");
  const [isRetryingAi, setIsRetryingAi] = useState(false); // New state to track retries

  // Function to fetch AI suggestion
  const getAiSuggestion = async (workDescription, isDescriptive = false) => {
    try {
      if (date <= lastSentTSDate || date < matterOpenDate) {
        errorAlert("Re-draft is not available for the blocked dates.");
        return;
      }

      const response = await axiosInstance.post(`/ai/suggest`, {
        workDescription,
        isDescriptive, // Pass the descriptive flag to the backend
      });
      return response.data.aiSuggestedDescription;
    } catch (error) {
      console.error("Error fetching AI suggestion:", error);
      errorAlert("Failed to get AI suggestion. Please try again.");
      return "";
    }
  };

  // AI submit handler
  const redraftWithAI = async () => {
    if (
      date &&
      modalMatter &&
      workDescription &&
      workHour !== "" &&
      workMin !== ""
    ) {
      const suggestion = await getAiSuggestion(workDescription, false);
      setAiSuggestion(suggestion);
      setIsRetryingAi(false); // Reset retry state
    } else {
      errorAlert("All Fields are Required!");
    }
  };

  // Function to retry AI suggestion
  const retryAiSuggestion = async () => {
    if (aiSuggestion) {
      setIsRetryingAi(true); // Set retry state to true
      const newSuggestion = await getAiSuggestion(aiSuggestion, false); // Fetch a new suggestion based on current aiSuggestion
      setAiSuggestion(newSuggestion); // Update the AI suggestion
      setIsRetryingAi(false); // Reset retry state after fetching
    } else {
      errorAlert("No AI suggestion available to retry.");
    }
  };

  // Submit with AI suggestion
  const onSubmitWithAiSuggestion = async () => {
    if (
      date &&
      modalMatter &&
      aiSuggestion &&
      workHour !== "" &&
      workMin !== ""
    ) {
      const timeData = {
        admin: auth?.user?.company,
        user: auth?.user?._id,
        workDate: date,
        matter: modalMatter,
        workDescription: aiSuggestion, // Use the AI suggestion
        comments,
        workHour: `${workHour}.${workMin * (10 / 6)}`,
        reviewed: true,
      };

      if (date <= lastSentTSDate || date < matterOpenDate) {
        errorAlert("Time entry is blocked for this date.");
        return;
      }

      try {
        if (editingTime) {
          // Update the existing entry with AI suggestion
          const response = await axiosInstance.put(
            `/workHour/update/${entryId}`,
            timeData
          );

          if (response.data) {
            successAlert("Time Updated Successfully with AI Suggestion!");
            setUserWorkHour((prevUserWorkHour) => {
              const updatedWorkHour = prevUserWorkHour.map((entry) =>
                entry._id === entryId ? response.data : entry
              );
              return updatedWorkHour;
            });
            closeEditModal();
            fetchData();
          }
          setAiSuggestion("");
          setIsRetryingAi(false);
        } else {
          // Create a new entry with AI suggestion
          const response = await axiosInstance.post(
            `/workHour/createNew`,
            timeData
          );

          if (response.data) {
            successAlert("Time Added Successfully with AI Suggestion!");
            closeEditModal();
            fetchData();
          }
        }
      } catch (error) {
        console.error(
          "Error adding or updating time with AI suggestion:",
          error
        );
        errorAlert("Error adding or updating time with AI suggestion");
      }
    } else {
      errorAlert("All Fields are Required!");
    }
  };

  useEffect(() => {
    fetchTimeTrackingData();
  }, [fetchTimeTrackingData]);

  // Add state for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Paginate the time tracking data
  const paginatedTimeTrackingData = timeTrackingData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleTimeTrackingClick = () => {
    // console.log("Time Tracking Clicked");
    setIsShowingTimeTracking(true);
    setIsShowingPending(false); // Ensure other states are false
  };

  const handleEnteredClick = () => {
    setIsShowingPending(false);
    setIsShowingTimeTracking(false);
  };

  const handleMaybeClick = () => {
    setIsShowingPending(true);
    setIsShowingTimeTracking(false);
  };

  return (
    <>
      <Header />
      <ToastContainer />

      {/* Snackbar for Undo Action */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Change the position here
        open={snackbarOpen}
        autoHideDuration={5000} // Hide after 10 seconds if no action is taken
        onClose={() => setSnackbarOpen(false)}
        message="Workhour deleted"
        action={
          <Button color="secondary" size="small" onClick={handleUndo}>
            UNDO
          </Button>
        }
      />

      {showEditModal && (
        <div className="modal">
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <Typography variant="h5" mb={2} align="center">
              {editingTime ? "Edit Time" : "Add Time"}
            </Typography>

            <form className="flex flex-col gap-3">
              <div className="flex align-middle gap-4 w-full">
                <div style={{ width: "50%" }}>
                  <div style={{ width: "100%" }}>
                    <input
                      type="date"
                      value={date}
                      min={
                        (matterOpenDate < lastSentTSDate
                          ? lastSentTSDate
                          : matterOpenDate) || ""
                      }
                      onChange={(e) => setDate(e.target.value)}
                      style={{
                        borderColor: "#aaa",
                        backgroundColor: "#fff",
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    />
                  </div>
                </div>

                <div style={{ width: "50%" }}>
                  <div style={{ width: "100%" }}>
                    <Select
                      options={[
                        ...filteredMatters,
                        {
                          value: modalMatter,
                          label: `${modalSelectedMatterName} | ${modalClient}`,
                        },
                      ]}
                      value={
                        modalSelectedMatterName
                          ? {
                              value: modalMatter,
                              label: `${modalSelectedMatterName} | ${modalClient}`,
                            }
                          : null
                      }
                      onChange={(e) => {
                        setModalMatter(e.value);
                        setModalSelectedMatterName(
                          e.label.split("|")[0].trim()
                        );
                        setModalClient(e.label.split("|")[1].trim());
                        handleMatterChange(e.value);
                      }}
                      isSearchable
                      placeholder="Select Matter"
                      styles={customStyles}
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-4 w-full">
                <div style={{ width: "50%" }}>
                  <select
                    value={workHour}
                    name="workHour"
                    onChange={(event) => {
                      setWorkHour(event.target.value);
                    }}
                    style={{
                      borderColor: "#aaa",
                      backgroundColor: "#fff",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <option value="">Select Work Hour *</option>
                    {Array.from({ length: 19 }, (_, i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={{ width: "50%" }}>
                  <select
                    value={workMin}
                    name="workMin"
                    onChange={(event) => {
                      setWorkMin(event.target.value);
                    }}
                    style={{
                      borderColor: "#aaa",
                      backgroundColor: "#fff",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <option value="">Select Work Minutes *</option>
                    {Array.from({ length: 10 }, (_, i) => (
                      <option key={i * 6} value={i * 6}>
                        {i * 6}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Comments for the reviewer"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              </div>
              <div>
                <textarea
                  style={{ height: 80, width: "100%" }}
                  type="text"
                  placeholder="Work description"
                  value={workDescription}
                  onChange={(e) => setWorkDescription(e.target.value)}
                />
              </div>

              {/* Redraft button, show only if aiSuggestion is not present */}
              {!aiSuggestion && (
                <div className="flex gap-4 w-full justify-end">
                  <CustomTooltip text="Redraft with AI">
                    <CustomButton
                      style={{ width: "100%" }}
                      onClick={redraftWithAI}
                      disabled={isRetryingAi} // Disable when retrying
                    >
                      <HiSparkles size={18} style={{ marginRight: "5px" }} />
                      Redraft
                    </CustomButton>
                  </CustomTooltip>
                </div>
              )}

              {/* AI suggested description with Retry button */}
              {aiSuggestion && (
                <>
                  <Typography variant="body2">AI Suggested</Typography>
                  <div style={{ position: "relative" }}>
                    <textarea
                      style={{
                        height: 80,
                        width: "100%",
                        backgroundColor: "#f0f0f0",
                        paddingRight: "40px",
                        resize: "none",
                      }}
                      value={isRetryingAi ? "Revising again..." : aiSuggestion}
                      onChange={(e) => setAiSuggestion(e.target.value)}
                      disabled={isRetryingAi}
                    />
                    {/* Retry button positioned inside the textarea container */}
                    <CustomTooltip text="Retry" placement="top" arrow="">
                      <IconButton
                        onClick={retryAiSuggestion}
                        style={{
                          position: "absolute",
                          right: "5px",
                          top: "5px",
                          color: isRetryingAi ? "gray" : "#555",
                        }}
                        disabled={isRetryingAi}
                      >
                        <TfiReload style={{ fontSize: "20px" }} />
                      </IconButton>
                    </CustomTooltip>
                  </div>
                </>
              )}

              <div className="flex justify-center align-middle gap-3">
                <CustomButton onClick={handleSubmit}>Submit</CustomButton>
                {aiSuggestion && (
                  <CustomButton onClick={onSubmitWithAiSuggestion}>
                    Submit Revised
                  </CustomButton>
                )}
                <CustomButton
                  variant="outlined"
                  style={{ width: "30%" }}
                  onClick={closeEditModal}
                >
                  Cancel
                </CustomButton>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* HEADING AND BUTTONS  */}
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        paddingX={isXs ? 2 : 4}
        sx={{
          marginTop: 2,
        }}
      >
        {/* Heading with Info Icon */}
        <Grid item sm={4}>
          <Box display="flex">
            <Typography variant="h5">Explore Your Calendar</Typography>
            <CustomTooltip title="More Info">
              <IconButton onClick={handleInfoClick}>
                <InfoIcon />
              </IconButton>
            </CustomTooltip>
          </Box>
          {/* InfoPopup Component */}
          <InfoPopup open={openInfo} handleClose={handleInfoClose} />
        </Grid>

        {/* Buttons on the Right */}
        <Grid item sm={8} xs={12} display="flex" justifyContent="flex-end">
          <Box
            display="flex"
            flexWrap="wrap"
            gap={2}
            justifyContent="flex-end"
            alignItems="center"
            style={{ width: "100%" }} // Ensures the box adapts to mobile screen width
          >
            {/* Leave Button */}
            {todayLeaves.length !== 0 && (
              <CustomButton
                variant={
                  !isShowingPending && !isShowingTimeTracking
                    ? "contained"
                    : "outlined"
                }
                onClick={handleEnteredClick}
                style={{
                  width: isSm ? "auto" : "120px",
                  padding: isSm ? "6px 12px" : "10px 20px",
                  fontSize: isxl ? "" : "14px",
                  backgroundColor: "green",
                }}
              >
                Leave Day
              </CustomButton>
            )}

            {/* Add Time Button */}
            <CustomButton
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: COLORS.primary,
                color: "white",
                fontWeight: 600,
                padding: "10px 15px",
                // borderRadius: "30px",
                width: "auto",
                fontSize: isxl ? "" : "14px",
              }}
              onClick={() => openEditModal(null, "add")}
            >
              Add Time
              <PlusCircleFilled
                style={{ fontSize: "20px", marginLeft: "10px" }}
              />
            </CustomButton>

            {/* Entered Button */}
            <CustomButton
              variant={
                !isShowingPending && !isShowingTimeTracking
                  ? "contained"
                  : "outlined"
              }
              onClick={handleEnteredClick}
              style={{
                width: isSm ? "auto" : "120px",
                padding: isSm ? "6px 12px" : "10px 20px",
                fontSize: isxl ? "" : "14px",
              }}
            >
              Entered
            </CustomButton>

            {/* Maybe? Button */}
            <CustomButton
              variant={isShowingPending ? "contained" : "outlined"}
              onClick={handleMaybeClick}
              style={{
                width: isSm ? "auto" : "120px",
                padding: isSm ? "6px 12px" : "10px 20px",
                fontSize: isxl ? "" : "14px",
              }}
            >
              Maybe?
            </CustomButton>

            {/* Time Tracking Button */}
            <CustomButton
              variant={isShowingTimeTracking ? "contained" : "outlined"}
              onClick={handleTimeTrackingClick}
              style={{
                width: isSm ? "auto" : "140px",
                padding: isSm ? "6px 12px" : "10px 20px",
                fontSize: isxl ? "" : "14px",
              }}
            >
              Time Tracking
            </CustomButton>
          </Box>
        </Grid>
      </Grid>

      {/* CALENDAR AND ENTIRES CONTAINER */}
      <Grid container spacing={2} paddingX={isSm ? 2 : 5} paddingY={2}>
        {/* LEFT CALENDAR */}
        <Grid item xs={12} sm={12} md={3.5} xl={2.4}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            display="flex"
            justifyContent="center"
          >
            <Calendar
              onClickDay={handleDateClick}
              onActiveStartDateChange={({ activeStartDate }) => {
                setActiveStartDate(activeStartDate);
              }}
              tileContent={renderDot}
              tileClassName={tileClassName}
            />
          </Grid>
        </Grid>

        {/* RIGHT ENTIRES CONTAINER */}
        <Grid item xs={12} sm={12} md={8.5} xl={9.6}>
          <Paper className="entries-container" style={{ borderRadius: 2 }}>
            <div id="entries-heading-data-container">
              {!isShowingTimeTracking && parseFloat(total) !== 0 && (
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={6} md={2.5}>
                    <div id="button-design">
                      <Typography
                        variant="body2"
                        style={{ fontSize: isxl ? "" : "16px" }}
                      >
                        Total : {parseFloat(total).toFixed(1)} hrs
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div id="button-design">
                      <Typography
                        variant="body2"
                        style={{ fontSize: isxl ? "" : "16px" }}
                      >
                        Billable : {parseFloat(totalBillable).toFixed(1)} hrs
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={3.5}>
                    <div id="button-design">
                      <Typography
                        variant="body2"
                        style={{ fontSize: isxl ? "" : "16px" }}
                      >
                        Non-Billable :{" "}
                        {parseFloat(total - totalBillable).toFixed(1)} hrs
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
            {isShowingTimeTracking ? (
              <TableContainer>
                {isLoadingTimeTracking ? (
                  <Box display="flex" justifyContent="center" padding={2}>
                    <Typography>Loading time tracking data...</Typography>
                  </Box>
                ) : paginatedTimeTrackingData.length === 0 ? (
                  <NoDataFound
                    message={
                      selectedDate
                        ? `No time tracking entries found for ${selectedDate}`
                        : "No time tracking entries found for today"
                    }
                  />
                ) : (
                  <>
                    <Box display="flex" justifyContent="center" padding={1}>
                      <Typography variant="subtitle1">
                        {selectedDate
                          ? `Time Tracking Entries for ${selectedDate}`
                          : "Today's Time Tracking Entries"}
                      </Typography>
                    </Box>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Application</b>
                          </TableCell>
                          <TableCell>
                            <b>Title</b>
                          </TableCell>
                          <TableCell>
                            <b>Duration</b>
                          </TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paginatedTimeTrackingData.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              <Typography variant="body2" color="textSecondary">
                                No time tracking data available
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          paginatedTimeTrackingData.map((entry) => {
                            // Calculate rounded hours and minutes
                            const totalMinutes =
                              entry.workHour * 60 + entry.workMin;
                            const roundedHours = Math.floor(totalMinutes / 60);
                            const roundedMinutes =
                              Math.round((totalMinutes % 60) / 6) * 6;

                            // Check if this entry already exists in userWorkHour
                            const normalizedDescription = `${entry.matter.name}: ${entry.workDescription} (${entry.workDuration})`;
                            const isAlreadyAdded = userWorkHour.some(
                              (workEntry) =>
                                workEntry.workDescription ===
                                  normalizedDescription &&
                                workEntry.workDate === entry.date &&
                                workEntry.isAdded === true
                            );

                            return (
                              <TableRow key={entry._id}>
                                <TableCell>{entry.matter.name}</TableCell>
                                <TableCell>{entry.workDescription}</TableCell>
                                <TableCell>{entry.workDuration}</TableCell>
                                <TableCell align="center">
                                  <CustomTooltip title="Add Time Entry">
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        const workHourEntry = {
                                          date: entry.date,
                                          _id: entry._id,
                                          workDescription:
                                            entry.workDescription,
                                          workHour: entry.workHour.toString(),
                                          workMin: entry.workMin,
                                          isAdded: false, // Explicitly set isAdded flag for new additions
                                        };
                                        // console.log("➕ Adding Entry:", workHourEntry); // Log the entry being added
                                        openEditModal(
                                          workHourEntry,
                                          "add_time"
                                        ); // Call the modal function
                                      }}
                                    >
                                      <Add />
                                    </IconButton>
                                  </CustomTooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="td"
                            count={timeTrackingData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Entries per page"
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </>
                )}
              </TableContainer>
            ) : (
              // Existing Work Hours Table
              <>
                {searchResult.length === 0 && (
                  <NoDataFound message="Oops!!....No WorkHours found." />
                )}
                {searchResult.length !== 0 && (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Matter</b>
                          </TableCell>
                          <TableCell>
                            <b>Narration</b>
                          </TableCell>
                          <TableCell>
                            <b>WorkHours</b>
                          </TableCell>
                          <TableCell>
                            <b>Edit</b>
                          </TableCell>
                          {isShowingPending && (
                            <TableCell>
                              <b>Approve</b>
                            </TableCell>
                          )}
                          <TableCell>
                            <b>Delete</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {Object.keys(matterGroups).map((matterName) => (
                          <React.Fragment key={matterName}>
                            {matterGroups[matterName].entries.map((entry) => (
                              <TableRow key={entry._id}>
                                <TableCell>{entry.matter?.name}</TableCell>
                                <TableCell>{entry.workDescription}</TableCell>
                                <TableCell>{entry.workHour}</TableCell>
                                <TableCell>
                                  <CiEdit
                                    style={{ cursor: "pointer" }}
                                    onClick={() => openEditModal(entry, "edit")}
                                  />
                                </TableCell>

                                {isShowingPending && (
                                  <TableCell>
                                    <IconButton
                                      style={{ color: "green" }}
                                      onClick={() => handleApprove(entry._id)}
                                    >
                                      <Check style={{ fontSize: "22px" }} />
                                    </IconButton>
                                  </TableCell>
                                )}

                                <TableCell>
                                  <RiDeleteBin6Line
                                    size={16}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleSoftDelete(entry)}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}

                            <TableRow>
                              <TableCell className=" bg-[#f8f8f8]" colSpan={2}>
                                <strong>Total for {matterName}</strong>
                              </TableCell>
                              <TableCell className=" bg-[#f8f8f8]">
                                <strong>
                                  {parseFloat(
                                    matterGroups[matterName].total
                                  ).toFixed(2)}
                                </strong>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </>
            )}

            {todayLeaves.length !== 0 && !isShowingPending && <hr />}
            {todayLeaves.length !== 0 && !isShowingPending && (
              <TableContainer>
                {/* Centering Leave Details */}
                <Typography
                  variant="h5"
                  align="center"
                  marginY={2}
                  gutterBottom
                >
                  Leave Details
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Leave Type</b>
                      </TableCell>
                      <TableCell>
                        <b>Leave Days</b>
                      </TableCell>
                      <TableCell>
                        <b>Leave Status</b>
                      </TableCell>
                      <TableCell className="table-cell">
                        <b>Narration</b>
                      </TableCell>
                      {/* <TableCell>
                        <b>Edit</b>
                      </TableCell>
                      <TableCell>
                        <b>Delete</b>
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {todayLeaves.map((leave) => (
                      <TableRow key={leave._id}>
                        <TableCell>
                          {" "}
                          {leave.leaveType === "workFromHome"
                            ? "Work From Home"
                            : leave.leaveType === "quarterDay"
                            ? "Quarter Day"
                            : leave.leaveType === "halfDay"
                            ? "Half Day"
                            : "Leave"}
                        </TableCell>
                        <TableCell>{leave.leaveDays}</TableCell>
                        <TableCell
                          style={{
                            color:
                              leave.leaveStatus === "approved"
                                ? "green"
                                : leave.leaveStatus === "pending"
                                ? "orange"
                                : "red",
                            fontWeight: "bold",
                            padding: "10px",
                          }}
                        >
                          {leave.leaveStatus === "pending"
                            ? "Pending"
                            : leave.leaveStatus === "approved"
                            ? "Approved"
                            : "Rejected"}
                        </TableCell>
                        <TableCell className="table-cell">
                          {leave.leaveDescription}
                        </TableCell>
                        {/* <TableCell>
                          <CiEdit
                            style={{ cursor: "pointer" }}
                            onClick={() => openLeaveModal(leave, "edit")}
                          />
                        </TableCell>
                        <TableCell>
                          <RiDeleteBin6Line
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteLeave(leave._id)}
                          />
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
      </Grid>

      {window.innerWidth <= 768 ? (
        <Grid item xs={12}>
          <Box sx={{ height: "70px" }}></Box> {/* Add 50px of space */}
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default MyCalendar;
