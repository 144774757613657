import { timeConstants } from "./constants";
import axiosInstance from "../helpers/axios";

export const updateWorkHoursWithDiscount = (entryId, discountHours) => {
  return async (dispatch) => {
    try {
      dispatch({ type: timeConstants.UPDATE_WORKHOURS_WITH_DISCOUNT_REQUEST });

      const response = await axiosInstance.put(
        `/workhour/admin/updateWithDiscount`,
        { entryId, updatedWorkHours: discountHours } // Pass discountHours as 'updatedWorkHours'ß
      );

      dispatch({
        type: timeConstants.UPDATE_WORKHOURS_WITH_DISCOUNT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error("Error in updating work hours with discount", error);
      dispatch({
        type: timeConstants.UPDATE_WORKHOURS_WITH_DISCOUNT_FAILURE,
        payload: error.message,
      });
    }
  };
};
