// import axiosInstance from "../helpers/axios";
// import { matterConstants } from "./constants";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// export const getAllMatters = (rowsPerPage, page) => {
//   return async (dispatch) => {
//     dispatch({ type: matterConstants.GET_ALL_MATTER_REQUEST });

//     try {
//       const res = await axiosInstance.get(
//         `/matter/getAllMatters`,
//         {
//           params: {
//             limit: rowsPerPage,
//             page,
//           },
//         }
//       );

//       dispatch({
//         type: matterConstants.GET_ALL_MATTER_SUCCESS,
//         payload: {
//           matters: res.data.matters,
//           totalMatters: res.data.totalMatters,
//         },
//       });
//     } catch (error) {
//       console.error("Error fetching matters:", error);

//       dispatch({
//         type: matterConstants.GET_ALL_MATTER_FAILURE,
//         payload: {
//           error: error.response?.data?.error || "Something went wrong",
//         },
//       });

//       toast.error(
//         error.response?.data?.error ||
//           "Something went wrong while fetching matters"
//       );
//     }
//   };
// };
