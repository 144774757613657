import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllClients } from "../../actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import formatDate, {
  customStyles,
  errorAlert,
  successAlert,
} from "../../utils/services";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axiosInstance from "../../helpers/axios";
import "./AddMatter.css";
import { fetchCurrencySettings } from "../../actions/currencyActions";
import { fetchDesignations } from "../../store/slices/globalSlice";
import { fetchEmployees } from "../../store/slices/employeesSlice";
import { fetchAllMatters } from "../../store/slices/matterSlice";

const AddMatter = ({ onClose }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const adminClient = useSelector((state) => state.adminClient);
  const allCurrencyData = useSelector((state) => state.currency);

  const { billedDesignations, unBilledDesignations } = useSelector(
    (state) => state.global
  );
  const { clientList } = adminClient;
  const { employeesData, filteredEmployees } = useSelector(
    (state) => state.employee
  );

  // Fetch designations, employees, clients, currency
  useEffect(() => {
    dispatch(fetchDesignations());
    dispatch(fetchEmployees());
    dispatch(getAllClients());
    dispatch(fetchCurrencySettings());
  }, [dispatch]);

  // State for currency
  const [currencyData, setCurrencyData] = useState({
    nativeCurrencyName: "",
    nativeCurrencyCode: "",
    conversionRates: [],
  });

  // Keep local currencyData in sync
  useEffect(() => {
    setCurrencyData(allCurrencyData);
  }, [allCurrencyData]);

  const currencies = [
    currencyData.nativeCurrencyCode,
    ...(currencyData.conversionRates || []).map((curr) => curr.currencyCode),
  ];

  // Pricing States
  const [radioValue, setRadioValue] = useState("Price/hour");
  const [isResourceWise, setIsResourceWise] = useState(false);
  const [resourceSpecificPrices, setResourceSpecificPrices] = useState([]);
  const [designationPrices, setDesignationPrices] = useState({});
  const [designationApplied, setDesignationApplied] = useState(false);

  // Basic Form State
  const [formData, setFormData] = useState({
    name: "",
    client: "",
    currency: "",
    pricePerHour: "",
    matterOpenDate: formatDate(new Date()),
    matterAddress: "",
    engagementLetter: [],
    flatFeesAmount: "",
    matterType: "",
  });

  // ---------------
  // MULTIPLE ADVANCES
  // ---------------
  // Here we add an array for multiple advance entries
  const [advanceInvoices, setAdvanceInvoices] = useState([]);
  // Temporary fields for adding one new advance
  const [advanceAmount, setAdvanceAmount] = useState("");
  const [advanceNote, setAdvanceNote] = useState("");
  const [advanceDate, setAdvanceDate] = useState(formatDate(new Date()));

  // For resource wise
  const [newResource, setNewResource] = useState("");
  const [newResourcePrice, setNewResourcePrice] = useState("");

  // File Upload
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);

  // Submission
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Prepare Client Options for Select
  const clientNames = clientList.map((client) => ({
    value: client._id,
    label: client.name,
  }));

  // Handle text field changes
  const handleOnChangeText = (value, name) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // MULTIPLE ADVANCES: Add new item
  const handleAddAdvance = () => {
    // Basic validation
    if (!advanceAmount) {
      errorAlert("Amount is required for advance entry.");
      return;
    }
    // Construct a new "advance" object
    // The schema uses Decimal128, but sending as a string is fine. The server will convert it.
    const newAdvance = {
      amount: advanceAmount,
      note: advanceNote,
      date: advanceDate, // Or you can let the server default it
    };

    setAdvanceInvoices((prev) => [...prev, newAdvance]);
    // Clear temporary fields
    setAdvanceAmount("");
    setAdvanceNote("");
    setAdvanceDate(formatDate(new Date()));
    successAlert("Advance added!");
  };

  // MULTIPLE ADVANCES: Remove an item
  const removeAdvanceItem = (index) => {
    setAdvanceInvoices((prev) => prev.filter((_, i) => i !== index));
  };

  // Designation-wise Price
  const handleDesignationPriceChange = (designation, price) => {
    setDesignationPrices((prev) => ({
      ...prev,
      [designation]: price,
    }));
  };

  const applyDesignationPrices = () => {
    const updatedResourceSpecificPrices = employeesData
      .filter(
        (emp) =>
          !unBilledDesignations.includes(emp.designation) &&
          emp.status !== "inActive"
      )
      .map((employee) => {
        // Get price based on designation
        const price = designationPrices[employee.designation];
        if (price) {
          return `${employee.fullName}: ${price}`;
        }
        return null;
      })
      .filter((entry) => entry !== null);

    setResourceSpecificPrices(updatedResourceSpecificPrices);
    setDesignationApplied(true);
    successAlert("Designation-wise prices applied successfully.");
  };

  // Add Resource + Price
  const handleAddNewResource = () => {
    if (!newResource || !newResourcePrice) {
      errorAlert("Please select a resource and assign a price.");
      return;
    }
    // Check if the resource is already added
    const isAlreadyAdded = resourceSpecificPrices.some((r) =>
      r.startsWith(newResource + ":")
    );
    if (isAlreadyAdded) {
      errorAlert("Resource already added.");
      return;
    }

    const newEntry = `${newResource.trim()}: ${newResourcePrice}`;
    setResourceSpecificPrices((prev) => [...prev, newEntry]);
    setNewResource("");
    setNewResourcePrice("");
    successAlert("Resource added successfully.");
  };

  // File Upload
  const handleFileUpload = async (e) => {
    if (e.target.files.length > 0) {
      const files = e.target.files;
      const uploadPromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("adminId", auth.user._id);

        uploadPromises.push(axiosInstance.post(`/uploadMatterEL`, formData));
      }

      try {
        setUploading(true);
        const uploadResponses = await Promise.all(uploadPromises);
        const uploadData = await Promise.all(
          uploadResponses.map((res) => res.data)
        );

        const uploadedFileUrls = uploadData.map((d) => d.url);
        setSelectedFile((prev) => [...prev, ...uploadedFileUrls]);
      } catch (error) {
        console.error(error);
      } finally {
        setUploading(false);
      }
    }
  };

  // Remove file from array
  const handleRemoveFile = (index) => {
    setSelectedFile((prev) => prev.filter((_, i) => i !== index));
  };

  // Toggle pricing type
  const handlePricingToggle = (isResourceWiseSelected) => {
    // If switching to Resource-wise, clear the resource-specific prices
    if (isResourceWiseSelected) {
      setResourceSpecificPrices([]);
    }
    setIsResourceWise(isResourceWiseSelected);
  };

  // Submit the form
  const onAddMatter = async (event) => {
    event.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      // Validate resource-specific logic
      if (radioValue === "ResourceSpecific") {
        if (!isResourceWise && !designationApplied) {
          errorAlert("Please apply designation-wise prices before submitting.");
          setIsSubmitting(false);
          return;
        }
        if (isResourceWise && resourceSpecificPrices.length === 0) {
          errorAlert("Please assign prices to resources before submitting.");
          setIsSubmitting(false);
          return;
        }
      }

      // Validate required fields
      if (!isValidForm()) {
        setIsSubmitting(false);
        return;
      }

      // Build form data
      const form = new FormData();
      form.append("admin", auth.user._id);
      form.append("name", formData.name);
      form.append("client", formData.client);
      form.append("currency", formData.currency);
      form.append("pricePerHour", formData.pricePerHour || 0);
      form.append("matterOpenDate", formData.matterOpenDate);
      form.append("matterAddress", formData.matterAddress || "");
      form.append("flatFeesAmount", formData.flatFeesAmount || 0);
      form.append("matterType", formData.matterType || "");

      // Engagement Letter
      form.append("engagementLetter", selectedFile);

      // If ResourceSpecific, send resourceSpecificPrice as JSON
      if (radioValue === "ResourceSpecific") {
        form.append(
          "resourceSpecificPrice",
          JSON.stringify(resourceSpecificPrices)
        );
      }

      // MULTIPLE ADVANCES: Convert advanceInvoices array to JSON
      // (This replaces the single "advanceAmount")
      if (advanceInvoices && advanceInvoices.length > 0) {
        form.append("advanceInvoices", JSON.stringify(advanceInvoices));
      }

      // Submit to server
      const response = await axiosInstance.post(`/matter/createNew`, form);
      if (response.data) {
        successAlert("Matter Added Successfully!");
        dispatch(fetchAllMatters());

        // Reset form
        setFormData({
          name: "",
          client: "",
          currency: "",
          pricePerHour: "",
          matterOpenDate: formatDate(new Date()),
          matterAddress: "",
          engagementLetter: [],
          flatFeesAmount: "",
          matterType: "",
        });
        setResourceSpecificPrices([]);
        setDesignationPrices({});
        setDesignationApplied(false);
        setNewResource("");
        setNewResourcePrice("");
        setSelectedFile([]);
        // Reset advances
        setAdvanceInvoices([]);
        setAdvanceAmount("");
        setAdvanceNote("");
        setAdvanceDate(formatDate(new Date()));

        onClose();
      }
    } catch (error) {
      errorAlert("Error occurred while adding the matter.");
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Validate
  const isValidForm = () => {
    if (
      !formData.name ||
      !formData.client ||
      !formData.currency ||
      !formData.matterOpenDate
    ) {
      errorAlert(
        "Please fill all required fields: name, client, currency, date."
      );
      return false;
    }
    if (radioValue === "ResourceSpecific") {
      if (!isResourceWise && Object.keys(designationPrices).length === 0) {
        errorAlert("Please assign prices to designations.");
        return false;
      }
      if (isResourceWise && resourceSpecificPrices.length === 0) {
        errorAlert("Please assign prices to resources.");
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <ToastContainer />
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          overflow: "hidden",
          marginBottom: 4,
          padding: 5,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" align="center" marginBottom={3}>
          Add Matter
        </Typography>

        <Grid container spacing={3}>
          {/* Client */}
          <Grid item xs={12} sm={6}>
            <Select
              name="client"
              options={clientNames}
              value={clientNames.find(
                (option) => option.value === formData.client
              )}
              onChange={(e) => handleOnChangeText(e.value, "client")}
              isSearchable
              placeholder="Select Client *"
              styles={{
                control: (base) => ({
                  ...base,
                  height: "46px",
                  minHeight: "46px",
                }),
              }}
            />
          </Grid>

          {/* Matter Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Matter Name *"
              fullWidth
              value={formData.name}
              onChange={(e) => handleOnChangeText(e.target.value, "name")}
              inputProps={{ style: { height: "13px" } }}
            />
          </Grid>

          {/* Currency */}
          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              value={formData.currency}
              onChange={(e) => handleOnChangeText(e.target.value, "currency")}
              SelectProps={{ native: true }}
              inputProps={{ style: { height: "20px" } }}
            >
              <option value="">Select Currency *</option>
              {currencies &&
                currencies.map((curr) => (
                  <option key={curr} value={curr}>
                    {curr}
                  </option>
                ))}
            </TextField>
          </Grid>

          {/* File Upload */}
          <Grid item xs={12} sm={6}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "8px 12px",
                height: "56px",
              }}
            >
              <label
                htmlFor="upload-button"
                style={{
                  cursor: "pointer",
                  padding: "6px 12px",
                  backgroundColor: "#1976d2",
                  color: "white",
                  borderRadius: "4px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Upload Engagement Letter
              </label>
              <input
                id="upload-button"
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={handleFileUpload}
                disabled={uploading}
                style={{ display: "none" }}
              />
              {uploading && (
                <CircularProgress size={20} style={{ marginLeft: "10px" }} />
              )}
            </div>
            <div style={{ marginTop: "10px" }}>
              {selectedFile.map((file, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <a href={file} target="_blank" rel="noopener noreferrer">
                    Open File
                  </a>
                  <Button
                    onClick={() => handleRemoveFile(index)}
                    color="error"
                    variant="outlined"
                    size="small"
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </div>
          </Grid>

          {/* Pricing Method */}
          <Grid item xs={12}>
            <Typography variant="subtitle1">Rating Method</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant={
                    radioValue === "Price/hour" ? "contained" : "outlined"
                  }
                  fullWidth
                  onClick={() => setRadioValue("Price/hour")}
                >
                  Blended Rates / Hour
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant={
                    radioValue === "ResourceSpecific" ? "contained" : "outlined"
                  }
                  fullWidth
                  onClick={() => setRadioValue("ResourceSpecific")}
                >
                  Resource Specific Rates
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* If Blended Rates */}
          {radioValue === "Price/hour" && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Rates Per Hour *"
                value={formData.pricePerHour}
                onChange={(e) =>
                  handleOnChangeText(e.target.value, "pricePerHour")
                }
              />
            </Grid>
          )}

          {/* If Resource Specific */}
          {radioValue === "ResourceSpecific" && (
            <>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Rating Type</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      variant={isResourceWise ? "contained" : "outlined"}
                      fullWidth
                      onClick={() => handlePricingToggle(true)}
                    >
                      Resource-wise
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant={!isResourceWise ? "contained" : "outlined"}
                      fullWidth
                      onClick={() => handlePricingToggle(false)}
                    >
                      Designation-wise
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {/* Resource-wise */}
              {isResourceWise ? (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Select
                        options={filteredEmployees.map((employee) => ({
                          value: employee.label,
                          label: employee.label,
                        }))}
                        value={
                          newResource
                            ? { label: newResource, value: newResource }
                            : null
                        }
                        onChange={(selectedOption) =>
                          setNewResource(selectedOption.value)
                        }
                        placeholder="Select Resource"
                        isSearchable
                        styles={{
                          ...customStyles,
                          control: (base) => ({
                            ...base,
                            height: "46px",
                            minHeight: "46px",
                          }),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Rate"
                        fullWidth
                        type="number"
                        value={newResourcePrice}
                        onChange={(e) => setNewResourcePrice(e.target.value)}
                        onWheel={(e) => e.target.blur()} // Disables scroll on number input
                      />
                    </Grid>
                    <Grid item xs={2} alignSelf="center">
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleAddNewResource}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>

                  {resourceSpecificPrices.length > 0 && (
                    <div style={{ marginTop: "10px" }}>
                      <Typography variant="subtitle2">
                        Assigned Resources:
                      </Typography>
                      {resourceSpecificPrices.map((resource, index) => (
                        <Typography key={index} variant="body2">
                          {resource}
                        </Typography>
                      ))}
                    </div>
                  )}
                </Grid>
              ) : (
                // Designation-wise
                <Grid item xs={12}>
                  {billedDesignations.map((designation) => (
                    <div
                      key={designation}
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <label>{designation}: </label>
                      <TextField
                        type="number"
                        placeholder="Rate *"
                        value={designationPrices[designation] || ""}
                        onChange={(e) =>
                          handleDesignationPriceChange(
                            designation,
                            e.target.value
                          )
                        }
                        style={{ marginLeft: "10px", width: "200px" }}
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>
                  ))}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={applyDesignationPrices}
                    disabled={Object.keys(designationPrices).length === 0}
                  >
                    Apply Rates
                  </Button>

                  {/* Display assigned results */}
                  {designationApplied && resourceSpecificPrices.length > 0 && (
                    <div style={{ marginTop: "20px" }}>
                      <Typography variant="h6">Assigned Resources:</Typography>
                      {resourceSpecificPrices.map((resource, index) => (
                        <div key={index} style={{ marginBottom: "10px" }}>
                          <Typography variant="body2">{resource}</Typography>
                        </div>
                      ))}
                    </div>
                  )}
                </Grid>
              )}
            </>
          )}

          {/* Matter Type + Flat Fees */}
          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              value={formData.matterType}
              onChange={(e) => handleOnChangeText(e.target.value, "matterType")}
              SelectProps={{ native: true }}
            >
              <option value="">Select Matter Type *</option>
              <option value="timespent">Time Spent</option>
              <option value="flatfees">Fixed Fees</option>
            </TextField>
          </Grid>

          {formData.matterType === "flatfees" && (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={`Fixed Fees (${formData.currency}) *`}
                type="number"
                value={formData.flatFeesAmount}
                onChange={(e) =>
                  handleOnChangeText(e.target.value, "flatFeesAmount")
                }
                onWheel={(e) => e.target.blur()}
              />
            </Grid>
          )}

          {/* ---------------
              MULTIPLE ADVANCES
              --------------- */}
          <Grid item xs={12}>
            <Typography variant="h6">Advance(s)</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label={`Advance Amount (${formData.currency})`}
              type="number"
              value={advanceAmount}
              onChange={(e) => setAdvanceAmount(e.target.value)}
              onWheel={(e) => e.target.blur()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Note (optional)"
              value={advanceNote}
              onChange={(e) => setAdvanceNote(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Advance Date"
              type="date"
              value={advanceDate}
              onChange={(e) => setAdvanceDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={1} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddAdvance}
              fullWidth
              sx={{ minHeight: "100%" }}
            >
              Add
            </Button>
          </Grid>

          {/* Display the list of added advances */}
          {advanceInvoices.length > 0 && (
            <Grid item xs={12}>
              {advanceInvoices.map((adv, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body2">
                    Amount: {adv.amount}, Date: {adv.date}, Note: {adv.note}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => removeAdvanceItem(index)}
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </Grid>
          )}
          {/* End Multiple Advances */}

          {/* Matter Open Date & Address */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Matter Open Date *"
              type="date"
              value={formData.matterOpenDate}
              onChange={(e) =>
                handleOnChangeText(e.target.value, "matterOpenDate")
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Matter Address"
              multiline
              value={formData.matterAddress}
              onChange={(e) =>
                handleOnChangeText(e.target.value, "matterAddress")
              }
            />
          </Grid>

          {/* Submit / Cancel */}
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={onAddMatter}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant="outlined" fullWidth onClick={onClose}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default AddMatter;