import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import formatDate, {
  errorAlert,
  formatLetterDate,
  formatMonthDate,
  successAlert,
  customStyles,
  formatDatePrevious,
} from "../../utils/services";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  TextField,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";
import { Add, Comment, Delete, Download } from "@mui/icons-material";
import { BiMoney } from "react-icons/bi";
import axiosInstance from "../../helpers/axios";
import CustomButton from "../../components/ui/CustomButton";
import CustomTooltip from "../../components/ui/CustomTootltip";
import { useTSEmployees } from "../../helpers/hooks/useTSEmployees";
import NoDataFound from "../../components/NoDataFound";
import { COLORS } from "../../constants/theme";
import TableSkeletonLoader from "../../components/ui/TableSkeletonLoader";
import { MdOutlineFilterAlt } from "react-icons/md";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";
// import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { fetchCurrencySettings } from "../../actions/currencyActions";
import { fetchAllMatters } from "../../store/slices/matterSlice";

const Invoice = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const allCurrencyData = useSelector((state) => state.currency);
  const { matters, filteredMatters } = useSelector((state) => state.matter);

  useEffect(() => {
    dispatch(fetchAllMatters());
  }, [dispatch]);

  const [currencyData, setCurrencyData] = useState({
    nativeCurrencyName: "",
    nativeCurrencyCode: "",
    conversionRates: [],
  });

  useEffect(() => {
    setCurrencyData(currencyData);
  }, [currencyData]);

  // Fetch currency settings when the component mounts if not already loaded
  useEffect(() => {
    if (!allCurrencyData.nativeCurrencyName) {
      dispatch(fetchCurrencySettings());
    }
  }, [dispatch, allCurrencyData.nativeCurrencyName]);

  // Set currency data to local state for easy manipulation (optional)
  useEffect(() => {
    setCurrencyData(allCurrencyData);
  }, [allCurrencyData]);

  const selectedLawyer = "";

  //states
  const [client, setClient] = useState("");
  const [matter, setMatter] = useState(""); //matter id
  const [selectedMatterName, setSelectedMatterName] = useState(""); //matter name
  const [startSelectedDate, setStartSelectedDate] = useState(
    formatDate(new Date())
  );
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );
  const [loading, setLoading] = useState(true);
  const [filteredWorkHour, setFilteredWorkHour] = useState([]);

  //pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [opeWorkHours, setOpeWorkHours] = useState([]);

  //modal states
  const [ope, setOPE] = useState("0");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [raisedBy, setRaisedBy] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [filteredStatus, setFilteredStatus] = useState(null);
  const [raisedDate, setRaisedDate] = useState(formatDate(new Date()));
  const [collectDate, setCollectDate] = useState("");
  const [status, setStatus] = useState("pending");
  const [invoiceId, setInvoiceId] = useState("");
  const [remarks, setRemarks] = useState("");
  const [showModal, setShowModal] = useState(false);
  // const [invoiceFile, setInvoiceFile] = useState([]);
  const needFin = false;

  const { commonNames } = useTSEmployees(filteredWorkHour);

  //company states
  const [companyData, setCompanyData] = useState({
    companyName: "",
    companyEmail: "",
    companyAddress: "",
    companyLogo: null, // Assuming this could be a URL string or null if not available
    companyWebsite: "",
    companyAccountsEmail: "",
    companyCorrespondentBankName: "",
    companyCorrespondentSwiftCode: "",
    companyIntermediaryBankClearingCode: "",
    companyIntermediaryAccountNumber: "",
    companyBankSwiftCode: "",
    companyABAFEDNumber: "",
    companyBeneficiaryName: "",
    companyBeneficiaryAccountNumber: "",
    companyBeneficiaryBankName: "",
    companyIFSCCode: "",
    companyGSTIN: "",
    companySAC: "",
    companyPAN: "",
    companyLUT: "",
  });

  // State variables
  const [editingInvoice, setEditingInvoice] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [invoiceFileDeleted, setInvoiceFileDeleted] = useState(false);

  //TS download states
  const [downloadWithTS, setDownloadWithTS] = useState("withTS");
  const [invoiceType, setInvoiceType] = useState("timespent");
  const [matterType, setMatterType] = useState("timespent");
  const [discountAmount, setDiscountAmount] = useState("");

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [finalConversionRate, setFinalConversionRate] = useState(1);

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    handleRemoveInvoiceFile();
  };

  //constants
  const formattedStartDate = formatLetterDate(startSelectedDate);
  const formattedEndDate = formatLetterDate(endSelectedDate);
  const adminId = auth.user._id;
  let sendMail = false;
  let matterCurrency; //matter currency
  let matterAddress = "";
  let flatFeesAmount = 0;
  let advanceAmount = 0;

  const matterCurr = matters.find((m) => m._id === matter);

  if (matterCurr) {
    matterCurrency = matterCurr.currency + " ";
    matterAddress =
      matterCurr.matterAddress !== "" ? matterCurr.matterAddress : "";
    flatFeesAmount = matterCurr.flatFeesAmount.$numberDecimal;
    const latestInvoice =
      matterCurr?.advanceInvoices[matterCurr?.advanceInvoices.length - 1] || 0;
    advanceAmount = latestInvoice?.amount?.$numberDecimal || 0;
  } else {
    matterCurrency = "";
  }

  const finalCurrencyForOffShoreInvoice = currencyData.conversionRates
    ? currencyData.conversionRates.filter(
        (curr) => curr.currencyCode.trim() === matterCurrency.trim()
      )
    : [];

  let conversionRate = finalCurrencyForOffShoreInvoice[0]?.rate || 1;

  // console.log(
  //   "conversionRate",
  //   currencyData.nativeCurrencyCode,
  //   matterCurrency,
  //   conversionRate,
  //   ope,
  //   finalConversionRate
  // );

  //Generate invoice button
  const enableDownload =
    matter &&
    invoiceNo &&
    raisedDate &&
    startSelectedDate &&
    endSelectedDate &&
    raisedBy;

  //dropdowns
  const [anchorElDownload, setAnchorElDownload] = useState(null);

  const handleDownloadClick = (event, setAnchorEl) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownloadClose = (setAnchorEl) => {
    setAnchorEl(null);
  };

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRemoveInvoiceFile = async () => {
    const fileToRemove = editingInvoice.invoiceFile;

    try {
      await axiosInstance.delete(`/deleteEngagementLetter`, {
        data: { filePath: fileToRemove },
      });

      // Update the editingInvoice state to remove the invoiceFile
      setEditingInvoice({
        ...editingInvoice,
        invoiceFile: null,
      });
      // Mark that the invoice file has been deleted
      setInvoiceFileDeleted(true);

      successAlert(
        "Invoice file deleted successfully from DigitalOcean Spaces."
      );
    } catch (error) {
      console.error("Error deleting file:", error);
      errorAlert("Failed to delete invoice file. Please try again.");
    }
  };

  const handleFileUpload = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
    }
  };
  // filter function and Validation
  const isValidObjFieldForFilters = () => {
    if (startSelectedDate === "" || endSelectedDate === "") {
      return false;
    } else {
      return true;
    }
  };

  const isValidFilters = () => {
    //all fields must be filled
    if (!isValidObjFieldForFilters()) return errorAlert("Enter the dates!!");

    return true;
  };

  async function filterBetweenDates() {
    if (isValidFilters()) {
      setLoading(true);
      const response = await axiosInstance.get(
        `/workHour/filterWorkHourByUserId`,
        {
          params: {
            user: selectedLawyer,
            fromDate: startSelectedDate,
            toDate: endSelectedDate,
            matterId: matter,
            needFin,
            sendMail,
            adminId,
          },
        }
      );

      const opeResponse = await axiosInstance.get(`/expense/admin`, {
        params: {
          user: selectedLawyer,
          fromDate: startSelectedDate,
          toDate: endSelectedDate,
          matterId: matter,
          adminId,
        },
      });
      setOpeWorkHours(opeResponse.data.expenses);
      setFilteredWorkHour(response.data.workHours);
      setLoading(false);
    }
  }

  const fetchCompanyDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/company/details/${adminId}`);

      setCompanyData((prevData) => ({
        ...prevData,
        ...response.data,
      }));
    } catch (error) {
      errorAlert("Error in fetching company details!!");
    }
  }, [adminId]);

  // Common Table Data Calculations Start----------------------------------

  const link1Text = companyData.companyAccountsEmail;
  const link1URL = `mailto:${companyData.companyAccountsEmail}`;
  const link2Text = companyData.companyWebsite;
  const link2URL = companyData.companyWebsite;

  const doubleUsedTableColumns = [
    "Associate Name",
    "Total Time",
    "Billed Time",
    "Hourly Rate",
    "Total Amount",
  ];

  const doubleUsedTableData = [];

  let teamTotalHours = 0;
  let teamTotalBilledHours = 0;
  let teamTotalBilledAmount = 0;
  let persontotalHours = 0;
  let amount;

  // Filtering the data with matter, dates and resource name
  commonNames.forEach((name) => {
    let personWorkHours = 0;
    filteredWorkHour &&
      filteredWorkHour
        .filter((result) => result.user?.fullName === name)
        .forEach((result) => {
          const workHour = parseFloat(result.workHour);
          personWorkHours += workHour;
          // console.log(name, personWorkHours)
        });

    persontotalHours = parseFloat(personWorkHours);
    // console.log(name, persontotalHours)

    const newMatter = matters.find(
      (matter) => matter?.name.trim() === selectedMatterName.trim()
    );

    // console.log(newMatter);

    let price;
    if (newMatter) {
      if (newMatter.pricePerHour) {
        price = newMatter.pricePerHour.$numberDecimal;
      } else if (newMatter.resourceSpecificPrice.length !== 0) {
        const employeeEntry = newMatter.resourceSpecificPrice.find((entry) =>
          entry.includes(name)
        );
        // console.log(employeeEntry)

        if (employeeEntry) {
          const [, hours] = employeeEntry.split(":");
          price = hours.trim();
          // console.log("price", name, price);
        }
      } else {
        price = 0;
      }
    }

    if (price) {
      let personTotalBilledAmount = price * personWorkHours;
      teamTotalHours += persontotalHours;
      teamTotalBilledHours += personWorkHours;
      teamTotalBilledAmount += personTotalBilledAmount;

      const data1 = [
        name,
        persontotalHours.toFixed(2),
        personWorkHours.toFixed(2),
        matterCurrency + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        matterCurrency +
          personTotalBilledAmount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      ];

      // console.log( name,
      //   persontotalHours.toFixed(2),
      //   personWorkHours.toFixed(2),
      //   currency + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      //   currency +
      //     personTotalBilledAmount
      //       .toFixed(2)
      //       .toString()
      //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),)

      doubleUsedTableData.push(data1);
    }
  });

  amount = parseFloat(
    invoiceType === "advance"
      ? advanceAmount
      : matterType === "timespent"
      ? teamTotalBilledAmount
      : flatFeesAmount
  );

  // Add footer row
  const footerRow = [
    {
      content: "Total Hours",
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content: teamTotalHours.toFixed(2),
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content: teamTotalBilledHours.toFixed(2),
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content: "",
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content:
        matterCurrency +
        amount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
  ];

  doubleUsedTableData.push(footerRow);

  // Table Calculations End ---------------------------------------------------------

  // All Invoices pdf generation
  const generateDomesticInvoice = async () => {
    const doc = new jsPDF();

    //this header is different from others common header
    const pageWidth = doc.internal.pageSize.getWidth();
    const rightMargin = 15; // You can adjust the right margin as needed
    const logoDesiredHeight = 20; // Set the desired height for the logo
    const logoYPosition = 10; // Set the Y position for the logo
    const logoXPosition = 13; // Set the X position for the logo

    function addHeader() {
      // Set the font style, size, and position of the header text
      const fontSize = 8;

      doc.setFont("helvetica", "normal");
      doc.setFontSize(fontSize);

      // Add the image to the header
      // doc.addImage(companyData.companyLogo, "PNG", 15, 10, 70, 9);
      doc.addImage(
        companyData.companyLogo,
        "PNG",
        logoXPosition,
        logoYPosition,
        0,
        logoDesiredHeight
      );

      // right header
      doc.setTextColor(46, 115, 176);

      const link2Width = doc.getTextWidth(link2Text);
      const link2X = pageWidth - link2Width - rightMargin;
      doc.textWithLink(link2Text, link2X, 16, {
        url: link2URL,
        underline: true,
      });

      // Set link color to blue and add underline
      const link1Width = doc.getTextWidth(link1Text);
      const link1X = pageWidth - link1Width - rightMargin;
      doc.textWithLink(link1Text, link1X, 19, {
        url: link1URL,
        underline: true,
      });

      doc.setTextColor(0);

      // Calculate text width and position to align it right
      const addressWidth = doc.getTextWidth(companyData.companyAddress);
      const addressX = pageWidth - addressWidth - rightMargin;
      doc.text(companyData.companyAddress, addressX, 23);

      // doc.setTextColor(0);
      doc.setFont("helvetica", "bold");
      const gstWidth = doc.getTextWidth(`GSTIN: ${companyData.companyGSTIN}`);
      const gstX = pageWidth - gstWidth - rightMargin;

      doc.text(`GSTIN: ${companyData.companyGSTIN}`, gstX, 27);

      const sacWidth = doc.getTextWidth(`SAC : ${companyData.companySAC}`);
      const sacX = pageWidth - sacWidth - rightMargin;
      doc.text(`SAC : ${companyData.companySAC}`, sacX, 30);
      doc.setFont("helvetica", "normal");
    }

    const InvoicePage = "/assets/invoice.jpg";

    // Add the invoice page image to the PDF
    doc.addImage(InvoicePage, "JPEG", 0, 0, 210, 297);
    doc.addImage(
      companyData.companyLogo,
      "PNG",
      logoXPosition,
      logoYPosition,
      0,
      logoDesiredHeight
    );

    //Header Image

    //left top width height
    // Header Text
    const fontSize = 20;
    doc.setFontSize(fontSize);

    //center alignment width calculations
    const clientNameLength = (210 - doc.getTextWidth(client)) / 2;
    const matterNameLength = (210 - doc.getTextWidth(selectedMatterName)) / 2;
    const dateRangeLength =
      (210 -
        doc.getTextWidth(
          invoiceType === "advance"
            ? "Advance Invoice"
            : `${formattedStartDate} - ${formattedEndDate}`
        )) /
      2;

    //client name
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(20);
    doc.text(client, clientNameLength, 120);

    //matter name
    doc.setFontSize(20);
    doc.setFont("helvetica", "normal");
    doc.text(selectedMatterName, matterNameLength, 130);

    //horizontal line
    doc.setLineWidth(0.3);
    doc.setDrawColor(136, 106, 71);
    doc.line(40, 145, 180, 145);

    //date range
    doc.setFont("helvetica", "italic");
    doc.setTextColor(0, 0, 0); // Black color
    doc.text(
      invoiceType === "advance"
        ? "Advance Invoice"
        : `${formattedStartDate} - ${formattedEndDate}`,
      dateRangeLength,
      160
    );

    // -----------2nd Page -----------------

    doc.addPage();
    addHeader();

    // Client and Matter Address
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(client, 14, 45);
    doc.setFont("helvetica", "normal");

    let maxWidth = 100; // Maximum width of text before wrapping
    let lines = doc.splitTextToSize(matterAddress, maxWidth);
    let yPosition = 50;
    lines.forEach((line) => {
      doc.text(line, 15, yPosition);
      yPosition += 5;
    });

    // Additional Information
    doc.setFont("helvetica", "bold");
    doc.text("By Email Only", 15, yPosition + 3);
    doc.setFont("helvetica", "normal");
    doc.text(formatLetterDate(new Date()), 15, yPosition + 11);
    doc.text("Dear Sir / Ma'am", 15, yPosition + 19);
    doc.setFont("helvetica", "bold");
    doc.text(`${selectedMatterName}`, 14, yPosition + 27);
    doc.setFont("helvetica", "normal");
    if (invoiceType === "timespent") {
      doc.text(
        `Please find enclosed our invoice for legal services rendered for all the work done between ${formatLetterDate(
          startSelectedDate
        )}`,
        15,
        yPosition + 37
      );

      doc.text(
        `to ${formatLetterDate(
          endSelectedDate
        )} for ${selectedMatterName}. The time-spent reports enclosed are for your`,
        15,
        yPosition + 42
      );

      doc.text(
        "records. The payment details are mentioned therein.",
        15,
        yPosition + 47
      );
    }

    if (invoiceType === "advance") {
      doc.text(
        `Please find enclosed our invoice for the advance fee of ${matterCurrency}${amount} in connection to legal services for`,
        15,
        yPosition + 37
      );

      doc.text(
        `${selectedMatterName}. The payment details are mentioned therein.`,
        15,
        yPosition + 42
      );
    }

    doc.text(
      `We will be grateful if you would pay all local bank charges so that ${auth.user.fullName} receives the full invoiced amount.`,
      15,
      yPosition + 52
    );
    doc.text("We trust you find this to be in order.", 15, yPosition + 60);
    doc.text("Yours sincerely", 15, yPosition + 68);
    doc.text("Accounts Team", 15, yPosition + 73);
    doc.setFont("helvetica", "bold");
    doc.text(auth.user.fullName, 15, yPosition + 78);
    doc.setFont("helvetica", "normal");

    //------------- 3rd Page -------------------

    doc.addPage();
    addHeader();
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(
      `INVOICE FOR LEGAL SERVICES RENDERED (INVOICE NO. ${invoiceNo})`,
      15,
      40
    );
    doc.text(`- ${selectedMatterName}`, 15, 45);
    doc.setFont("helvetica", "normal");
    doc.text(
      "Fees for legal services rendered in connection with the above.",
      15,
      53
    );
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.text("Fees", 15, 60);
    doc.setFont("helvetica", "normal");
    doc.text(
      `${matterCurrency}${amount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
      15,
      68
    );

    doc.setFont("helvetica", "bold");
    doc.text("Disbursements", 15, 78);
    doc.setFont("helvetica", "normal");
    doc.text(
      `${matterCurrency} ${parseFloat(ope)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
      15,
      86
    );

    doc.setFont("helvetica", "bold");
    doc.text("Invoice Total", 15, 96);
    doc.setFont("helvetica", "normal");
    doc.text(
      `${matterCurrency}${(parseFloat(amount) + parseFloat(ope))
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
      15,
      104
    );
    doc.setFont("helvetica", "italic");
    doc.text(
      "Legal services provided by a firm of Advocates are subject to Goods and Service Tax (GST) on reverse–",
      15,
      114
    );
    doc.text(
      "charge basis. The obligation to pay GST is placed upon the service recipient, if applicable and is not included",
      15,
      119
    );
    doc.text(
      "in the memorandum of fees. The entire amount of GST on the above services is required to be paid by the ",
      15,
      124
    );
    doc.text(
      "service recipient on reverse charge basis as Notification No. 13/2017 Central Tax (Rate). ",
      15,
      129
    );

    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text("Payment Details", 15, 140);
    doc.setFontSize(10);

    doc.setFont("helvetica", "bold");
    doc.text("Correspondent Bank Name: ", 15, 150);

    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyCorrespondentBankName,
      20 + doc.getTextWidth("Correspondent Bank Name:"),
      150
    );
    doc.setFont("helvetica", "bold");
    doc.text("Correspondent Swift Code: ", 15, 158);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyCorrespondentSwiftCode,
      20 + doc.getTextWidth("Correspondent Swift Code:"),
      158
    );
    doc.setFont("helvetica", "bold");
    doc.text("Intermediary Bank Clearing Code: ", 15, 166);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyIntermediaryBankClearingCode,
      20 + doc.getTextWidth("Intermediary Bank Clearing Code:"),
      166
    );
    doc.setFont("helvetica", "bold");
    doc.text("Intermediary Account Number: ", 15, 174);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyIntermediaryAccountNumber,
      20 + doc.getTextWidth("Intermediary Account Number:"),
      174
    );
    doc.setFont("helvetica", "bold");
    doc.text("Bank Swift Code: ", 15, 182);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyBankSwiftCode,
      20 + doc.getTextWidth("Bank Swift Code:"),
      182
    );

    doc.setFont("helvetica", "bold");
    doc.text("ABA FED Number: ", 15, 190);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyABAFEDNumber,
      20 + doc.getTextWidth("ABA FED Number:"),
      190
    );

    doc.setFont("helvetica", "bold");
    doc.text("Beneficiary Name: ", 15, 198);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyBeneficiaryName,
      20 + doc.getTextWidth("Beneficiary Name:"),
      198
    );

    doc.setFont("helvetica", "bold");
    doc.text("Beneficiary Account Number: ", 15, 206);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyBeneficiaryAccountNumber,
      20 + doc.getTextWidth("Beneficiary Account Number:"),
      206
    );

    doc.setFont("helvetica", "bold");
    doc.text("Beneficiary Bank: ", 15, 214);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyBeneficiaryBankName,
      20 + doc.getTextWidth("Beneficiary Bank:"),
      214
    );

    doc.setFont("helvetica", "bold");
    doc.text("IFSC Code: ", 15, 222);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyIFSCCode,
      18 + doc.getTextWidth("IFSC Code:"),
      222
    );

    doc.setFont("helvetica", "bold");
    doc.text("GSTIN: ", 15, 230);
    doc.setFont("helvetica", "normal");
    doc.text(companyData.companyGSTIN, 18 + doc.getTextWidth("GSTIN:"), 230);

    doc.setFont("helvetica", "bold");
    doc.text("SAC: ", 15, 238);
    doc.setFont("helvetica", "normal");
    doc.text(companyData.companySAC, 18 + doc.getTextWidth("SAC:"), 238);

    doc.setFont("helvetica", "bold");
    doc.text("PAN: ", 15, 246);
    doc.setFont("helvetica", "normal");
    doc.text(companyData.companyPAN, 18 + doc.getTextWidth("PAN:"), 246);

    //-------4th Page -------------
    if (invoiceType === "timespent" && downloadWithTS === "withTS") {
      doc.addPage();
      addHeader();

      doc.setFont("helvetica", "bold");
      doc.setFontSize(13);
      doc.text("Summary of Time-Spent", 15, 40);

      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");

      // ----------------------------------Table Data  Start

      const startY = 55; // Starting Y-coordinate for the table

      doc.autoTable(doubleUsedTableColumns, doubleUsedTableData, {
        startY,
        theme: "grid",
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.1,

        didParseCell: function (data) {
          if (data.row.index === 0 && data.row.section === "head") {
            // Header row styles
            data.cell.styles.fillColor = [136, 106, 71];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.halign = "left"; // Align header text to the left
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.fontSize = 9;
          } else if (data.row.section === "body") {
            // Body row styles
            if (data.column.index !== 0) {
              // Right-align all columns except the first column
              data.cell.styles.halign = "right";
            }
          } else if (data.row.index === doubleUsedTableData.length - 1) {
            // Footer row align
            data.cell.styles.halign = "right"; // Align footer text to the right
            data.cell.styles.fillColor = [226, 213, 203];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.fontStyle = "bold";
          } else if (
            data.row.index === doubleUsedTableData.length - 1 &&
            data.row.section === "foot"
          ) {
            // Footer row styles
            data.cell.styles.fillColor = [226, 213, 203];
            data.cell.styles.textColor = [255, 255, 255];
          }

          // Set padding for table data cells
          if (
            data.row.section === "body" &&
            data.column.index === 0 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 1 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 2 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 3 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 4 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
        },
      });

      // -----------------------------------Table data End

      // Calculate the table height
      const tableHeight = doc.autoTable.previous.finalY - startY;

      doc.text("Time-spent accrued", 15, startY + tableHeight + 10);
      doc.text(
        matterCurrency +
          amount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        210 - doc.getTextWidth(`${matterCurrency} ${amount.toFixed(2)}`) - 15,
        startY + tableHeight + 10
      );
      doc.text(
        "Add: Out of pocket expenses and other costs, if any",
        15,
        startY + tableHeight + 18
      );

      doc.text(
        matterCurrency + parseFloat(ope).toFixed(2),
        210 -
          doc.getTextWidth(matterCurrency + parseFloat(ope).toFixed(2)) -
          15,
        startY + tableHeight + 18
      );

      doc.setFont("helvetica", "bold");
      doc.text("Total Amount Due", 15, startY + tableHeight + 26);
      doc.text(
        matterCurrency +
          (parseFloat(amount) + parseFloat(ope))
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        210 -
          doc.getTextWidth(
            matterCurrency +
              (parseFloat(amount) + parseFloat(ope))
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          ) -
          15,
        startY + tableHeight + 26
      );

      // ----------------5th Page --------------------

      doc.addPage();
      addHeader();

      let startY1 = 35;

      //Description Table for every resource
      commonNames.forEach((name, index) => {
        const personData =
          filteredWorkHour &&
          filteredWorkHour
            .filter((result) => result.user?.fullName === name)
            .sort((a, b) => new Date(a.workDate) - new Date(b.workDate)); // Sort the data by workDate in ascending order

        // Add table header row with person's name
        const headerRow = [
          {
            content: name,
            colSpan: 2,
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },
          {
            content: "Total Time",
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },

          {
            content: "Billed Time",
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },
        ];

        const tableRows = [headerRow]; // Initialize tableRows with the header row

        // Add individual data rows for the person
        personData.forEach((result) => {
          const data = [
            formatMonthDate(result.workDate),
            result.workDescription.trim(" "),
            result.workHour,
            result.workHour,
          ];
          tableRows.push(data);
        });

        // Calculate total time
        const totalTime = personData.reduce(
          (total, result) => total + parseFloat(result.workHour),
          0
        );

        // Add footer row with total time
        const footerRow = [
          {
            content: "Total Time",
            colSpan: 2,
            styles: {
              halign: "left",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
          {
            content: totalTime.toFixed(2),
            styles: {
              halign: "right",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
          {
            content: totalTime.toFixed(2),
            styles: {
              halign: "right",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
        ];
        tableRows.push(footerRow);

        // Remove empty rows and columns from tableRows
        const filteredTableRows = tableRows.filter((row) =>
          row.some((cell) => cell !== "")
        );

        // Generate table for the current person
        doc.autoTable({
          // columns: tableColumn,
          body: filteredTableRows,
          startY: startY1,
          theme: "grid",
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.1,
          didParseCell: function (data) {
            if (data.row.index === 0 && data.row.section === "head") {
              // Header row styles
              data.cell.styles.fillColor = [136, 106, 71];
              data.cell.styles.textColor = [255, 255, 255];
              data.cell.styles.halign = "left";
              data.cell.styles.valign = "left"; // Align the text vertically at the center
              data.cell.styles.fontStyle = "bold"; // Set the font style to bold
            } else if (
              data.row.index === filteredTableRows.length - 1 &&
              data.row.section === "body"
            ) {
              // Footer row styles
              data.cell.styles.fillColor = [97, 68, 58];
              data.cell.styles.textColor = [255, 255, 255];
              // data.cell.styles.halign = "right";
              data.cell.styles.fontStyle = "bold";
            } else if (data.row.section === "body") {
              // Body row styles
              if (data.column.index !== 1) {
                // Right-align all columns except the first column
                data.cell.styles.halign = "left";
              }
            }

            // Set padding for table data cells
            if (
              data.row.section === "body" &&
              data.column.index === 0 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 3,
              };
              data.cell.styles.fontSize = 9;
              data.cell.styles.halign = "left";
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
            if (
              data.row.section === "body" &&
              data.column.index === 1 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 4,
                bottom: 3,
                left: 4,
              };
              data.cell.styles.fontSize = 9;
              data.cell.styles.fontStyle = "times";
              data.cell.styles.halign = "left";
              data.cell.styles.cellWidth = 122; // Set the width to 100 units
              data.cell.styles.textColor = [0, 0, 0];
            }
            if (
              data.row.section === "body" &&
              data.column.index === 2 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 2,
              };
              data.cell.styles.halign = "right";
              data.cell.styles.fontSize = 9;
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
            if (
              data.row.section === "body" &&
              data.column.index === 3 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 2,
              };
              data.cell.styles.halign = "right";
              data.cell.styles.fontSize = 9;
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
          },
        });

        startY1 = doc.lastAutoTable.finalY + 10; // Update startY for the next table
      });
    }

    // doc.save(`${invoiceNo.slice(-3)}_${selectedMatterName}.pdf`);

    // Generate the PDF as a Blob
    const pdfFileName = `${invoiceNo.slice(-3)}_${selectedMatterName}.pdf`;
    const pdfBlob = doc.output("blob");

    // Create a Blob URL for downloading the PDF
    const pdfBlobUrl = URL.createObjectURL(pdfBlob);

    // Trigger the download in the browser
    const link = document.createElement("a");
    link.href = pdfBlobUrl;
    link.download = pdfFileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Optional: Revoke the Blob URL after download
    URL.revokeObjectURL(pdfBlobUrl);

    // Upload the PDF and get the URL
    const pdfURL = await uploadPDF(pdfBlob, pdfFileName);

    if (pdfURL) {
      return pdfURL;
    } else {
      throw new Error("Failed to upload PDF");
    }
  };

  const generateOffshoreInvoice = async () => {
    const doc = new jsPDF();

    //this header is different from others common header

    const pageWidth = doc.internal.pageSize.getWidth();
    const rightMargin = 15; // You can adjust the right margin as needed
    const logoDesiredHeight = 20; // Set the desired height for the logo
    const logoYPosition = 10; // Set the Y position for the logo
    const logoXPosition = 13; // Set the X position for the logo

    function addHeader() {
      // Set the font style, size, and position of the header text
      const fontSize = 8;

      doc.setFont("helvetica", "normal");
      doc.setFontSize(fontSize);

      // Add the image to the header
      // doc.addImage(companyData.companyLogo, "PNG", 15, 10, 70, 9);
      doc.addImage(
        companyData.companyLogo,
        "PNG",
        logoXPosition,
        logoYPosition,
        0,
        logoDesiredHeight
      );

      // right header
      doc.setTextColor(46, 115, 176);

      const link2Width = doc.getTextWidth(link2Text);
      const link2X = pageWidth - link2Width - rightMargin;
      doc.textWithLink(link2Text, link2X, 16, {
        url: link2URL,
        underline: true,
      });

      // Set link color to blue and add underline
      const link1Width = doc.getTextWidth(link1Text);
      const link1X = pageWidth - link1Width - rightMargin;
      doc.textWithLink(link1Text, link1X, 19, {
        url: link1URL,
        underline: true,
      });

      doc.setTextColor(0);

      // Calculate text width and position to align it right
      const addressWidth = doc.getTextWidth(companyData.companyAddress);
      const addressX = pageWidth - addressWidth - rightMargin;
      doc.text(companyData.companyAddress, addressX, 23);

      // doc.setTextColor(0);
      doc.setFont("helvetica", "bold");
      const gstWidth = doc.getTextWidth(`GSTIN: ${companyData.companyGSTIN}`);
      const gstX = pageWidth - gstWidth - rightMargin;

      doc.text(`GSTIN: ${companyData.companyGSTIN}`, gstX, 27);

      const sacWidth = doc.getTextWidth(`SAC : ${companyData.companySAC}`);
      const sacX = pageWidth - sacWidth - rightMargin;
      doc.text(`SAC : ${companyData.companySAC}`, sacX, 30);
      doc.setFont("helvetica", "normal");
    }

    const InvoicePage = "/assets/invoice.jpg";

    // Add the invoice page image to the PDF
    doc.addImage(InvoicePage, "JPEG", 0, 0, 210, 297);
    doc.addImage(
      companyData.companyLogo,
      "PNG",
      logoXPosition,
      logoYPosition,
      0,
      logoDesiredHeight
    );

    //Header Image
    //left top width height

    const fontSize = 20;
    doc.setFontSize(fontSize);

    //center alignment width calculations
    const clientNameLength = (210 - doc.getTextWidth(client)) / 2;
    const matterNameLength = (210 - doc.getTextWidth(selectedMatterName)) / 2;
    const dateRangeLength =
      (210 - doc.getTextWidth(`${formattedStartDate} - ${formattedEndDate}`)) /
      2;

    //client name
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(20);
    doc.text(client, clientNameLength, 120);

    //matter name
    doc.setFontSize(20);
    doc.setFont("helvetica", "normal");
    doc.text(selectedMatterName, matterNameLength, 130);

    //horizontal line
    doc.setLineWidth(0.3);
    doc.setDrawColor(136, 106, 71);
    doc.line(40, 145, 180, 145);

    //date range
    doc.setFont("helvetica", "italic");
    doc.setTextColor(0, 0, 0); // Black color
    doc.text(
      `${formattedStartDate} - ${formattedEndDate}`,
      dateRangeLength,
      160
    );

    // -----------2nd Page -----------------

    doc.addPage();
    addHeader();

    // Client and Matter Address
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(client, 14, 45);
    doc.setFont("helvetica", "normal");

    let maxWidth = 100; // Maximum width of text before wrapping
    let lines = doc.splitTextToSize(matterAddress, maxWidth);
    let yPosition = 50;
    lines.forEach((line) => {
      doc.text(line, 15, yPosition);
      yPosition += 5;
    });

    // Additional Information
    doc.setFont("helvetica", "bold");
    doc.text("By Email Only", 15, yPosition + 3);
    doc.setFont("helvetica", "normal");
    doc.text(formatLetterDate(new Date()), 15, yPosition + 11);
    doc.text("Dear Sir / Ma'am", 15, yPosition + 19);
    doc.setFont("helvetica", "bold");
    doc.text(`${selectedMatterName}`, 14, yPosition + 27);
    doc.setFont("helvetica", "normal");

    if (invoiceType === "timespent") {
      doc.text(
        `Please find enclosed our invoice for legal services rendered for all the work done between ${formatLetterDate(
          startSelectedDate
        )}`,
        15,
        yPosition + 37
      );

      doc.text(
        `to ${formatLetterDate(
          endSelectedDate
        )} for ${selectedMatterName}. The time-spent reports enclosed are for your`,
        15,
        yPosition + 42
      );

      doc.text(
        "records. The payment details are mentioned therein.",
        15,
        yPosition + 47
      );
    }

    if (invoiceType === "advance") {
      doc.text(
        `Please find enclosed our invoice for the advance fee of ${matterCurrency}${amount} in connection to legal services for`,
        15,
        yPosition + 37
      );

      doc.text(
        `${selectedMatterName}. The payment details are mentioned therein.`,
        15,
        yPosition + 42
      );
    }

    doc.text(
      `We will be grateful if you would pay all local bank charges so that ${auth.user.fullName} receives the full invoiced amount.`,
      15,
      yPosition + 52
    );
    doc.text("We trust you find this to be in order.", 15, yPosition + 60);
    doc.text("Yours sincerely", 15, yPosition + 68);
    doc.text("Accounts Team", 15, yPosition + 73);
    doc.setFont("helvetica", "bold");
    doc.text(auth.user.fullName, 15, yPosition + 78);
    doc.setFont("helvetica", "normal");

    //------------- 3rd Page -------------------

    doc.addPage();
    addHeader();
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(
      `INVOICE FOR LEGAL SERVICES RENDERED (INVOICE NO. ${invoiceNo})`,
      15,
      40
    );
    doc.text(`- ${selectedMatterName}`, 15, 45);
    doc.setFont("helvetica", "normal");
    doc.text(
      "Fees for legal services rendered in connection with the above.",
      15,
      58
    );
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.text("Fees", 15, 66);
    doc.setFont("helvetica", "normal");
    doc.text(
      `${matterCurrency}${amount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
      15,
      74
    );
    doc.setFont("helvetica", "bold");
    doc.text("Disbursements", 15, 82);
    doc.setFont("helvetica", "normal");
    doc.text(
      `${matterCurrency} ${parseFloat(ope / conversionRate)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
      15,
      90
    );
    doc.setFont("helvetica", "bold");
    doc.text("Invoice Total", 15, 98);
    doc.setFont("helvetica", "normal");
    doc.text(
      `${matterCurrency} ${(
        parseFloat(amount) + parseFloat(ope / conversionRate)
      )
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `,
      15,
      106
    );
    doc.setFont("helvetica", "italic");
    doc.text(
      "The Export Service Operations are Under Bond or Letter Of Undertaking Without Payment Of IGST ",
      15,
      120
    );
    doc.text(`– Reference no. ${companyData.companyLUT}`, 15, 125);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text("Payment Details", 15, 140);
    doc.setFontSize(10);

    doc.setFont("helvetica", "bold");
    doc.text("Correspondent Bank Name: ", 15, 150);

    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyCorrespondentBankName,
      20 + doc.getTextWidth("Correspondent Bank Name:"),
      150
    );
    doc.setFont("helvetica", "bold");
    doc.text("Correspondent Swift Code: ", 15, 158);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyCorrespondentSwiftCode,
      20 + doc.getTextWidth("Correspondent Swift Code:"),
      158
    );

    doc.setFont("helvetica", "bold");
    doc.text("Intermediary Bank Clearing Code: ", 15, 166);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyIntermediaryBankClearingCode,
      20 + doc.getTextWidth("Intermediary Bank Clearing Code:"),
      166
    );

    doc.setFont("helvetica", "bold");
    doc.text("Intermediary Account Number: ", 15, 174);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyIntermediaryAccountNumber,
      20 + doc.getTextWidth("Intermediary Account Number:"),
      174
    );

    doc.setFont("helvetica", "bold");
    doc.text("Bank Swift Code: ", 15, 182);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyBankSwiftCode,
      20 + doc.getTextWidth("Bank Swift Code:"),
      182
    );

    doc.setFont("helvetica", "bold");
    doc.text("ABA FED Number: ", 15, 190);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyABAFEDNumber,
      20 + doc.getTextWidth("ABA FED Number:"),
      190
    );

    doc.setFont("helvetica", "bold");
    doc.text("Beneficiary Name: ", 15, 198);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyBeneficiaryName,
      20 + doc.getTextWidth("Beneficiary Name:"),
      198
    );

    doc.setFont("helvetica", "bold");
    doc.text("Beneficiary Account Number: ", 15, 206);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyBeneficiaryAccountNumber,
      20 + doc.getTextWidth("Beneficiary Account Number:"),
      206
    );

    doc.setFont("helvetica", "bold");
    doc.text("Beneficiary Bank: ", 15, 214);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyBeneficiaryBankName,
      20 + doc.getTextWidth("Beneficiary Bank:"),
      214
    );

    doc.setFont("helvetica", "bold");
    doc.text("IFSC Code: ", 15, 222);
    doc.setFont("helvetica", "normal");
    doc.text(
      companyData.companyIFSCCode,
      18 + doc.getTextWidth("IFSC Code:"),
      222
    );

    doc.setFont("helvetica", "bold");
    doc.text("GSTIN: ", 15, 230);
    doc.setFont("helvetica", "normal");
    doc.text(companyData.companyGSTIN, 18 + doc.getTextWidth("GSTIN:"), 230);

    doc.setFont("helvetica", "bold");
    doc.text("SAC: ", 15, 238);
    doc.setFont("helvetica", "normal");
    doc.text(companyData.companySAC, 18 + doc.getTextWidth("SAC:"), 238);

    doc.setFont("helvetica", "bold");
    doc.text("PAN: ", 15, 246);
    doc.setFont("helvetica", "normal");
    doc.text(companyData.companyPAN, 18 + doc.getTextWidth("PAN:"), 246);

    //-------4th Page -------------

    if (invoiceType === "timespent" && downloadWithTS === "withTS") {
      doc.addPage();
      addHeader();

      doc.setFont("helvetica", "bold");
      doc.setFontSize(13);
      doc.text("Summary of Time-Spent", 15, 40);

      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");

      // ----------------------------------Table Data  Start

      doc.setFont("helvetica", "bold");
      doc.setFontSize(13);
      doc.text("Summary of Time-Spent", 15, 40);

      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");

      const startY = 55; // Starting Y-coordinate for the table

      doc.autoTable(doubleUsedTableColumns, doubleUsedTableData, {
        startY,
        theme: "grid",
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.1,

        didParseCell: function (data) {
          if (data.row.index === 0 && data.row.section === "head") {
            // Header row styles
            data.cell.styles.fillColor = [136, 106, 71];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.halign = "left"; // Align header text to the left
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.fontSize = 9;
          } else if (data.row.section === "body") {
            // Body row styles
            if (data.column.index !== 0) {
              // Right-align all columns except the first column
              data.cell.styles.halign = "right";
            }
          } else if (data.row.index === doubleUsedTableData.length - 1) {
            // Footer row align
            data.cell.styles.halign = "right"; // Align footer text to the right
            data.cell.styles.fillColor = [226, 213, 203];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.fontStyle = "bold";
          } else if (
            data.row.index === doubleUsedTableData.length - 1 &&
            data.row.section === "foot"
          ) {
            // Footer row styles
            data.cell.styles.fillColor = [226, 213, 203];
            data.cell.styles.textColor = [255, 255, 255];
          }

          // Set padding for table data cells
          if (
            data.row.section === "body" &&
            data.column.index === 0 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 1 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 2 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 3 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 4 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
        },
      });

      // -----------------------------------Table data End

      // Calculate the table height
      const tableHeight = doc.autoTable.previous.finalY - startY;

      doc.text("Time-spent accrued", 15, startY + tableHeight + 10);
      doc.text(
        matterCurrency +
          parseFloat(amount)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        210 -
          doc.getTextWidth(
            matterCurrency +
              parseFloat(amount)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          ) -
          15,
        startY + tableHeight + 10
      );
      doc.text(
        "Add: Out of pocket expenses and other costs, if any",
        15,
        startY + tableHeight + 18
      );
      doc.text(
        matterCurrency + parseFloat(ope / conversionRate).toFixed(2),
        210 -
          doc.getTextWidth(
            matterCurrency + parseFloat(ope / conversionRate).toFixed(2)
          ) -
          15,
        startY + tableHeight + 18
      );

      doc.setFont("helvetica", "bold");
      doc.text("Total Amount Due", 15, startY + tableHeight + 26);
      doc.text(
        matterCurrency +
          (parseFloat(amount) + parseFloat(ope / conversionRate))
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        210 -
          doc.getTextWidth(
            matterCurrency +
              (parseFloat(amount) + parseFloat(ope / conversionRate))
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          ) -
          15,
        startY + tableHeight + 26
      );

      // ----------------5th Page --------------------

      doc.addPage();
      addHeader();

      let startY1 = 35;

      //Description Table for every resource
      commonNames.forEach((name, index) => {
        const personData =
          filteredWorkHour &&
          filteredWorkHour
            .filter((result) => result.user?.fullName === name)
            .sort((a, b) => new Date(a.workDate) - new Date(b.workDate)); // Sort the data by workDate in ascending order

        // Add table header row with person's name
        const headerRow = [
          {
            content: name,
            colSpan: 2,
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },
          {
            content: "Total Time",
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },

          {
            content: "Billed Time",
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },
        ];

        const tableRows = [headerRow]; // Initialize tableRows with the header row

        // Add individual data rows for the person
        personData.forEach((result) => {
          const data = [
            formatMonthDate(result.workDate),
            result.workDescription,
            result.workHour,
            result.workHour,
          ];
          tableRows.push(data);
        });

        // Calculate total time
        const totalTime = personData.reduce(
          (total, result) => total + parseFloat(result.workHour),
          0
        );

        // Add footer row with total time
        const footerRow = [
          {
            content: "Total Time",
            colSpan: 2,
            styles: {
              halign: "left",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
          {
            content: totalTime.toFixed(2),
            styles: {
              halign: "right",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
          {
            content: totalTime.toFixed(2),
            styles: {
              halign: "right",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
        ];
        tableRows.push(footerRow);

        // Remove empty rows and columns from tableRows
        const filteredTableRows = tableRows.filter((row) =>
          row.some((cell) => cell !== "")
        );

        // Generate table for the current person
        doc.autoTable({
          // columns: tableColumn,
          body: filteredTableRows,
          startY: startY1,
          theme: "grid",
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.1,
          didParseCell: function (data) {
            if (data.row.index === 0 && data.row.section === "head") {
              // Header row styles
              data.cell.styles.fillColor = [136, 106, 71];
              data.cell.styles.textColor = [255, 255, 255];
              data.cell.styles.halign = "left";
              data.cell.styles.valign = "left"; // Align the text vertically at the center
              data.cell.styles.fontStyle = "bold"; // Set the font style to bold
            } else if (
              data.row.index === filteredTableRows.length - 1 &&
              data.row.section === "body"
            ) {
              // Footer row styles
              data.cell.styles.fillColor = [97, 68, 58];
              data.cell.styles.textColor = [255, 255, 255];
              // data.cell.styles.halign = "right";
              data.cell.styles.fontStyle = "bold";
            } else if (data.row.section === "body") {
              // Body row styles
              if (data.column.index !== 1) {
                // Right-align all columns except the first column
                data.cell.styles.halign = "left";
              }
            }

            // Set padding for table data cells
            if (
              data.row.section === "body" &&
              data.column.index === 0 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 3,
              };
              data.cell.styles.fontSize = 9;
              data.cell.styles.halign = "left";
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
            if (
              data.row.section === "body" &&
              data.column.index === 1 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 4,
                bottom: 3,
                left: 4,
              };
              data.cell.styles.fontSize = 9;
              data.cell.styles.fontStyle = "times";
              data.cell.styles.halign = "left";
              data.cell.styles.cellWidth = 122; // Set the width to 100 units
              data.cell.styles.textColor = [0, 0, 0];
            }
            if (
              data.row.section === "body" &&
              data.column.index === 2 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 2,
              };
              data.cell.styles.halign = "right";
              data.cell.styles.fontSize = 9;
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
            if (
              data.row.section === "body" &&
              data.column.index === 3 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 2,
              };
              data.cell.styles.halign = "right";
              data.cell.styles.fontSize = 9;
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
          },
        });

        startY1 = doc.lastAutoTable.finalY + 10; // Update startY for the next table
      });
    }

    // doc.save(`${invoiceNo.slice(-3)}_${selectedMatterName}.pdf`);
    // Generate the PDF as a Blob
    const pdfFileName = `${invoiceNo.slice(-3)}_${selectedMatterName}.pdf`;
    const pdfBlob = doc.output("blob");

    // Create a Blob URL for downloading the PDF
    const pdfBlobUrl = URL.createObjectURL(pdfBlob);

    // Trigger the download in the browser
    const link = document.createElement("a");
    link.href = pdfBlobUrl;
    link.download = pdfFileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Optional: Revoke the Blob URL after download
    URL.revokeObjectURL(pdfBlobUrl);

    // Upload the PDF and get the URL
    const pdfURL = await uploadPDF(pdfBlob, pdfFileName);

    if (pdfURL) {
      return pdfURL;
    } else {
      throw new Error("Failed to upload PDF");
    }
  };

  //Upload pdf file in digital ocean spaces
  const uploadPDF = async (pdfBlob, pdfFileName) => {
    const formData = new FormData();
    formData.append("file", pdfBlob, pdfFileName);
    formData.append("adminId", auth.user._id);

    try {
      const response = await axiosInstance.post(`/uploadInvoice`, formData);

      const data = await response.data;
      if (!response.data) {
        throw new Error(data.message || "Failed to upload PDF");
      }
      // The uploaded file URL
      return data.url;
    } catch (error) {
      console.error("Error uploading PDF:", error);
      errorAlert("Error uploading PDF");
      return null;
    }
  };

  //Invoices report
  const generateInvoicesReport = () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });

    const invoiceTableColumns = [
      "Raised Date",
      "Collection Date",
      "Invoice No.",
      "Invoice Period",
      "Matter",
      "Amount",
      "Status",
    ];

    // Separate invoices into pending and collected
    // Assuming startSelectedDate and endSelectedDate are either valid dates or undefined/null.
    const filterInvoices = (
      status,
      invoices,
      startSelectedDate,
      endSelectedDate
    ) => {
      return invoices.filter((invoice) => {
        const statusMatch = invoice.status === status;
        const dateRangeMatch =
          !startSelectedDate ||
          !endSelectedDate ||
          (invoice.startDate >= startSelectedDate &&
            invoice.endDate <= endSelectedDate);

        return statusMatch && dateRangeMatch;
      });
    };

    const pendingInvoices = filterInvoices(
      "pending",
      invoices,
      startSelectedDate,
      endSelectedDate
    );
    const collectedInvoices = filterInvoices(
      "collected",
      invoices,
      startSelectedDate,
      endSelectedDate
    );

    // Function to format invoice data into table rows
    const formatInvoiceData = (invoiceList) => {
      return invoiceList.map((invoice) => [
        formatMonthDate(invoice.invoiceDate),
        invoice.collectionDate
          ? formatMonthDate(invoice.collectionDate)
          : "Not yet collected",
        invoice.invoiceNumber,
        `${formatMonthDate(invoice.startDate)} - ${formatMonthDate(
          invoice.endDate
        )}`,
        invoice.matter.name,
        invoice.amount.$numberDecimal,
        invoice.status,
      ]);
    };

    // Function to calculate total amount
    const calculateTotalAmount = (invoiceList) => {
      return invoiceList.reduce(
        (total, invoice) => parseFloat(invoice.amount.$numberDecimal) + total,
        0
      );
    };

    // Formatting data for pending and collected invoices
    const pendingInvoiceData = formatInvoiceData(pendingInvoices);
    const collectedInvoiceData = formatInvoiceData(collectedInvoices);

    // Adding footer row for total amount
    const pendingInvoiceFooter = [
      { content: "Total Amount", colSpan: 5, styles: { halign: "right" } },
      calculateTotalAmount(pendingInvoices),
      "",
    ];

    const collectedInvoiceFooter = [
      { content: "Total Amount", colSpan: 5, styles: { halign: "right" } },
      calculateTotalAmount(collectedInvoices),
      "",
    ];

    pendingInvoiceData.push(pendingInvoiceFooter);
    collectedInvoiceData.push(collectedInvoiceFooter);

    // Document Title
    doc.setFontSize(25);
    doc.setFont("helvetica", "bold");
    doc.text("Invoices Report", 120, 20);

    // Date Range Title
    doc.setFont("helvetica", "underline");
    doc.setFontSize(15);
    if (startSelectedDate !== "") {
      doc.text(
        `${formattedStartDate}` +
          (startSelectedDate !== endSelectedDate
            ? ` - ${formattedEndDate}`
            : ""),
        (297 -
          doc.getTextWidth(
            `${formattedStartDate}` +
              (startSelectedDate !== endSelectedDate
                ? ` - ${formattedEndDate}`
                : "")
          )) /
          2,
        30
      );
    }

    // Adding Pending Invoices Table
    doc.setFontSize(13);
    doc.text("Pending Invoices", 14, 40);
    doc.autoTable(invoiceTableColumns, pendingInvoiceData, {
      startY: 45,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      didParseCell: (data) => customizeCellStyles(data),
    });

    // Adding Collected Invoices Table
    let currentY = doc.lastAutoTable.finalY + 10; // Get the Y position after the first table
    doc.setFontSize(13);
    doc.text("Collected Invoices", 14, currentY);
    doc.autoTable(invoiceTableColumns, collectedInvoiceData, {
      startY: currentY + 5,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      didParseCell: (data) => customizeCellStyles(data),
    });

    doc.save("Invoices.pdf");
  };

  // Custom function to adjust cell styles
  function customizeCellStyles(data) {
    if (data.row.index === 0 && data.row.section === "head") {
      data.cell.styles.fillColor = [136, 106, 71];
      data.cell.styles.textColor = [255, 255, 255];
    }
    if (
      data.row.index === data.table.body.length - 1 &&
      data.row.section === "body"
    ) {
      data.cell.styles.fillColor = [97, 68, 58];
      data.cell.styles.textColor = [255, 255, 255];
      data.cell.styles.fontStyle = "bold";
    }
    if (data.row.section === "body" && data.column.index === 5) {
      data.cell.styles.halign = "right";
      data.cell.styles.cellWidth = 35;
    }
  }

  //CRUD opeartions start here ----------------------------

  //GET
  const fetchAllInvoices = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/invoices`, {
        params: {
          limit: rowsPerPage,
          page,
        },
      });

      if (response.data) {
        setInvoices(response.data.invoices);
        setTotalInvoices(response.data.totalInvoices);
        setLoading(false);
        // console.log("Invoices data------", invoices);
      }
    } catch (error) {
      setLoading(false);
      errorAlert("Error getting invoices");
      console.error("Error fetching invoices:", error.response.data);
    }
  }, [page, rowsPerPage]);

  const totalAmount =
    // invoiceType === "advance" ? amount :
    amount * (finalConversionRate || 1);

  // console.log("totalAmount", totalAmount);

  const handleSubmit = async (pdfURL) => {
    try {
      const invoiceData = {
        admin: auth.user._id,
        matter,
        invoiceNumber: invoiceNo,
        invoiceDate: raisedDate,
        collectionDate: collectDate,
        startDate: startSelectedDate,
        endDate: endSelectedDate,
        amount: totalAmount.toFixed(2),
        discountAmount: parseFloat(discountAmount || 0).toFixed(2),
        status: status,
        raisedBy,
        remarks,
        invoiceFile: pdfURL, // Include the PDF URL here
        invoiceType,
      };

      // console.log("invoiceData", invoiceData);

      if (invoiceData.invoiceDate === "" || invoiceData.invoiceNumber === "") {
        errorAlert("Enter both raised invoice date and invoice number");
      } else {
        const response = await axiosInstance.post(`/invoices`, invoiceData);
        if (response.data) {
          successAlert("Invoice created successfully!");
          fetchAllInvoices();
          closeModal();
        }
      }
    } catch (error) {
      console.error("Error creating invoice:", error.response.data);
      errorAlert("Error creating invoice", error.response.data);
    }
  };

  //PUT
  const openModal = (invoice) => {
    if (invoice) {
      setEditingInvoice(invoice);
      setInvoiceId(invoice._id);
      setRaisedDate(formatDate(invoice.invoiceDate));
      setCollectDate(
        invoice.collectionDate !== null
          ? formatDate(invoice.collectionDate)
          : ""
      );
      setInvoiceType(invoice.invoiceType);
      setInvoiceNo(invoice.invoiceNumber);
      setStatus(invoice.status);
      setRaisedBy(invoice.raisedBy);
      setRemarks(invoice.remarks);
      // Initialize state for file management
      setInvoiceFileDeleted(false);
      setSelectedFile(null);
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditingInvoice(null);
    setInvoiceId("");
    setRaisedDate(formatDate(new Date()));
    setCollectDate("");
    setInvoiceNo("");
    setStatus("pending");
    // setDollarPrice("");
    setDiscountAmount("");
    setDownloadWithTS("withTS");
    setMatterType("timespent");
    setRaisedBy("");
    setRemarks("");
    setInvoiceFileDeleted(false);
    setSelectedFile(null);
  };

  const handleEditInvoice = async (e) => {
    e.preventDefault();

    let pdfURL = editingInvoice.invoiceFile; // Use existing file URL

    // If the existing invoice file was deleted
    if (invoiceFileDeleted) {
      pdfURL = null; // Set to null since the file was deleted
    }

    // If a new file is selected
    if (selectedFile) {
      const pdfBlob = selectedFile;
      const pdfFileName = `${invoiceNo.slice(-3)}_${selectedMatterName}.pdf`;
      pdfURL = await uploadPDF(pdfBlob, pdfFileName);
    }

    const formData = {
      admin: auth.user._id,
      invoiceNumber: invoiceNo,
      invoiceDate: raisedDate,
      collectionDate: collectDate,
      status: status,
      invoiceFile: pdfURL, // Use the updated or existing URL
      raisedBy,
      remarks,
      invoiceType,
    };

    console.log(formData);

    try {
      const response = await axiosInstance.put(
        `/invoices/${invoiceId}`,
        formData
      );
      if (response.data) {
        successAlert("Invoice updated successfully!");
        fetchAllInvoices();
      }
      closeModal();
    } catch (error) {
      console.error("Error:", error);
      errorAlert("Error in updating invoice", error);
    }
  };

  //Filter for UI
  const handleFilterChange = (status) => {
    setFilteredStatus(status);
  };

  const filteredInvoices = filteredStatus
    ? invoices.filter((invoice) => invoice.status === filteredStatus)
    : invoices;

  //DELETE
  // const handleInvoice = (id) => {
  //   // console.log(id);
  //   toast(
  //     <ConfirmationAlert
  //       onDeleteOff={() => onDeleteInvoice(id)}
  //       question={"Are you sure to delete this invoice?"}
  //       answer={"Yes, Delete it"}
  //       icon={<RiDeleteBin6Line />}
  //     />,
  //     {
  //       position: toast.POSITION.TOP_CENTER,
  //       closeButton: true,
  //       autoClose: false,
  //       draggable: false,
  //     }
  //   );
  // };

  // const onDeleteInvoice = async (invoiceId) => {
  //   await axiosInstance
  //     .delete(`$/invoices/${invoiceId}`)
  //     .then((response) => {
  //       // console.log(response.data);
  //       if (response.data) {
  //         fetchAllInvoices();
  //         successAlert("Invoice Deleted Successfully!");
  //       }
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //       errorAlert("Deletion of invoice failed", "Please try again...");
  //     });
  // };

  const columns = [
    "Raised Date",
    "Raised By",
    "Matter",
    "Invoice No",
    "Invoice Period",
    `Total Invoiced (${currencyData.nativeCurrencyCode})`,
    "W/O",
    "Status",
    "Collection Date",
    "Upload Invoice",
    "Remarks",
    "Edit",
    // "Delete",
  ];

  //Filter function and states
  const [anchorEl, setAnchorEl] = useState(null);

  // Function to handle the opening of the menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle the closing of the menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // State for the search input
  const [searchTerm, setSearchTerm] = useState("");

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter invoices based on the search term
  const filteredInvoicesBySearch = filteredInvoices.filter((invoice) =>
    invoice.matter?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Add event listener to close modal on outside click
  // useEffect(() => {
  //   if (showModal) {
  //     document.addEventListener("click", handleOutsideClick);
  //   } else {
  //     document.removeEventListener("click", handleOutsideClick);
  //   }

  //   // Cleanup event listener
  //   return () => {
  //     document.removeEventListener("click", handleOutsideClick);
  //   };
  // }, [showModal]);

  // Close modal when clicking outside of it
  // const handleOutsideClick = (e) => {
  //   if (e.target.className === "modal") {
  //     setShowModal(false);
  //   }
  // };

  // states and functions for date range picker
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  // Function to receive selected dates from DateRangePickerTst
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setStartSelectedDate(startDate);
    setEndSelectedDate(endDate);
    // console.log(startDate, endDate);
  };

  useEffect(() => {
    if (startSelectedDate && endSelectedDate && matter) {
      filterBetweenDates();
    }
  }, [matter, startSelectedDate, endSelectedDate]);

  useEffect(() => {
    setFinalConversionRate(conversionRate);
  }, [matter]);

  //useEffects
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setLoading(false);
    };
    loadData();
    fetchCompanyDetails();
    dispatch(fetchCurrencySettings());
  }, []);

  useEffect(() => {
    dispatch(fetchAllInvoices);
  }, [dispatch, fetchAllInvoices, page, rowsPerPage]);

  useEffect(() => {
    const opeAmountCalcs = opeWorkHours.reduce(
      (total, curr) => total + parseFloat(curr.amount.$numberDecimal),
      0
    );

    setOPE(opeAmountCalcs);
  }, [opeWorkHours]);

  const [openRemarksModal, setOpenRemarksModal] = useState(false);
  const [selectedRemarks, setSelectedRemarks] = useState("");

  const handleOpenModal = (address) => {
    setSelectedRemarks(address);
    setOpenRemarksModal(true);
  };

  const handleCloseModal = () => {
    setOpenRemarksModal(false);
    setSelectedRemarks("");
  };

  //CRUD Operations End here---------------------------

  return (
    <>
      <ToastContainer />

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this invoice file?
          </Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton
            variant="outlined"
            onClick={() => setOpenConfirmDialog(false)}
          >
            Cancel
          </CustomButton>
          <CustomButton onClick={handleConfirmDelete}>Delete</CustomButton>
        </DialogActions>
      </Dialog>

      <Grid container spacing={2} alignItems="baseline">
        <Grid item>
          <Typography variant="h5">Invoices</Typography>
        </Grid>
        <Grid item>
          <CustomTooltip text="Add Invoice">
            <CustomButton
              style={{
                borderRadius: "50%",
                minWidth: "5px",
                height: "30px",
                transition:
                  "transform 0.3s ease-in-out, background-color 0.3s ease",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
              onClick={() => openModal(null, "add")}
            >
              <Add />
            </CustomButton>
          </CustomTooltip>
        </Grid>
      </Grid>

      {/* Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <Typography variant="h5" align="center">
              {editingInvoice ? "Edit Invoice" : "Add Invoice"}
            </Typography>

            <form className="flex flex-col gap-3">
              {/* 1st Line Inputs  */}
              {!editingInvoice && (
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={3} md={3}>
                    Invoice Type *
                    <select
                      value={invoiceType}
                      onChange={(e) => setInvoiceType(e.target.value)}
                      style={{ height: "30px" }}
                      required
                    >
                      <option value="timespent">Timespent</option>
                      <option value="advance">Advance</option>
                    </select>
                  </Grid>

                  <Grid item xs={12} sm={7} md={5}>
                    Select Matter *
                    <Select
                      options={filteredMatters}
                      value={filteredMatters.find(
                        (option) =>
                          option.label === `${selectedMatterName} | ${client}`
                      )}
                      onChange={(e) => {
                        setMatter(e.value);
                        setSelectedMatterName(e.label.split("|")[0].trim());
                        setClient(e.label.split("|")[1]);
                        setFinalConversionRate(conversionRate);
                      }}
                      isSearchable
                      placeholder="Select Matter"
                      styles={customStyles}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={4}
                    display="flex"
                    alignItems="center"
                    gap={1}
                  >
                    <CustomTooltip text="Select Dates">
                      <CustomDateRangePicker
                        startDate={selectedDateRange.start}
                        endDate={selectedDateRange.end}
                        onDateRangeChange={handleDateRangeChange}
                        iconSize={30}
                        iconColor={COLORS.primary}
                      />
                    </CustomTooltip>
                    <Typography>
                      {formatMonthDate(startSelectedDate)} -{" "}
                      {formatMonthDate(endSelectedDate)}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {/* 2nd Line Inputs  */}
              {!editingInvoice && (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6} sm={6} md={6}>
                    {invoiceType === "timespent" &&
                      finalConversionRate !== 1 && (
                        <input
                          type="number"
                          placeholder="Dollar Price"
                          value={finalConversionRate}
                          onChange={(e) =>
                            setFinalConversionRate(e.target.value)
                          }
                          required
                        />
                      )}
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sm={5}
                    md={
                      invoiceType === "timespent" && finalConversionRate !== 1
                        ? 6
                        : 12
                    }
                  >
                    <CustomButton variant="outlined" style={{ width: "100%" }}>
                      <BiMoney
                        size={20}
                        style={{ marginRight: "8px", color: COLORS.primary }}
                      />
                      Total Amount : {matterCurrency}
                      {parseFloat(amount)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    </CustomButton>
                  </Grid>
                </Grid>
              )}

              {/* 3rd Line Inputs  */}
              {!editingInvoice && invoiceType === "timspent" && (
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6}>
                    <input
                      type="number"
                      placeholder="Discount Amount (optional)"
                      value={discountAmount}
                      onChange={(e) => setDiscountAmount(e.target.value)}
                    />
                  </Grid>
                </Grid>
              )}

              {/* 4th Line inputs  */}
              <Grid container spacing={2}>
                <Grid item xs={2} sm={2} md={6}>
                  Raised Date *
                  <input
                    style={{ width: "100%" }}
                    type="date"
                    className="date"
                    value={raisedDate}
                    onChange={(e) => setRaisedDate(e.target.value)}
                  />
                </Grid>

                <Grid item xs={2} sm={2} md={6}>
                  Invoice No *
                  <input
                    type="text"
                    // placeholder="Invoice Number *"
                    value={invoiceNo}
                    onChange={(e) => setInvoiceNo(e.target.value)}
                    required
                  />
                </Grid>
              </Grid>

              {/* 5th Line Inputs  */}
              {!editingInvoice && invoiceType !== "advance" && (
                <Grid container spacing={2}>
                  <Grid item xs={2} sm={2} md={6}>
                    Matter Type *
                    <select
                      value={matterType}
                      onChange={(e) => setMatterType(e.target.value)}
                      style={{ height: "37px" }}
                      required
                    >
                      <option value="timespent">TimeSpent</option>
                      <option value="flatfees">Flat Fees</option>
                    </select>
                  </Grid>

                  <Grid item xs={3} sm={3} md={6}>
                    Invoice Format *
                    <select
                      value={downloadWithTS}
                      onChange={(e) => setDownloadWithTS(e.target.value)}
                      style={{ height: "37px" }}
                      required
                    >
                      <option value="withTS">Download with TimeSpent</option>
                      <option value="withoutTS">
                        Download without Timespent
                      </option>
                    </select>
                  </Grid>
                </Grid>
              )}

              {/* Editing Inputs  */}
              {editingInvoice && (
                <Grid container spacing={2}>
                  <Grid item xs={2} sm={2} md={6}>
                    Collection Date:
                    <input
                      type="date"
                      value={collectDate}
                      onChange={(e) => setCollectDate(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={2} sm={2} md={6}>
                    Status :
                    <select
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      style={{ height: "30px" }}
                    >
                      <option value="pending">Pending</option>
                      <option value="collected">Collected</option>
                      <option value="cancelled">Cancelled</option>
                    </select>
                  </Grid>

                  {/* File Management Section */}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Invoice File:</Typography>
                    {editingInvoice.invoiceFile && !invoiceFileDeleted ? (
                      // Display existing invoice file with options to download and delete
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <a
                          href={editingInvoice.invoiceFile}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginRight: "10px" }}
                        >
                          <CustomButton
                            variant="contained"
                            style={{ width: "fit-content" }}
                            startIcon={<Download />}
                          >
                            Download
                          </CustomButton>
                        </a>
                        <CustomButton
                          variant="outlined"
                          style={{ width: "fit-content" }}
                          startIcon={<Delete />}
                          onClick={handleRemoveInvoiceFile}
                        >
                          Delete Invoice File
                        </CustomButton>
                      </div>
                    ) : (
                      // Provide option to upload a new file
                      <div>
                        <input
                          type="file"
                          accept="application/pdf"
                          onChange={handleFileUpload}
                          style={{ marginTop: "5px" }}
                        />
                        {selectedFile && (
                          <div style={{ marginTop: "10px" }}>
                            <Typography variant="body2">
                              {selectedFile.name}
                            </Typography>
                            <Button
                              variant="contained"
                              color="secondary"
                              startIcon={<Delete />}
                              onClick={() => setSelectedFile(null)}
                              style={{ marginTop: "5px" }}
                            >
                              Remove Selected File
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={2}>
                <Grid item xs={2} sm={2} md={6}>
                  Raised By *
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    className="raisedBy"
                    value={raisedBy}
                    onChange={(e) => setRaisedBy(e.target.value)}
                  />
                </Grid>

                <Grid item xs={2} sm={2} md={6}>
                  Remarks
                  <input
                    type="text"
                    // placeholder="Invoice Number *"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </Grid>
              </Grid>

              {editingInvoice && (
                <Grid item xs={3} sm={3} md={3}>
                  Invoice Type
                  <select
                    value={invoiceType}
                    onChange={(e) => setInvoiceType(e.target.value)}
                    style={{ height: "30px" }}
                  >
                    <option value="timespent">Timespent</option>
                    <option value="advance">Advance</option>
                  </select>
                </Grid>
              )}

              {/* Submit Buttons  */}
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                {editingInvoice && (
                  <Grid item xs={3} sm={3} md={6}>
                    <CustomButton
                      type="submit"
                      style={{ width: "100%", height: "35px" }}
                      onClick={handleEditInvoice}
                    >
                      Submit
                    </CustomButton>
                  </Grid>
                )}

                {!editingInvoice && (
                  <Grid item xs={3} sm={3} md={6}>
                    <CustomTooltip
                      text={
                        enableDownload
                          ? ""
                          : "Enter all required fields to generate invoice"
                      }
                    >
                      <CustomButton
                        style={{ width: "100%" }}
                        onClick={(event) => {
                          handleDownloadClick(event, setAnchorElDownload);
                        }}
                        disabled={!enableDownload}
                      >
                        <Download style={{ marginRight: "8px" }} />
                        Create and Download Invoice
                      </CustomButton>
                    </CustomTooltip>
                    <Menu
                      anchorEl={anchorElDownload}
                      open={Boolean(anchorElDownload)}
                      onClose={() => handleDownloadClose(setAnchorElDownload)}
                    >
                      <MenuItem
                        onClick={() => {
                          generateDomesticInvoice()
                            .then((pdfURL) => {
                              handleSubmit(pdfURL);
                            })
                            .catch((error) => {
                              errorAlert(
                                "Failed to generate and upload invoice"
                              );
                            });
                        }}
                      >
                        Domestic Invoice
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          generateOffshoreInvoice()
                            .then((pdfURL) => {
                              handleSubmit(pdfURL);
                            })
                            .catch((error) => {
                              errorAlert(
                                "Failed to generate and upload invoice"
                              );
                            });
                        }}
                      >
                        Offshore Invoice
                      </MenuItem>

                      <MenuItem onClick={() => generateInvoicesReport()}>
                        All Invoices
                      </MenuItem>
                    </Menu>
                  </Grid>
                )}

                <Grid item xs={3} sm={3} md={6}>
                  <CustomButton
                    variant="outlined"
                    style={{ width: "50%" }}
                    onClick={closeModal}
                  >
                    Cancel
                  </CustomButton>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      )}

      {/* Modal to display matter address */}
      <Dialog open={openRemarksModal} onClose={handleCloseModal}>
        <DialogTitle>Invoice Remarks</DialogTitle>
        <DialogContent>
          <Typography>{selectedRemarks}</Typography>
        </DialogContent>
      </Dialog>

      {/* Main Table  */}
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "10px" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="subtitle1"
            style={{ marginLeft: "10px", fontWeight: 500, fontStyle: "italic" }}
          >
            While manual intervention has been permitted, we strongly encourage
            you to use the automated features
          </Typography>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalInvoices}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>

        {/* Filter for invoices and search bar */}
        <div style={{ margin: "0 0 1rem 1rem" }}>
          <div className="flex items-center justify-center my-1 mx-auto">
            {/* Search Bar for Matter Name */}
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search by Matter Name"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{ marginRight: "1rem", width: "50%" }} // Space between search bar and filter icon
            />

            {/* Filter Icon */}
            <CustomTooltip text="Filter By Status">
              <IconButton
                onClick={handleClick}
                className="text-black hover:bg-gray-800"
              >
                <MdOutlineFilterAlt style={{ fontSize: "25px" }} />
              </IconButton>
            </CustomTooltip>

            {/* Dropdown Menu */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleFilterChange(null)}>All</MenuItem>
              <MenuItem onClick={() => handleFilterChange("pending")}>
                Pending
              </MenuItem>
              <MenuItem onClick={() => handleFilterChange("collected")}>
                Collected
              </MenuItem>
              <MenuItem onClick={() => handleFilterChange("cancelled")}>
                Cancelled
              </MenuItem>
            </Menu>
          </div>
        </div>

        <TableContainer sx={{ maxHeight: "59vh" }}>
          <Table aria-label="reusable table">
            <TableHead sx={{ backgroundColor: COLORS.primary2 }}>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={index} id="table-header">
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {loading ? (
              <TableSkeletonLoader rows={6} colSpan={13} />
            ) : filteredInvoicesBySearch.length === 0 ? (
              <TableRow>
                <TableCell colSpan={13}>
                  <NoDataFound message="Oops! No Invoices Found." />
                </TableCell>
              </TableRow>
            ) : (
              filteredInvoicesBySearch
                .sort(
                  (a, b) => new Date(b.invoiceDate) - new Date(a.invoiceDate)
                )
                .map((invoice, index) => (
                  <TableRow key={index} role="checkbox" tabIndex={-1}>
                    <TableCell>
                      {formatDatePrevious(invoice.invoiceDate)}
                    </TableCell>
                    <TableCell> {invoice.raisedBy}</TableCell>
                    <TableCell>{invoice.matter?.name}</TableCell>
                    <TableCell>{invoice.invoiceNumber}</TableCell>
                    <TableCell>
                      {formatMonthDate(invoice.startDate)} -{" "}
                      {formatMonthDate(invoice.endDate)}
                    </TableCell>
                    <TableCell>
                      {parseFloat(invoice.amount.$numberDecimal)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </TableCell>
                    <TableCell>
                      {parseFloat(invoice.discountAmount.$numberDecimal)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 600,
                        color:
                          invoice.status === "pending"
                            ? "orange"
                            : invoice.status === "collected"
                            ? "green"
                            : "red",
                      }}
                    >
                      {invoice.status === "pending"
                        ? "Pending"
                        : invoice.status === "collected"
                        ? "Collected"
                        : "Cancelled"}
                    </TableCell>
                    <TableCell>
                      {invoice?.collectionDate
                        ? formatDatePrevious(invoice.collectionDate)
                        : "Not yet collected"}
                    </TableCell>
                    <TableCell>
                      {invoice?.invoiceFile && (
                        <a
                          href={invoice.invoiceFile}
                          download
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <IconButton style={{ color: "black" }}>
                            <Download />
                          </IconButton>
                        </a>
                      )}
                    </TableCell>
                    <TableCell>
                      {invoice.remarks && (
                        <CustomTooltip text="View remarks">
                          <IconButton
                            onClick={() => handleOpenModal(invoice.remarks)}
                          >
                            <Comment />
                          </IconButton>
                        </CustomTooltip>
                      )}
                    </TableCell>
                    <TableCell className="table-cell">
                      <CiEdit
                        style={{ cursor: "pointer", fontSize: "17px" }}
                        onClick={() => openModal(invoice)}
                      />
                    </TableCell>
                    {/* <TableCell className="table-cell">
                      <RiDeleteBin6Line
                        style={{ cursor: "pointer" }}
                        onClick={() => handleInvoice(invoice._id)}
                      />
                    </TableCell> */}
                  </TableRow>
                ))
            )}

            <TableRow
              sx={{
                backgroundColor: COLORS.primary,
                color: "white",
              }}
            >
              <TableCell colSpan={5} style={{ textAlign: "center" }}>
                <b>Total Amount For All Invoices </b>
              </TableCell>
              <TableCell>
                <b>
                  {filteredInvoicesBySearch
                    .reduce(
                      (total, invoice) =>
                        parseFloat(invoice.amount.$numberDecimal) + total,
                      0
                    )
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </b>
              </TableCell>
              <TableCell colSpan={5}>
                <b>
                  {filteredInvoicesBySearch
                    .reduce(
                      (total, invoice) =>
                        parseFloat(invoice.discountAmount.$numberDecimal) +
                        total,
                      0
                    )
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </b>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default Invoice;
