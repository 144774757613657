import React, { useEffect, useRef, useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import useResponsive from "../../constants/useResponsive";

// Register the components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ data }) => {
  const chartRef = useRef(null);
  const { isXs,isXl } = useResponsive();

  // Memoize the modified data to add ordinal suffixes to day labels
  const modifiedData = useMemo(() => {
    if (data && data.labels) {
      const modifiedLabels = data.labels.map(label => {
        // Function to add ordinal suffix to a number
        const addOrdinalSuffix = (n) => {
          const s = ["th", "st", "nd", "rd"];
          const v = n % 100;
          return `${n}${s[(v - 20) % 10] || s[v] || s[0]}`;
        };

        // Regex to match day with optional month
        const dayMonthMatch = label.match(/^(\d+)(?:\s*(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))?/);
        if (dayMonthMatch) {
          return addOrdinalSuffix(parseInt(dayMonthMatch[1]));
        }
        return label;
      });

      // console.log("Original Labels:", data.labels);
      // console.log("Modified Labels:", modifiedLabels);

      return {
        ...data,
        labels: modifiedLabels
      };
    }
    return data;
  }, [data]);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;

      // Apply gradient to dataset
      chart.data.datasets = chart.data.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: dataset.borderColor,
      }));

      chart.update();
    }
  }, [data]); // Re-run the effect when 'data' changes

  const options = {
    responsive: true,
    elements: {
      line: {
        tension: 0.4, // Smoothes the line
        borderWidth: 3,
        borderColor: "#4bc0c0",
      },
      point: {
        radius: 3, // Reduced the radius to make dots smaller
        borderWidth: 1.5, // Adjusted border width for smaller dots
        backgroundColor: "white",
        hoverRadius: 5, // Adjust hover radius
        hoverBorderWidth: 2, // Adjust hover border width
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hides X-axis grid lines
        },
        ticks: {
          color: "#555", // Color of X-axis labels
          font: {
            size: 12,
          },
        },
      },
      y: {
        grid: {
          color: "#ddd", // Color of Y-axis grid lines
        },
        ticks: {
          color: "#555", // Color of Y-axis labels
          font: {
            size: 12,
          },
          callback: function (value, index, values) {
            return value;
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          boxWidth: 20,
          padding: 20,
          color: "#333",
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        backgroundColor: "rgba(0,0,0,0.8)",
        titleFont: {
          size: 16,
        },
        bodyFont: {
          size: 14,
        },
        cornerRadius: 4,
        displayColors: false,
      },
    },
    animation: {
      duration: 1500, // General animation time
    },
    maintainAspectRatio: false,
  };

  return (
    <div style={{ height: isXl ? "500px" : "320px" }}>
      <Line ref={chartRef} 
        data={isXs ? modifiedData.slice(0, 5) : data}
        options={options} />
    </div>
  );
};

export default LineChart;
