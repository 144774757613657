import {
  Grid,
  Card,
  CardContent,
  Typography,
  LinearProgress,
  Box,
} from "@mui/material";

const getProgressColor = (value) => {
  if (value < 50) return "success";
  if (value < 80) return "warning";
  return "error";
};

const LeaveCard = ({ title, assigned, used }) => {
  const available = assigned - used;
  const percentageUsed = Math.min((used / assigned) * 100, 100);

  return (
    <Card variant="outlined" sx={{ width: "100%", mb: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>

        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2">Assigned: {assigned}</Typography>
          <Typography variant="body2">Used: {used}</Typography>
          <Typography
            variant="body2"
            sx={{
              color: available < 0 ? "red" : "green",
              fontWeight: "bold",
            }}
          >
            Available: {available}
          </Typography>
        </Box>

        <Box mt={2}>
          <LinearProgress
            variant="determinate"
            value={percentageUsed}
            color={getProgressColor(percentageUsed)}
            sx={{ height: 10, borderRadius: 5 }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default LeaveCard;
